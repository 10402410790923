@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&family=PT+Sans+Narrow&display=swap");
/********************************************************************************/
/* Vien Main Style

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.Navbar
09.Menu
10.Apps
11.Chat App
12.Survey App
13.Todo App
14.Main
15.Cards
16.Dashboard
17.Calendar
18.Datatable
19.Alerts
20.Forms
21.Form Wizard
22.Slider(Range)
23.Navs
24.Tooltip and popover
25.Progress
26.Rating
27.Sortable
28.Spinner
29.Modal
30.Authorization
31.Html Editors
32.Icons
33.Loading
34.Context Menu
35.Videojs
36.Prices
37.Tables
38.Invoice Print
39.Profile Page
40.Details Page
41.Blog
42.Rounded
43.Rtl
44.Landing Page
*/
/* 00.Variables and Imports */
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");
/* 01.Base */
html {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

:root {
  --theme-color-1: #3E3A3E;
  --theme-color-2: #9A1915;
  --theme-color-3: #8F8F8F;
  --theme-color-4: #e1e1e1;
  --theme-color-5: #856f5a;
  --theme-color-6: #e7000e;
  --theme-color-1-10: rgba(62, 58, 62, 0.1);
  --theme-color-2-10: rgba(154, 25, 21, 0.1);
  --theme-color-3-10: rgba(143, 143, 143, 0.1);
  --theme-color-4-10: rgba(225, 225, 225, 0.1);
  --theme-color-5-10: rgba(133, 111, 90, 0.1);
  --theme-color-6-10: rgba(231, 0, 14, 0.1);
  --primary-color: snow;
  --foreground-color: white;
  --separator-color: #d7d7d7;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: snow;
  background: #f8f8f8;
}

app-color-switcher, app-app > div, app-user > div {
  opacity: 0;
}

body.show app-color-switcher, body.show app-app > div, body.show app-user > div {
  opacity: 1;
}

.rtl * {
  text-align: right;
}

.fixed-background {
  background: #A7392E no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll {
  position: relative;
  height: 100%;
  max-width: unset;
  width: unset;
  margin-right: -15px;
  padding-right: 15px;
}

perfect-scrollbar > .ps {
  max-width: unset;
  width: unset;
  margin-right: -15px;
  padding-right: 15px;
  margin-left: -15px;
  padding-left: 15px;
}

::selection {
  background: #c2c2c2;
}

::-moz-selection {
  background: #c2c2c2;
}

.ps__thumb-y {
  background-color: #d7d7d7 !important;
  left: 0 !important;
  width: 5px !important;
  right: 0 !important;
}

.ps__rail-y {
  width: 5px !important;
}

.ps__thumb-y {
  background-color: #d7d7d7;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px !important;
  left: 0 !important;
}

:focus {
  outline: initial !important;
}

.map-item {
  height: 400px;
  width: 100%;
}
.map-item .map-container-inner {
  height: 400px;
  width: 100%;
}

.logo-single {
  width: 200px;
  height: 60px;
  background: url("../../logos/logo-horizontal.jpg") no-repeat;
  background-position: center center;
  display: inline-block;
  margin-bottom: 60px;
  background-size: contain;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.3;
}

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block;
}
@media (max-width: 767px) {
  h1 {
    font-size: 1.3rem;
    padding-top: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-bottom: 0.5rem;
  }
}

h2 {
  font-size: 1.4rem;
}
@media (max-width: 767px) {
  h2 {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;
}
@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;
}
@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;
}
@media (max-width: 767px) {
  h4 {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;
}
@media (max-width: 767px) {
  h5 {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;
}
@media (max-width: 767px) {
  h6 {
    font-size: 0.85rem;
  }
}

.depth-1 {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.depth-2 {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}

.z-index-1 {
  z-index: 1;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.list-item-heading {
  font-size: 1rem;
}

.min-width-zero {
  min-width: 0;
}

.no-transition {
  transition: none !important;
}

button {
  color: snow;
  outline: initial !important;
}

.c-pointer {
  cursor: pointer;
}

@media (max-width: 767px) {
  .display-1 {
    font-size: 2.8rem;
  }
}

@media (max-width: 767px) {
  .display-2 {
    font-size: 2.6rem;
  }
}

@media (max-width: 767px) {
  .display-3 {
    font-size: 2.4rem;
  }
}

@media (max-width: 767px) {
  .display-4 {
    font-size: 2.2rem;
  }
}

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem;
}
@media (max-width: 767px) {
  .lead {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

.mb-5, .my-5 {
  margin-bottom: 2rem !important;
}

@media (max-width: 575px) {
  .float-none-xs {
    float: initial !important;
  }
}

.main-heading {
  border-bottom: 1px solid #d7d7d7;
}

.separator {
  border-bottom: 1px solid #d7d7d7;
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem;
}

ul li {
  margin-bottom: 5px;
}
ul li:last-of-type {
  margin-bottom: 0;
}

.rounded {
  border-radius: 0.75rem !important;
}

.rounded-100 {
  border-radius: 100px !important;
}

.video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;
}
.video-play-icon span {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  height: 2.5rem;
  border-radius: 2rem;
  line-height: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8em;
  width: 3em;
  text-align: center;
  padding-left: 5px;
}
.video-play-icon span:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 12px;
  font-size: 0;
  border-color: transparent transparent transparent #3E3A3E;
}
.video-play-icon:hover span, .video-play-icon:active span {
  background: rgba(255, 255, 255, 0.85);
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

a {
  color: #9A1915;
  transition: color 200ms;
  outline: initial !important;
}
a:hover, a:active {
  text-decoration: initial;
  color: #e7000e;
}

.white {
  color: #fff !important;
}

p {
  font-size: 0.82rem;
  line-height: 1rem;
  font-family: "Nunito", sans-serif;
}

.text-zero {
  font-size: 0 !important;
}

.text-extra-small {
  font-size: 0.7rem !important;
}

.text-large {
  font-size: 1.9rem !important;
}

.text-one {
  font-size: 1rem !important;
}

.text-xlarge {
  font-size: 2.7rem !important;
}

.text-small {
  font-size: 14.5px !important;
  line-height: 0.9rem !important;
}

.text-white {
  color: #fff !important;
}

.text-default {
  color: snow !important;
}

.text-muted {
  color: #125354 !important;
}

.text-semi-muted {
  color: #3E3A3E !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.color-theme-1, .subpage .color-theme-1 {
  color: #3E3A3E;
}

.color-theme-2, .subpage .color-theme-2 {
  color: #9A1915;
}

.display-options-container {
  margin-bottom: 0.25rem;
}
@media (max-width: 575px) {
  .display-options-container {
    margin-top: 0.5rem;
  }
}

#displayOptions a {
  cursor: pointer;
}
#displayOptions a.active i {
  color: #3E3A3E;
}
#displayOptions button {
  border-color: #3E3A3E;
  color: #3E3A3E;
}
#displayOptions button:hover {
  background-color: #3E3A3E;
  border-color: #3E3A3E;
  color: #fff;
}
#displayOptions .btn-outline-dark:not(:disabled):not(.disabled):active, #displayOptions .btn-outline-dark:not(:disabled):not(.disabled).active, #displayOptions .show > .btn-outline-dark.dropdown-toggle {
  background-color: #3E3A3E;
  border-color: #3E3A3E;
  color: #fff;
}
#displayOptions > span {
  padding-top: 0.1rem;
}
#displayOptions .view-icon svg {
  width: 19px;
}
#displayOptions .view-icon .view-icon-svg {
  fill: #3E3A3E;
}
#displayOptions .view-icon:hover .view-icon-svg, #displayOptions .view-icon.active .view-icon-svg {
  fill: #3E3A3E;
}

@media (min-width: 767px) {
  .collapse.dont-collapse-sm {
    display: block !important;
    height: auto !important;
    visibility: visible;
  }
}
@media (min-width: 767px) {
  .collapse.dont-collapse-sm {
    display: block !important;
    height: auto !important;
    visibility: visible;
  }
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.accordion-content {
  padding-top: 0 !important;
}

.feedback-container a {
  font-size: 1.3em;
  color: #3E3A3E;
  margin: 0.5rem;
}
.feedback-container a:hover, .feedback-container a:active {
  color: #3E3A3E;
}

.border-radius {
  border-radius: 0.1rem;
}

/* 02.Buttons */
button {
  color: snow;
  outline: initial !important;
}

.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px;
}

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center;
}

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px;
}

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px;
}

.btn {
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;
}
.btn-shadow:hover, .btn-shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
}

.btn-empty {
  background: transparent !important;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-sm > .btn {
  border-radius: 50px;
}

.btn.default {
  border-radius: 0.1rem;
}

.btn-primary, a.btn-primary:not([href]):not([tabindex]) {
  background-color: #FBBB21;
  border-color: transparent;
  color: #fff;
}
.btn-primary:hover, a.btn-primary:not([href]):not([tabindex]):hover {
  color: #fff;
  background-color: #efaa04;
  border-color: transparent;
}

.redprimary {
  color: #858585 !important;
  font-size: large;
}

.check-button {
  cursor: default !important;
  padding-left: 3rem !important;
  min-height: unset !important;
  line-height: unset !important;
  padding-top: 0.5rem !important;
}
.check-button label.custom-control {
  padding-left: initial;
  min-height: initial;
  vertical-align: top;
  margin-top: -2px;
}
.check-button span {
  line-height: unset;
}

.check-button.btn-primary {
  background-color: #3E3A3E !important;
  border-color: #3E3A3E !important;
  opacity: 1;
}

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px;
}

.dropdown-item {
  padding: 0.5rem 1.25rem;
  color: #3E3A3E;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #3E3A3E;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: #f8f8f8;
  color: #3E3A3E;
}

.dropdown-divider {
  border-color: #d7d7d7;
}

.dropdown-menu.dropdown-menu-sm {
  min-width: 4rem;
}
.dropdown-menu.dropdown-menu-sm .dropdown-item {
  padding: 0.25rem 1rem;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #1e1c1e;
  border-color: #1e1c1e;
  color: #fff;
}

.btn-secondary, a.btn-secondary:not([href]):not([tabindex]) {
  background-color: #9A1915;
  border-color: #9A1915;
  color: #fff;
}
.btn-secondary:hover, a.btn-secondary:not([href]):not([tabindex]):hover {
  color: #fff;
  background-color: #761310;
  border-color: #761310;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: #64100e;
  border-color: #64100e;
  color: #fff;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right, #3E3A3E, #161516);
}
.btn-primary.btn-primary-gradient:hover {
  background: linear-gradient(to right, #3E3A3E, #292629);
}

.btn-primary-gradient:not(:disabled):not(.disabled):active, .btn-primary-gradient:not(:disabled):not(.disabled).active, .show > .btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right, #3E3A3E, #161516);
}

.btn-secondary-gradient {
  background: linear-gradient(to right, #9A1915, #570e0c);
}
.btn-secondary-gradient:hover {
  background: linear-gradient(to right, #9A1915, #761310);
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active, .btn-secondary-gradient:not(:disabled):not(.disabled).active, .show > .btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right, #9A1915, #570e0c);
}

.btn-warning {
  background-color: #b69329;
  border-color: #b69329;
}

.btn-success, .btn-info, .btn-danger, .btn-warning {
  color: #fff;
}
.btn-success:hover, .btn-info:hover, .btn-danger:hover, .btn-warning:hover {
  color: #fff;
}

.btn-outline-success:hover, .btn-outline-info:hover, .btn-outline-danger:hover, .btn-outline-warning:hover {
  color: white;
}

.btn-light {
  color: #1F1C1D;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-light:hover {
  color: #1F1C1D;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.btn-dark {
  color: #ececec;
  background-color: #1F1C1D;
  border-color: #1F1C1D;
}
.btn-dark:hover {
  color: #ececec;
  background-color: #121010;
  border-color: #121010;
}

.btn-outline-dark {
  color: #1F1C1D;
  border-color: #1F1C1D;
}
.btn-outline-dark:hover {
  color: white;
  background-color: #1F1C1D;
  border-color: #1F1C1D;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: initial;
}
.btn-outline-white:hover {
  color: #3E3A3E;
  background-color: #fff;
}

.btn-outline-light {
  color: #ececec;
  border-color: #ececec;
}
.btn-outline-light:hover {
  color: white;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-outline-primary {
  color: #FBBB21;
  border-color: #FBBB21;
}
.btn-outline-primary:hover {
  color: white;
  background-color: #FBBB21;
  border-color: #FBBB21;
}

.btn-outline-theme-3 {
  background: unset;
  color: #8F8F8F;
  border-color: #8F8F8F;
}
.btn-outline-theme-3:hover {
  background-color: #8F8F8F;
  border-color: #8F8F8F;
  color: white;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  background-color: #3E3A3E;
  border-color: #3E3A3E;
  color: white;
}

.btn-outline-secondary {
  color: #9A1915;
  border-color: #9A1915;
}
.btn-outline-secondary:hover {
  background-color: #9A1915;
  border-color: #9A1915;
  color: white;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #9A1915;
  border-color: #9A1915;
  color: white;
}

.btn-header-light {
  color: #d7d7d7;
  border-color: transparent;
  background: transparent;
}
.btn-header-light:hover {
  background-color: transparent;
  border-color: #d7d7d7;
}

.btn-header-primary {
  color: #3E3A3E;
  border-color: transparent;
  background: transparent;
}
.btn-header-primary:hover {
  background-color: transparent;
  border-color: #3E3A3E;
}

.btn-header-secondary {
  color: #9A1915;
  border-color: transparent;
  background: transparent;
}
.btn-header-secondary:hover {
  background-color: transparent;
  border-color: #9A1915;
}

.btn-header-primary-light {
  color: #8F8F8F;
  border-color: transparent;
  background: transparent;
}
.btn-header-primary-light:hover {
  background-color: transparent;
  border-color: #8F8F8F;
}

.btn-xl, .btn-group-xl > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg, .btn-group-lg > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.6rem 2.5rem 0.6rem 2.5rem;
}
@media (max-width: 575px) {
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.45rem 1rem 0.45rem 1rem;
  font-size: 0.8rem;
  line-height: 1.5;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #FBBB21;
  border-color: transparent;
  color: #3E3A3E;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #9A1915;
  border-color: #9A1915;
  color: white;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-link {
  color: #3E3A3E;
  text-decoration: initial !important;
}

.white-underline-link {
  color: #fff;
  text-decoration: underline;
}
.white-underline-link:hover, .white-underline-link:active {
  color: #fff;
  text-decoration: initial;
}

.btn-link:hover {
  color: rgba(62, 58, 62, 0.7);
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;
}
.btn-multiple-state .spinner, .btn-multiple-state .icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 500ms;
  color: #fff;
  pointer-events: none;
}
.btn-multiple-state .icon i {
  vertical-align: text-bottom;
  font-size: 18px;
}
.btn-multiple-state .label {
  transition: opacity 500ms;
}
.btn-multiple-state.show-spinner .label {
  opacity: 0;
}
.btn-multiple-state.show-spinner .spinner {
  opacity: 1;
  visibility: visible;
}
.btn-multiple-state.show-success .label {
  opacity: 0;
}
.btn-multiple-state.show-success .icon.success {
  opacity: 1;
  visibility: visible;
}
.btn-multiple-state.show-fail .label {
  opacity: 0;
}
.btn-multiple-state.show-fail .icon.fail {
  opacity: 1;
  visibility: visible;
}
.btn-multiple-state.btn-primary:disabled {
  background: #242124;
  border-color: #242124;
}
.btn-multiple-state.btn-secondary:disabled {
  opacity: 1;
  border-color: #6d120f;
}

.icon-button {
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
}
.icon-button.large {
  width: 44px;
  height: 44px;
  font-size: 18px;
  line-height: 2.5;
}
.icon-button.small-icon {
  font-size: 12px;
  line-height: 32px;
}

.top-right-button-single {
  width: unset;
}
@media (max-width: 991px) {
  .top-right-button-single {
    width: 100%;
  }
}

.top-right-button-container {
  float: right;
  position: relative;
}
.top-right-button-container .check-button .custom-control-label::before {
  border: 1px solid #fff !important;
}
.top-right-button-container .btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #fff !important;
}
@media (max-width: 575px) {
  .top-right-button-container {
    float: initial;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 575px) {
  .top-right-button-container .top-right-button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}

/* 03.Widths */
.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

@media (max-width: 767px) {
  .w-xs-100 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media (max-width: 1199px) {
  .w-md-100 {
    width: 100% !important;
  }
}

/* 04.Borders */
.border {
  border: 1px solid #f3f3f3 !important;
}

.border-right {
  border-right: 1px solid #f3f3f3 !important;
}

.border-left {
  border-left: 1px solid #f3f3f3 !important;
}

.border-top {
  border-top: 1px solid #f3f3f3 !important;
}

.border-bottom {
  border-bottom: 1px solid #f3f3f3 !important;
}

.border-primary, .border-theme-1 {
  border-color: #3E3A3E !important;
}

.border-theme-2 {
  border-color: #9A1915 !important;
}

.border-theme-3 {
  border-color: #8F8F8F !important;
}

.border-secondary {
  border-color: snow !important;
}

.border-muted {
  border-color: #125354 !important;
}

.remove-last-border > *:last-child {
  border-bottom: initial !important;
}

.remove-last-margin > *:last-child {
  margin-bottom: initial !important;
}

.remove-last-padding > *:last-child {
  padding-bottom: initial !important;
}

/* Colors */
.text-theme-2 {
  color: #9A1915 !important;
}

.text-theme-3 {
  color: #8F8F8F !important;
}

.text-primary, .text-theme-1 {
  color: rgba(0, 0, 0, 0.788) !important;
}

.text-secondary {
  color: snow !important;
}

/* 05.Badges */
.bg-theme-2, .badge-theme-2 {
  background-color: #9A1915 !important;
  color: #fff;
}

.bg-theme-3, .badge-theme-3 {
  background-color: #8F8F8F !important;
  color: #fff;
}

.bg-primary, .bg-theme-1, .badge-primary, .badge-theme-1 {
  background-color: #3E3A3E !important;
  color: #fff;
}

.bg-secondary, .badge-secondary {
  background-color: #9A1915 !important;
  color: #fff;
}

.badge-warning {
  background-color: #b69329;
}

.badge-success {
  background-color: #3e884f;
}

.badge-info {
  background-color: #3195a5;
}

.badge-danger {
  background-color: #c43d4b;
}

.badge-success, .badge-danger, .badge-warning, .badge-info {
  color: #fff;
}

.badge {
  padding: 0.6em 0.75em;
  font-size: 74%;
}
.badge.badge-pill {
  padding-right: 1.25em;
  padding-left: 1.25em;
}
.badge.badge-top-left {
  top: 10px;
  left: -7px;
}
.badge.badge-top-left-2 {
  top: 40px;
  left: -7px;
}
.badge.badge-top-right {
  top: 8px;
  right: -7px;
}
.badge.badge-top-right-2 {
  top: 40px;
  right: -7px;
}

.badge-light {
  background-color: #ececec;
  color: #1F1C1D;
}

.badge-dark {
  background-color: #1F1C1D;
  color: #ececec;
}

.badge-outline-primary, .badge-outline-theme-1 {
  background: unset;
  border: 1px solid #3E3A3E;
  color: #3E3A3E;
}

.badge-outline-secondary, .badge-outline-theme-2 {
  background: unset;
  border: 1px solid #9A1915;
  color: #9A1915;
}

.badge-outline-theme-3 {
  background: unset;
  border: 1px solid #8F8F8F;
  color: #8F8F8F;
}

.badge-outline-success {
  background: unset;
  border: 1px solid #3e884f;
  color: #3e884f;
}

.badge-outline-danger {
  background: unset;
  border: 1px solid #c43d4b;
  color: #c43d4b;
}

.badge-outline-warning {
  background: unset;
  border: 1px solid #b69329;
  color: #b69329;
}

.badge-outline-info {
  background: unset;
  border: 1px solid #3195a5;
  color: #3195a5;
}

.badge-outline-light {
  background: unset;
  border: 1px solid #ececec;
  color: #ececec;
}

.badge-outline-dark {
  background: unset;
  border: 1px solid #1F1C1D;
  color: #1F1C1D;
}

/* 06.Breadcrumb */
@media (max-width: 991px) {
  .breadcrumb-container .breadcrumb {
    padding: 0;
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}

/* 07.Tabs */
.detail-tabs .panel-heading.card-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0.5rem !important;
}
.detail-tabs .panel-heading.card-header button {
  font-weight: 700;
}
.detail-tabs .panel-body.card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.separator-tabs .nav-tabs {
  border-bottom: 1px solid #d7d7d7 !important;
}

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: initial;
}

.nav-tabs.nav-justified .nav-item .nav-link {
  text-align: center !important;
}

.nav-tabs .nav-link.active::before, .nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3E3A3E;
  color: white;
  border-radius: 10px;
  position: absolute;
  width: calc(100% - 1rem);
  height: 5px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.separator-tabs .nav-tabs .nav-link.active::before, .separator-tabs .nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3E3A3E;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  top: unset;
}
.separator-tabs .nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #3E3A3E;
}

.separator-tabs .nav-tabs {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 2rem;
}

.separator-tabs .nav-tabs .nav-link.active::before, .separator-tabs .nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3E3A3E;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  top: unset;
}

.separator-tabs .nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #3E3A3E;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: #3E3A3E;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border: initial;
}

.card-tabs .nav-tabs {
  border-bottom: initial;
  padding: 0 0.75rem;
}

/* 08.Navbar*/
.navbar {
  background: white;
  height: 100px;
  padding: 1.5rem 0 1.5rem 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.navbar .navbar-contents {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.navbar #notificationDropdown {
  width: 270px;
  padding: 1.5rem;
  height: 280px;
  right: 15px;
}
.navbar #iconMenuDropdown {
  width: 220px;
  padding: 1.5rem 1rem;
  height: 290px;
}
.navbar .icon-menu-item {
  width: 90px;
  display: inline-block;
  text-align: center;
  margin-bottom: 1.7rem;
  color: white;
}
.navbar .icon-menu-item i {
  font-size: 32px;
  line-height: 42px;
}
.navbar .icon-menu-item span {
  text-align: center;
  padding: 0 10px;
  line-height: 14px;
}
.navbar .icon-menu-item:hover, .navbar .icon-menu-item:focus {
  color: #3E3A3E;
}
.navbar .menu-button-mobile {
  color: #3E3A3E;
  text-align: center;
  margin-left: 15px;
}
.navbar .menu-button-mobile svg {
  height: 12px;
  fill: #9A1915;
}
@media (max-width: 767px) {
  .navbar .menu-button-mobile {
    width: 20px;
  }
}
.navbar .language-button {
  background: #f8f8f8;
  border: initial;
  font-size: 0.8rem;
  color: #3E3A3E;
  padding: 0.6rem 1rem;
}
@media (max-width: 575px) {
  .navbar .language-button {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
.navbar .language-button:hover, .navbar .language-button:focus {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #3E3A3E;
}
.navbar .language-button.btn-light:not(:disabled):not(.disabled):active, .navbar .language-button.btn-light:not(:disabled):not(.disabled).active, .navbar .language-button .show > .btn-light.dropdown-toggle {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #3E3A3E;
}
.navbar .menu-button {
  color: #3E3A3E;
  width: 115px;
  text-align: center;
}
.navbar .menu-button svg {
  height: 15px;
}
.navbar .menu-button .main {
  fill: #e1e1e1;
  transition: fill 300ms;
}
.navbar .menu-button .sub {
  fill: #9A1915;
  transition: fill 300ms;
}
.navbar .menu-button:hover {
  color: #3E3A3E;
}
@media (max-width: 1439px) {
  .navbar .menu-button {
    width: 115px;
  }
}
@media (max-width: 1199px) {
  .navbar .menu-button {
    width: 115px;
  }
}
@media (max-width: 767px) {
  .navbar .menu-button {
    width: 60px;
  }
}
.navbar .navbar-left {
  flex-basis: 40%;
}
.navbar .navbar-right {
  flex-basis: 40%;
  text-align: right;
}
.navbar .navbar-right .user {
  margin-right: 15px;
}
@media (max-width: 1439px) {
  .navbar .navbar-right .user {
    margin-right: 15px;
  }
}
@media (max-width: 1199px) {
  .navbar .navbar-right .user {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-right .user {
    margin-right: 15px;
  }
}
.navbar .search {
  position: relative;
  width: 272px;
  border-radius: 20px;
  background: #f8f8f8;
}
.navbar .search input {
  border: initial;
  background: transparent;
  outline: initial !important;
  font-size: 0.8rem;
  width: 93%;
  color: snow;
  padding: 0.4rem 1rem;
  line-height: 2;
}
.navbar .search .search-icon {
  font-size: 17px;
  border-radius: 10px;
  color: #d7d7d7;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -10px;
  right: 3px;
  text-align: center;
  cursor: pointer;
}
.navbar .search .search-icon:hover {
  color: #3E3A3E;
}
@media (max-width: 991px) {
  .navbar .search {
    width: 136px;
  }
  .navbar .search input {
    width: 85%;
  }
}
@media (max-width: 767px) {
  .navbar .search {
    width: 30px;
    height: 30px;
    background: initial;
    margin-left: 0.6rem;
    color: white;
  }
  .navbar .search input {
    display: none;
  }
  .navbar .search .search-icon {
    font-size: 17px;
    width: 30px;
    height: 30px;
    bottom: -3px;
    right: 0;
    color: inherit;
  }
  .navbar .search.mobile-view {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 2;
    background: white;
    left: 0;
    top: 0;
    height: 70px;
    margin-left: 15px;
  }
  .navbar .search.mobile-view input {
    display: block;
    width: 100%;
    height: 70px;
    padding-left: 0;
  }
  .navbar .search.mobile-view span {
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
  }
}
.navbar .header-icons {
  margin-right: 0.5rem;
}
@media (max-width: 575px) {
  .navbar .header-icons {
    margin-right: 0;
  }
}
.navbar .header-icon {
  font-size: 16px;
  color: #3E3A3E;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  vertical-align: initial;
}
@media (max-width: 575px) {
  .navbar .header-icon {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
.navbar .header-icon:hover {
  color: #3E3A3E;
}
.navbar .header-icon#notificationButton .count {
  font-size: 9px;
  color: #3E3A3E;
  border: 1px solid #3E3A3E;
  border-radius: 10px;
  position: absolute;
  width: 18px;
  height: 15px;
  text-align: center;
  font-weight: 700;
  top: -4px;
  right: 1px;
  line-height: 14px;
}
@media (max-width: 575px) {
  .navbar .header-icon#notificationButton .count {
    right: -1px;
  }
}
.navbar .custom-control .custom-control-label::before {
  border: 1px solid #3E3A3E !important;
}
.navbar .custom-control .custom-control-label::after {
  background-color: #3E3A3E;
}
.navbar .user {
  color: white;
  position: relative;
}
.navbar .user img {
  margin-left: 10px;
  border-radius: 30px;
  width: 40px;
}
.navbar .user .name {
  color: #A8392F;
  font-size: 17.5px;
}
@media (max-width: 575px) {
  .navbar .user .name {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar .user {
    margin-left: initial;
  }
  .navbar .user img {
    width: 30px;
  }
  .navbar .user:after {
    font-size: 11px;
    width: 14px;
    height: 14px;
    bottom: -3px;
    right: -3px;
  }
}
@media (max-width: 1439px) {
  .navbar {
    height: 90px;
  }
}
@media (max-width: 1199px) {
  .navbar {
    height: 80px;
    padding: 12px 0;
  }
}
@media (max-width: 767px) {
  .navbar {
    height: 70px;
  }
}

.logo {
  width: 317px;
  height: 60px;
  background: url("../../logos/logo-horizontal.jpg") no-repeat;
  margin: 0 auto;
  background-size: contain;
  position: absolute;
  left: 109px;
  top: 19px;
}

.logo-mobile {
  width: 20px;
  height: 35px;
  background: url("../../logos/logo-horizontal.jpg") no-repeat;
  background-position: center center;
  background-size: contain;
}

#app-container.sub-hidden .menu-button .sub, #app-container.menu-sub-hidden .menu-button .sub {
  fill: #e7000e;
}
#app-container.main-hidden .menu-button .main, #app-container.main-hidden .menu-button .sub, #app-container.menu-hidden .menu-button .main, #app-container.menu-hidden .menu-button .sub {
  fill: #3E3A3E;
}

/* 09.Menu*/
.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 100px;
  z-index: 5;
  height: calc(100% - 100px);
  transition: border-radius 300ms;
}
.menu .main-menu {
  width: max-content;
  height: calc(100% - 100px);
  background: white;
  z-index: 3;
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
}
.menu .main-menu .scroll {
  padding-right: unset;
  margin-right: unset;
  height: 100%;
  --scrollbar-color: transparent;
  --scrollbar-container-color: transparent;
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
  --scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.3);
  --scrollbar-border-radius: 4px;
  --scrollbar-size: 6px;
  --scrollbar-padding: 0px;
  --scroll-view-margin: 0;
  --scroll-view-color: transparent;
}
.menu .main-menu .scroll .ps__thumb-y {
  right: 0;
}
.menu .main-menu ul {
  margin-bottom: initial;
}
.menu .main-menu ul li {
  position: relative;
}
.menu .main-menu ul li span {
  text-align: center;
  padding: 0 10px;
  line-height: 14px;
}
.menu .main-menu ul li a {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #f3f3f3;
  color: snow;
  transition: color 300ms;
  transition: background 300ms;
}
.menu .main-menu ul li a:hover, .menu .main-menu ul li a:focus {
  color: #3E3A3E;
}
.menu .main-menu ul li i {
  font-size: 32px;
  line-height: 42px;
}
.menu .main-menu ul li.active a {
  color: #3E3A3E;
}
.menu .main-menu ul li.active:after {
  content: " ";
  background: #3E3A3E;
  color: white;
  border-radius: 10px;
  position: absolute;
  width: 6px;
  height: 90px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.menu .main-menu ul li:last-of-type a {
  border-bottom: initial;
}
@media (max-width: 1439px) {
  .menu .main-menu {
    width: 115px;
    height: calc(100% - 90px);
  }
}
@media (max-width: 1199px) {
  .menu .main-menu {
    width: 115px;
    height: calc(100% - 80px);
  }
}
@media (max-width: 767px) {
  .menu .main-menu {
    width: 105px;
    height: calc(100% - 70px);
  }
  .menu .main-menu ul li i {
    font-size: 26px;
    line-height: 30px;
  }
  .menu .main-menu ul li a {
    height: 90px;
  }
  .menu .main-menu ul li.active:after {
    width: 3px;
    height: 60px;
  }
}
.menu .sub-menu {
  width: 272px;
  background: white;
  z-index: 2;
  position: fixed;
  left: 115px;
  border-left: 1px solid #f3f3f3;
  height: calc(100% - 100px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}
.menu .sub-menu .scroll {
  margin-right: unset;
  padding-right: unset;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}
.menu .sub-menu .scroll .ps__thumb-y {
  right: 0;
}
.menu .sub-menu ul {
  display: none;
}
.menu .sub-menu ul li {
  margin-left: 30px;
  margin-bottom: 0;
  position: relative;
}
.menu .sub-menu ul li a {
  font-size: 16px;
  display: block;
  padding: 8px 0;
  margin-bottom: 10px;
}
.menu .sub-menu ul li a span {
  vertical-align: middle;
  height: 16px;
  font-size: 15px;
}
.menu .sub-menu ul li i {
  font-size: 1.3em;
  margin-right: 10px;
  color: #3E3A3E;
  vertical-align: middle;
  display: inline-block;
}
.menu .sub-menu ul li.active i, .menu .sub-menu ul li.active a {
  color: #3E3A3E;
}
.menu .sub-menu ul li.active:after {
  content: " ";
  background: #3E3A3E;
  border-radius: 10px;
  position: absolute;
  width: 4px;
  height: 4px;
  top: 50%;
  transform: translateY(-50%);
  left: -16px;
}
.menu .sub-menu ul.inner-level-menu {
  display: block !important;
}
.menu .sub-menu ul.inner-level-menu li a, .menu .sub-menu ul.inner-level-menu li i {
  color: snow;
}
.menu .sub-menu ul.inner-level-menu li a:hover, .menu .sub-menu ul.inner-level-menu li.active i, .menu .sub-menu ul.inner-level-menu li.active a {
  color: #3E3A3E;
}
.menu .sub-menu ul.inner-level-menu li.active:after {
  left: -22px;
}
.menu .sub-menu .rotate-arrow-icon i {
  transition: transform 200ms;
  transform: rotate(0);
}
.menu .sub-menu .rotate-arrow-icon.collapsed i {
  transform: rotate(-90deg);
}
@media (max-width: 1439px) {
  .menu .sub-menu {
    left: 115px;
    width: 230px;
    height: calc(100% - 90px);
  }
}
@media (max-width: 1199px) {
  .menu .sub-menu {
    left: 115px;
    width: 230px;
    height: calc(100% - 80px);
  }
}
@media (max-width: 767px) {
  .menu .sub-menu {
    left: 105px;
    width: 230px;
    height: calc(100% - 70px);
  }
}
@media (max-width: 1439px) {
  .menu {
    padding-top: 90px;
    height: calc(100% - 90px);
  }
}
@media (max-width: 1199px) {
  .menu {
    padding-top: 80px;
    height: calc(100% - 80px);
  }
}
@media (max-width: 767px) {
  .menu {
    padding-top: 70px;
    height: calc(100% - 70px);
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  }
}

body.default-transition .menu .sub-menu {
  transition: transform 300ms;
}

body.default-transition .menu .main-menu {
  transition: transform 300ms, border-radius 300ms;
}

.sub-hidden .sub-menu, .menu-sub-hidden .sub-menu, .menu-hidden .sub-menu {
  transform: translateX(-272px);
}
@media (max-width: 1439px) {
  .sub-hidden .sub-menu, .menu-sub-hidden .sub-menu, .menu-hidden .sub-menu {
    transform: translateX(-230px);
  }
}
@media (max-width: 1199px) {
  .sub-hidden .sub-menu, .menu-sub-hidden .sub-menu, .menu-hidden .sub-menu {
    transform: translateX(-230px);
  }
}
@media (max-width: 767px) {
  .sub-hidden .sub-menu, .menu-sub-hidden .sub-menu, .menu-hidden .sub-menu {
    transform: translateX(-230px);
  }
}

.main-hidden .main-menu, .menu-hidden .main-menu {
  transform: translateX(-115px);
}
@media (max-width: 1439px) {
  .main-hidden .main-menu, .menu-hidden .main-menu {
    transform: translateX(-115px);
  }
}
@media (max-width: 1199px) {
  .main-hidden .main-menu, .menu-hidden .main-menu {
    transform: translateX(-115px);
  }
}
@media (max-width: 767px) {
  .main-hidden .main-menu, .menu-hidden .main-menu {
    transform: translateX(-105px);
  }
}

.main-hidden.sub-hidden .sub-menu, .menu-hidden .sub-menu {
  transform: translateX(-387px);
}
@media (max-width: 1439px) {
  .main-hidden.sub-hidden .sub-menu, .menu-hidden .sub-menu {
    transform: translateX(-345px);
  }
}
@media (max-width: 1199px) {
  .main-hidden.sub-hidden .sub-menu, .menu-hidden .sub-menu {
    transform: translateX(-345px);
  }
}
@media (max-width: 767px) {
  .main-hidden.sub-hidden .sub-menu, .menu-hidden .sub-menu {
    transform: translateX(-335px);
  }
}

.menu-mobile .main-menu {
  transform: translateX(-105px);
}
.menu-mobile .sub-menu {
  transform: translateX(-385px);
}

.main-show-temporary .main-menu {
  transform: translateX(0);
}
.main-show-temporary .sub-menu {
  transform: translateX(-272px);
}
@media (max-width: 1439px) {
  .main-show-temporary .main-menu {
    transform: translateX(0);
  }
  .main-show-temporary .sub-menu {
    transform: translateX(-230px);
  }
}
@media (max-width: 1199px) {
  .main-show-temporary .main-menu {
    transform: translateX(0);
  }
  .main-show-temporary .sub-menu {
    transform: translateX(-230px);
  }
}
@media (max-width: 767px) {
  .main-show-temporary .sub-menu {
    transform: translateX(-230px);
  }
}

.sub-show-temporary .sub-menu, .menu-mobile.sub-show-temporary .sub-menu {
  transform: translateX(0);
}

/* 10.Apps*/
.app-menu {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 280px;
  height: 100%;
  float: right;
  background: white;
  transition: transform 300ms;
  position: fixed;
  right: 0;
  top: 100px;
  height: calc(100% - 100px);
}
@media (max-width: 1439px) {
  .app-menu {
    top: 90px;
    height: calc(100% - 90px);
  }
}
@media (max-width: 1199px) {
  .app-menu {
    top: 80px;
    transform: translateX(280px);
    height: calc(100% - 80px);
  }
}
@media (max-width: 767px) {
  .app-menu {
    top: 70px;
    height: calc(100% - 70px);
  }
}
.app-menu .scroll {
  height: 100%;
}
.app-menu .scroll .ps__thumb-y {
  right: 0;
}
.app-menu.shown {
  transform: translateX(0);
}
.app-menu .app-menu-button {
  position: absolute;
  left: -28px;
  background: white;
  top: 0px;
  padding: 12px 8px 12px 5px;
  border-radius: 0.2rem;
  color: #3E3A3E;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
  font-size: 15px;
  line-height: 0;
}
@media (max-width: 1439px) {
  .app-menu .app-menu-button {
    top: 1px;
  }
}
@media (max-width: 1199px) {
  .app-menu .app-menu-button {
    top: 2px;
  }
}
@media (max-width: 767px) {
  .app-menu .app-menu-button {
    top: 13px;
  }
}
.app-menu ul li {
  margin-bottom: 5px;
}
.app-menu ul li a {
  font-size: 13px;
  display: block;
  padding: 3px 0;
}
.app-menu ul li a:hover i {
  color: #3E3A3E;
}
.app-menu ul li i {
  font-size: 1.2em;
  margin-right: 10px;
  color: #3E3A3E;
  vertical-align: unset;
}
@media (max-width: 767px) {
  .app-menu ul li i {
    font-size: 20px;
  }
}
.app-menu ul li.active i, .app-menu ul li.active a {
  color: #3E3A3E;
}

.app-row {
  padding-right: 280px;
}
@media (max-width: 1199px) {
  .app-row {
    padding-right: 0;
  }
}

/* 11.Chat App*/
.chat-app .scroll {
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: -10px;
  height: calc(100vh - 100px - 270px);
}
@media (max-width: 1439px) {
  .chat-app .scroll {
    height: calc(100vh - 90px - 270px);
  }
}
@media (max-width: 1199px) {
  .chat-app .scroll {
    height: calc(100vh - 80px - 270px);
  }
}
@media (max-width: 767px) {
  .chat-app .scroll {
    height: calc(100vh - 70px - 220px);
  }
}
.chat-app .scroll .ps__thumb-y {
  right: 10px;
}
@media (max-width: 767px) {
  .chat-app .list-item-heading {
    font-size: 0.9rem;
  }
}
@media (max-width: 767px) {
  .chat-app .card .card-body {
    padding: 0.75rem;
  }
}
.chat-app .chat-text-left {
  padding-left: 64px;
}
.chat-app .chat-text-right {
  padding-right: 64px;
}

.chat-input-container {
  width: 100%;
  height: 90px;
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transition: padding 300ms;
  padding-left: 402px;
  padding-right: 295px;
}
@media (max-width: 1439px) {
  .chat-input-container {
    padding-left: 130px;
    padding-right: 295px;
  }
}
@media (max-width: 1199px) {
  .chat-input-container {
    padding-left: 130px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .chat-input-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.chat-input-container input {
  border: initial;
  width: unset;
  height: 90px;
  padding-left: 0;
  display: inline-block;
}
@media (max-width: 991px) {
  .chat-input-container .send-button {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}

.menu-sub-hidden .chat-input-container, .sub-hidden .chat-input-container {
  padding-left: 130px;
}
@media (max-width: 1439px) {
  .menu-sub-hidden .chat-input-container, .sub-hidden .chat-input-container {
    padding-left: 130px;
  }
}
@media (max-width: 1199px) {
  .menu-sub-hidden .chat-input-container, .sub-hidden .chat-input-container {
    padding-left: 130px;
  }
}
@media (max-width: 767px) {
  .menu-sub-hidden .chat-input-container, .sub-hidden .chat-input-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.menu-main-hidden .chat-input-container, .menu-hidden .chat-input-container, .main-hidden .chat-input-container {
  padding-left: 15px;
}
@media (max-width: 1439px) {
  .menu-main-hidden .chat-input-container, .menu-hidden .chat-input-container, .main-hidden .chat-input-container {
    padding-left: 15px;
  }
}
@media (max-width: 1199px) {
  .menu-main-hidden .chat-input-container, .menu-hidden .chat-input-container, .main-hidden .chat-input-container {
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .menu-main-hidden .chat-input-container, .menu-hidden .chat-input-container, .main-hidden .chat-input-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* 12.Survey App*/
.survey-app .answers .badge {
  color: #3E3A3E;
}
.survey-app .answers input {
  padding-right: 70px;
}
.survey-app .question.edit-quesiton .edit-mode {
  display: block;
}
.survey-app .question.edit-quesiton .view-mode {
  display: none;
}
.survey-app .question.edit-quesiton .view-button {
  display: inline-block;
}
.survey-app .question.edit-quesiton .edit-button {
  display: none;
}
.survey-app .question.view-quesiton .edit-mode {
  display: none;
}
.survey-app .question.view-quesiton .view-mode {
  display: block;
}
.survey-app .question.view-quesiton .view-button {
  display: none;
}
.survey-app .question.view-quesiton .edit-button {
  display: inline-block;
}
.survey-app .survey-question-types .btn-group-icon {
  font-size: 21px;
  line-height: 28px;
}
.survey-app .survey-question-types .btn {
  padding-bottom: 0.3rem;
}

.input-icons {
  position: absolute;
  right: 0;
  top: 9px;
}

/* 13.Todo App*/
.heading-icon {
  color: #3E3A3E;
  font-size: 20px;
  line-height: inherit;
  margin-right: 5px;
  vertical-align: middle;
}

.heading-number {
  border: 1px solid #3E3A3E;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: #3E3A3E;
}

/* 14.Main*/
main {
  margin-left: 402px;
  margin-top: 100px;
  margin-right: 15px;
  margin-bottom: -5px;
}
main .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1439px) {
  main {
    margin-left: 360px;
    margin-right: 15px;
    margin-top: 95px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1199px) {
  main {
    margin-left: 360px;
    margin-right: 15px;
    margin-top: 85px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  main {
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-top: 85px !important;
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  main {
    margin-bottom: 0;
  }
}

body.default-transition main {
  transition: margin-left 300ms;
}

#app-container.sub-hidden main, #app-container.menu-sub-hidden main, #app-container.menu-hidden main {
  margin-left: 130px;
}
#app-container.main-hidden main, #app-container.menu-hidden main {
  margin-left: 15px;
}
#app-container.menu-main-hidden main {
  margin-left: 287px;
}
#app-container.menu-main-hidden.menu-hidden main {
  margin-left: 15px;
}
@media (max-width: 1439px) {
  #app-container.sub-hidden main, #app-container.menu-sub-hidden main, #app-container.menu-hidden main {
    margin-left: 130px;
  }
  #app-container.main-hidden main, #app-container.menu-hidden main {
    margin-left: 15px;
  }
  #app-container.menu-main-hidden main {
    margin-left: 245px;
  }
  #app-container.menu-main-hidden.menu-hidden main {
    margin-left: 15px;
  }
}
@media (max-width: 1199px) {
  #app-container.sub-hidden main, #app-container.menu-sub-hidden main, #app-container.menu-hidden main {
    margin-left: 130px;
  }
  #app-container.main-hidden main, #app-container.menu-hidden main {
    margin-left: 15px;
  }
  #app-container.menu-main-hidden main {
    margin-left: 245px;
  }
  #app-container.menu-main-hidden.menu-hidden main {
    margin-left: 15px;
  }
}

/* 15.Cards*/
.card {
  border: initial;
  background: white;
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.card .card-header .card-icon {
  right: 5px;
  top: 5px;
}
.card .card-header .card-icon i {
  font-size: 12px;
  color: #d7d7d7;
}
.card .card-header .handle {
  cursor: default;
}
.card .card-body {
  padding: 1.75rem;
}
@media (max-width: 575px) {
  .card .card-body {
    padding: 1.5rem;
  }
}
.card .card-body.sm {
  padding: 1.25rem 1.75rem;
}
.card .card-title {
  margin-bottom: 2rem;
}

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset;
}

.card-img-fluid {
  border-radius: calc(0.25rem - 1px);
  object-fit: cover;
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
}

.card-img-left {
  width: 100%;
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
  height: 100%;
  object-fit: cover;
}

.card-img-right {
  width: 100%;
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  height: 100%;
  object-fit: cover;
}

.card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.75rem;
}
@media (max-width: 575px) {
  .card-img-overlay {
    padding: 1rem;
  }
}

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0;
}
@media (max-width: 575px) {
  .card-top-buttons {
    padding: 0.35rem;
  }
}

.card-header {
  border: initial;
  background: initial;
  padding-top: 0;
}

.img-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
}

.list-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset;
}
@media (max-width: 991px) {
  .list-thumbnail {
    height: 80px;
  }
}
@media (max-width: 575px) {
  .list-thumbnail {
    height: 70px;
  }
}
@media (max-width: 991px) {
  .list-thumbnail.responsive {
    width: unset;
    height: 135px;
  }
}
@media (max-width: 575px) {
  .list-thumbnail.responsive {
    width: 110px !important;
    height: 100%;
  }
}
@media (max-width: 419px) {
  .list-thumbnail.responsive {
    width: 90px !important;
    height: 100%;
  }
}
.list-thumbnail.small {
  height: 60px;
  font-size: 1rem;
}
@media (max-width: 991px) {
  .list-thumbnail.small {
    height: 55px;
  }
}
@media (max-width: 575px) {
  .list-thumbnail.small {
    height: 50px;
  }
}
.list-thumbnail.xsmall {
  height: 40px;
  font-size: 1rem;
}
@media (max-width: 991px) {
  .list-thumbnail.xsmall {
    height: 40px;
  }
}
@media (max-width: 575px) {
  .list-thumbnail.xsmall {
    height: 40px;
  }
}

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: #3E3A3E;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #fff;
}
@media (max-width: 991px) {
  .list-thumbnail-letters {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 575px) {
  .list-thumbnail-letters {
    width: 70px;
    height: 70px;
  }
}
.list-thumbnail-letters.small {
  width: 60px;
  height: 60px;
  font-size: 1rem;
}
@media (max-width: 991px) {
  .list-thumbnail-letters.small {
    width: 55px;
    height: 55px;
  }
}
@media (max-width: 575px) {
  .list-thumbnail-letters.small {
    width: 50px;
    height: 50px;
  }
}

.panel-group .card {
  box-shadow: initial;
}
.panel-group .panel-body.card-body {
  padding: 0.5rem 1.75rem 1.25rem;
}
.panel-group .panel-heading.card-header {
  padding: 1rem 1.75rem;
}
.panel-group .panel-heading.card-header .btn {
  padding: initial;
}

/* 16.Dashboard*/
.dashboard-best-sellers {
  height: 488px;
}

.dashboard-line-chart {
  height: 283px;
}

.dashboard-quick-post {
  min-height: 283px;
}

.dashboard-list-with-thumbs {
  height: 496px;
}

.dashboard-logs {
  height: 270px;
}

.dashboard-list-with-user {
  height: 270px;
}

.dashboard-donut-chart {
  height: 270px;
}

.dashboard-small-chart {
  height: 150px;
}
.dashboard-small-chart .chart-container {
  height: 60px;
}
.dashboard-small-chart .lead {
  font-size: 1.4rem;
}

.dashboard-small-chart-analytics {
  height: 180px;
}
.dashboard-small-chart-analytics .chart {
  height: 80px;
}
.dashboard-small-chart-analytics .lead {
  font-size: 1.4rem;
}

.dashboard-filled-line-chart {
  height: 340px;
}
.dashboard-filled-line-chart .chart {
  height: 200px;
}

.dashboard-numbers .owl-nav, .dashboard-numbers .owl-dots {
  display: none;
}

.dashboard-sq-banner {
  background-image: linear-gradient(to right top, #aa4f435e, rgba(35, 31, 32, 0.5), #ffffff);
  background-size: cover;
  height: 385px;
  transition: 0.5s;
  background-size: 350% auto;
  cursor: pointer;
}
.dashboard-sq-banner .card-body {
  width: 270px;
}
.dashboard-sq-banner .lead {
  line-height: 2.3rem;
}
.dashboard-sq-banner:hover {
  background-position: right top;
}

.dashboard-link-list {
  height: 385px;
}

.dashboard-progress {
  height: 385px;
}

.dashboard-top-rated {
  height: 300px;
}
.dashboard-top-rated .glide img {
  height: 120px;
  display: flex;
  object-fit: cover;
  width: 100%;
}

.rounded .border-radius, .rounded .dashboard-top-rated .glide img, .rounded .notification, .rounded .popover, .rounded .tooltip-inner {
  border-radius: 0.75rem;
}

.dashboard-search {
  height: 650px;
  background: url("../../img/login/plane.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
}
.dashboard-search .card-body {
  padding: 120px 50px 30px 50px;
}
@media (max-width: 1439px) {
  .dashboard-search .card-body {
    padding: 80px 30px 30px 30px;
  }
}
.dashboard-search .form-container {
  height: 400px;
  border-radius: 0.1rem;
  box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
  padding: 2rem;
  background-color: white;
}

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
}
.icon-cards-row .glide__slides .glide__slide {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.icon-cards-row .card-body {
  padding: 2rem 0.5rem;
}
.icon-cards-row .card-text {
  color: #3E3A3E;
  height: 30px;
  line-height: 26px;
}
.icon-cards-row .lead {
  color: #3E3A3E;
  margin-bottom: 0;
}
.icon-cards-row i {
  font-size: 38px;
  line-height: 66px;
  color: #3E3A3E;
}
.icon-cards-row .card {
  transition: box-shadow 1000ms;
  cursor: pointer;
}
@media (max-width: 1439px) {
  .icon-cards-row .card-text {
    height: 48px;
    line-height: 18px;
  }
  .icon-cards-row .lead {
    font-size: 1.6rem;
  }
  .icon-cards-row i {
    font-size: 32px;
    line-height: 47px;
  }
}

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid #3E3A3E;
  border-radius: 14px;
  display: inline-block;
}

/* 17.Calendar */
.fc {
  height: 490px !important;
}
.fc .table-bordered th,
.fc .table-bordered td {
  border: none;
}
.fc .fc-scrollgrid-sync-table {
  height: 400px !important;
}
.fc .fc-toolbar-title {
  font-size: 0.85rem !important;
  line-height: 1.3rem !important;
}
.fc .fc-day {
  border-bottom: 1px solid;
  border-right: 1px solid;
  font-size: 0.8rem !important;
  border-color: #f3f3f3 !important;
}
.fc .fc-col-header-cell {
  color: #3E3A3E;
}
.fc .fc-day:not(:last-child) {
  border-right: 1px solid;
  border-bottom: 1px solid;
}
.fc .fc-day:last-child {
  border-right: 0px solid;
  border-bottom: 1px solid;
}
.fc .table-bordered {
  border: unset !important;
}
.fc .cal-month-view .cal-cell-row {
  border-bottom: 1px solid;
  border-color: #f3f3f3;
}
.fc .fc-day:hover {
  background: white !important;
}
.fc .fc-day-today {
  color: #3E3A3E;
  background-color: #f3f3f3 !important;
}
.fc .fa {
  font-family: "simple-line-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.fc .fa-chevron-right::before {
  content: "\e606";
}
.fc .fa-chevron-left::before {
  content: "\e605";
}
.fc .fc-toolbar-chunk .btn-group .btn {
  height: 32px !important;
}
.fc .fc-toolbar-chunk .fc-today-button {
  height: 32px;
  line-height: 1rem;
}
.fc .fc-toolbar.fc-header-toolbar .fc-prev-button {
  margin-right: 0;
}
.fc .fc-toolbar.fc-header-toolbar .fc-next-button {
  margin-right: 5px;
}

/* 18.Datatable */
.ngx-datatable .datatable-header {
  margin-bottom: 15px;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap {
  white-space: nowrap;
  font-weight: bold;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap:before {
  right: 3em;
  content: "↑";
  position: absolute;
  opacity: 0.3;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap:after {
  right: 2.5em;
  content: "↓";
  position: absolute;
  opacity: 0.3;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sort-active.sort-asc .datatable-header-cell-template-wrap:after {
  opacity: 1;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sort-active.sort-desc .datatable-header-cell-template-wrap:before {
  opacity: 1;
}

.datatable-body-cell {
  overflow: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: max-content;
}
.datatable-body-cell .datatable-body-cell-label {
  text-wrap: wrap;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}

.ngx-datatable.material {
  text-align: left;
  padding: 0rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 0px;
}

datatable-row-wrapper:last-of-type .datatable-body-row {
  border-bottom: initial !important;
}

.ngx-datatable .custom-control.custom-checkbox {
  margin-top: -4px;
}

.data-table-scrollable .datatable-body .datatable-scroll {
  padding-bottom: 20px;
}

.data-table-rows .ngx-datatable {
  margin-left: -15px;
  padding-left: 15px;
  margin-right: -15px;
  padding-right: 15px;
}
.data-table-rows .ngx-datatable .datatable-body .datatable-row-wrapper {
  background: white;
  border-radius: 0.75rem;
  border: initial;
  width: 100% !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.data-table-rows .ngx-datatable .datatable-body .datatable-scroll {
  width: 100% !important;
}
.data-table-rows .ngx-datatable .datatable-body .datatable-row-wrapper {
  width: 100% !important;
}
.data-table-rows datatable-selection {
  width: 100% !important;
}

.data-table-responsive .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-row-center datatable-body-cell:first-of-type {
  padding-left: 0 !important;
}
.data-table-responsive .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-row-center datatable-body-cell:first-of-type .glyph-icon {
  position: absolute;
  left: 12px;
  top: 4px;
}
@media (min-width: 1199px) {
  .data-table-responsive .datatable-row-detail {
    display: none;
  }
}
.data-table-responsive .datatable-row-detail .detail-content {
  padding-left: 35px;
  padding-right: 35px;
}

/* 19.Alerts */
.alert {
  border-radius: 0;
}

.alert-primary {
  background-color: #D3D3D3;
  color: #3E3A3E;
  border-color: rgba(62, 58, 62, 0.1);
}

.alert-secondary {
  color: #9A1915;
  background-color: #e5cbcb;
  border-color: rgba(154, 25, 21, 0.1);
}

.alert-success {
  color: #3e884f;
  background-color: #d3e2d6;
  border-color: rgba(62, 136, 79, 0.1);
}

.alert-info {
  color: #3195a5;
  background-color: #d0e4e7;
  border-color: rgba(49, 149, 165, 0.1);
}

.alert-warning {
  color: #b69329;
  background-color: #ebe4cf;
  border-color: rgba(182, 147, 41, 0.1);
}

.alert-danger {
  color: #c43d4b;
  background-color: #eed3d5;
  border-color: rgba(196, 61, 75, 0.1);
}

.alert-light {
  color: #fcf9f9;
  background-color: #f6f6f6;
  border-color: rgba(236, 236, 236, 0.1);
}

.alert-dark {
  color: #1F1C1D;
  background-color: #cdcccc;
  border-color: rgba(31, 28, 29, 0.1);
}

.alert-dismissible .close,
button.close {
  text-shadow: initial;
}

.alert *[data-notify=title] {
  display: block;
  font-size: 0.9rem;
}

div[data-notify=container] {
  padding: 18px;
}

.simple-notification {
  border-radius: 0.1rem;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.simple-notification .icon {
  padding-top: 10px !important;
  width: 40px !important;
  height: 50px !important;
}

.simple-notification .sn-title {
  font-size: 1rem !important;
  line-height: 1 !important;
  margin-bottom: 0.5rem !important;
}

.simple-notification .sn-content {
  font-size: 0.8rem !important;
}

.simple-notification.success {
  color: #3e884f !important;
  background-color: #d3e2d6 !important;
  border-color: rgba(62, 136, 79, 0.1) !important;
}
.simple-notification.success .icon svg {
  fill: #3e884f !important;
}

.simple-notification .sn-progress-loader {
  margin: 15px 7%;
  height: 2px !important;
  width: 86% !important;
  top: initial !important;
  bottom: 0 !important;
  opacity: 0.75;
}

.simple-notification.success .sn-progress-loader span {
  border-radius: 0.1rem;
}

.simple-notification.bare .icon {
  display: none;
}

.text-dark {
  color: #3E3A3E;
}

.simple-notification.outline.primary {
  background-color: white !important;
  border: 1px solid #3E3A3E !important;
  color: snow !important;
}
.simple-notification.outline.primary .sn-title {
  color: #3E3A3E !important;
}
.simple-notification.outline.primary .sn-progress-loader span {
  background: #3E3A3E !important;
}

.simple-notification.outline.secondary {
  background-color: white !important;
  border: 1px solid #9A1915 !important;
  color: snow !important;
}
.simple-notification.outline.secondary .sn-title {
  color: #9A1915 !important;
}

.simple-notification.outline.info {
  background-color: white !important;
  border: 1px solid #3195a5 !important;
  color: snow !important;
}
.simple-notification.outline.info .sn-title {
  color: #3195a5 !important;
}
.simple-notification.outline.info .icon svg {
  fill: #3195a5 !important;
}

.simple-notification.outline.success {
  background-color: white !important;
  border: 1px solid #3e884f !important;
  color: snow !important;
}
.simple-notification.outline.success .sn-title {
  color: #3e884f !important;
}
.simple-notification.outline.success .icon svg {
  fill: #3e884f !important;
}

.simple-notification.outline.warn {
  background-color: white !important;
  border: 1px solid #b69329 !important;
  color: snow !important;
}
.simple-notification.outline.warn .sn-title {
  color: #b69329 !important;
}
.simple-notification.outline.warn .icon svg {
  fill: #b69329 !important;
}

.simple-notification.outline.error {
  background-color: white !important;
  border: 1px solid #c43d4b !important;
  color: snow !important;
}
.simple-notification.outline.error .sn-title {
  color: #c43d4b !important;
}
.simple-notification.outline.error .icon svg {
  fill: #c43d4b !important;
}

.simple-notification.primary {
  color: #3E3A3E !important;
  background-color: #d3d2d3 !important;
  border-color: rgba(62, 58, 62, 0.1) !important;
}
.simple-notification.primary .sn-progress-loader {
  background: rgba(62, 58, 62, 0.1) !important;
}
.simple-notification.primary .sn-progress-loader span {
  background: rgba(62, 58, 62, 0.5) !important;
}

.simple-notification.secondary {
  color: #9A1915 !important;
  background-color: #e5cbcb !important;
  border-color: rgba(154, 25, 21, 0.1) !important;
}
.simple-notification.secondary .sn-progress-loader {
  background: rgba(154, 25, 21, 0.1) !important;
}
.simple-notification.secondary .sn-progress-loader span {
  background: rgba(154, 25, 21, 0.5) !important;
}

.simple-notification.info {
  color: #3195a5 !important;
  background-color: #d0e4e7 !important;
  border-color: rgba(49, 149, 165, 0.1) !important;
}
.simple-notification.info .icon svg {
  fill: #3195a5 !important;
}
.simple-notification.info .sn-progress-loader {
  background: rgba(49, 149, 165, 0.1) !important;
}
.simple-notification.info .sn-progress-loader span {
  background: rgba(49, 149, 165, 0.5) !important;
}

.simple-notification.error {
  color: #c43d4b !important;
  background-color: #EA5045 !important;
  border-color: rgba(196, 61, 75, 0.1) !important;
}
.simple-notification.error .icon svg {
  fill: #c43d4b !important;
}
.simple-notification.error .sn-progress-loader {
  background: rgba(196, 61, 75, 0.1) !important;
}
.simple-notification.error .sn-progress-loader span {
  background: rgba(196, 61, 75, 0.5) !important;
}

.simple-notification.success {
  color: #3e884f !important;
  background-color: #d3e2d6 !important;
  border-color: rgba(62, 136, 79, 0.1) !important;
}
.simple-notification.success .icon svg {
  fill: #3e884f !important;
}
.simple-notification.success .sn-progress-loader {
  background: rgba(62, 136, 79, 0.1) !important;
}
.simple-notification.success .sn-progress-loader span {
  background: rgba(62, 136, 79, 0.5) !important;
}

.simple-notification.warn {
  color: #b69329 !important;
  background-color: #ebe4cf !important;
  border-color: rgba(182, 147, 41, 0.1) !important;
}
.simple-notification.warn .icon svg {
  fill: #b69329 !important;
}
.simple-notification.warn .sn-progress-loader {
  background: rgba(182, 147, 41, 0.1) !important;
}
.simple-notification.warn .sn-progress-loader span {
  background: rgba(182, 147, 41, 0.5) !important;
}

/* 20.Forms */
.form-check-label,
.custom-control-label {
  line-height: 24px;
  padding-top: 1px;
}

.form-control,
.bootstrap-tagsinput {
  border-radius: 0.1rem;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem;
  line-height: 1;
  border: 1px solid #d7d7d7;
  background: white;
  color: #9A1915;
  border-color: #d7d7d7;
  height: initial;
}

.form-control-sm {
  padding: 0.5rem;
  font-size: 0.75rem;
  height: auto !important;
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  height: calc(1.5em + 1rem + 2px) !important;
}

select {
  outline: initial !important;
  box-shadow: initial !important;
}

textarea.form-control {
  line-height: 1.5;
}

@keyframes autofill {
  to {
    color: snow;
    background: transparent;
  }
}
input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: snow !important;
}

.bootstrap-tagsinput input {
  color: snow;
}

.has-float-label label::after,
.has-float-label > span::after,
.has-float-label > span::after {
  background: white;
}

.has-float-label > div.error::after {
  background: initial;
}

.form-control:focus {
  background: white;
  color: #9A1915;
}

.bootstrap-tagsinput {
  width: 100%;
}

.bootstrap-tagsinput input {
  padding: 0;
}

.bootstrap-tagsinput .tag [data-role=remove] {
  margin-top: -1px;
  display: inline-block;
  vertical-align: top;
}

.bootstrap-tagsinput .tag [data-role=remove]:hover {
  box-shadow: initial;
}

.bootstrap-tagsinput .tag {
  background: #3E3A3E;
  border-radius: 15px;
  padding: 3px 8px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
}

.form-control:focus,
.bootstrap-tagsinput.focus {
  border-color: rgba(62, 58, 62, 0.6);
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(255, 250, 250, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}

.custom-control-label::before {
  border: 1px solid #125354 !important;
  background: initial;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #3E3A3E;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3E3A3E !important;
  box-shadow: initial !important;
  border: 1px solid #3E3A3E !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3E3A3E;
  box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3E3A3E;
  box-shadow: initial !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
}

.custom-control-label::before {
  top: 0.25rem;
}

.custom-control-label::after {
  top: 0.25rem;
}

.btn.rotate-icon-click i {
  transition: transform 0.5s;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid #fff;
}

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #fff;
}

.btn-group-icon {
  line-height: 22px;
}

.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::placeholder {
  opacity: 1;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .bootstrap-tagsinput {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem;
}

.has-top-label {
  display: block;
  position: relative;
}

.has-top-label label,
.has-top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600;
}

.has-top-label label::after,
.has-top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::placeholder {
  opacity: 1;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-top-label .form-control {
  width: 100%;
}

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group {
  position: relative;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .bootstrap-tagsinput {
  height: calc(3.3rem + 2px);
}

.form-group.has-top-label textarea.form-control {
  min-height: calc(3.3rem + 2px);
  height: initial;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

.invalid-tooltip,
.valid-tooltip,
div.error:not(.simple-notification) {
  border-radius: 0.1rem;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: #3E3A3E;
  background: #D3D3D3;
  border: 1px solid transparent;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  display: block;
}
.invalid-tooltip::before,
.valid-tooltip::before,
div.error:not(.simple-notification)::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #D3D3D3;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}
.invalid-tooltip::after,
.valid-tooltip::after,
div.error:not(.simple-notification)::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #D3D3D3;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.tooltip-right-bottom .invalid-tooltip,
.tooltip-right-bottom .valid-tooltip,
.tooltip-right-bottom div.error {
  left: initial;
  right: -10px;
  transform: translateX(0);
  top: initial;
  bottom: -40px;
}
.tooltip-right-bottom .invalid-tooltip::before, .tooltip-right-bottom .invalid-tooltip::after,
.tooltip-right-bottom .valid-tooltip::before,
.tooltip-right-bottom .valid-tooltip::after,
.tooltip-right-bottom div.error::before,
.tooltip-right-bottom div.error::after {
  left: initial;
  right: 25px;
  margin-left: 0;
}

.tooltip-center-bottom .invalid-tooltip,
.tooltip-center-bottom .valid-tooltip,
.tooltip-center-bottom div.error {
  left: 50%;
  right: initial;
  transform: translateX(-50%);
  top: initial;
  bottom: -33px;
}

.tooltip-left-bottom .invalid-tooltip,
.tooltip-left-bottom .valid-tooltip,
.tooltip-left-bottom div.error {
  left: 0;
  right: initial;
  transform: translateX(0);
  top: initial;
  bottom: -33px;
}
.tooltip-left-bottom .invalid-tooltip::before, .tooltip-left-bottom .invalid-tooltip::after,
.tooltip-left-bottom .valid-tooltip::before,
.tooltip-left-bottom .valid-tooltip::after,
.tooltip-left-bottom div.error::before,
.tooltip-left-bottom div.error::after {
  left: 25px;
  right: initial;
  margin-left: 0;
}

.tooltip-center-top .invalid-tooltip,
.tooltip-center-top .valid-tooltip,
.tooltip-center-top div.error {
  transform: translateX(-50%) translateY(50%);
  bottom: initial;
  top: -17px;
}
.tooltip-center-top .invalid-tooltip::before,
.tooltip-center-top .valid-tooltip::before,
.tooltip-center-top div.error::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: -5px;
  border-top: solid 5px #3E3A3E;
  border-bottom: initial;
}
.tooltip-center-top .invalid-tooltip::after,
.tooltip-center-top .valid-tooltip::after,
.tooltip-center-top div.error::after {
  content: "";
  position: absolute;
  top: initial;
  bottom: -4px;
  border-top: solid 5px white;
  border-bottom: initial;
}

.tooltip-right-top .invalid-tooltip,
.tooltip-right-top .valid-tooltip,
.tooltip-right-top div.error {
  transform: translateX(0) translateY(50%);
  top: initial;
  left: initial;
  right: 0;
  bottom: initial;
  top: -17px;
}
.tooltip-right-top .invalid-tooltip::before,
.tooltip-right-top .valid-tooltip::before,
.tooltip-right-top div.error::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: -5px;
  border-top: solid 5px #3E3A3E;
  border-bottom: initial;
  left: initial;
  right: 25px;
  margin-left: 0;
}
.tooltip-right-top .invalid-tooltip::after,
.tooltip-right-top .valid-tooltip::after,
.tooltip-right-top div.error::after {
  content: "";
  position: absolute;
  top: initial;
  bottom: -4px;
  border-top: solid 5px white;
  border-bottom: initial;
  left: initial;
  right: 25px;
  margin-left: 0;
}

.tooltip-left-top .invalid-tooltip,
.tooltip-left-top .valid-tooltip,
.tooltip-left-top div.error {
  transform: translateX(0) translateY(50%);
  top: initial;
  left: 0;
  right: initial;
  bottom: initial;
  top: -17px;
}
.tooltip-left-top .invalid-tooltip::before,
.tooltip-left-top .valid-tooltip::before,
.tooltip-left-top div.error::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: -5px;
  border-top: solid 5px #3E3A3E;
  border-bottom: initial;
  left: 25px;
  right: initial;
  margin-left: 0;
}
.tooltip-left-top .invalid-tooltip::after,
.tooltip-left-top .valid-tooltip::after,
.tooltip-left-top div.error::after {
  content: "";
  position: absolute;
  top: initial;
  bottom: -4px;
  border-top: solid 5px white;
  border-bottom: initial;
  left: 25px;
  right: initial;
  margin-left: 0;
}

.tooltip-label-right .invalid-tooltip,
.tooltip-label-right .valid-tooltip,
.tooltip-label-right div.error {
  transform: translateX(0) translateY(-50%);
  top: 16px;
  left: 50px;
}
.tooltip-label-right .invalid-tooltip::before,
.tooltip-label-right .valid-tooltip::before,
.tooltip-label-right div.error::before {
  content: "";
  position: absolute;
  left: -5px;
  right: initial;
  margin-left: 0;
  border: initial;
  border-top: solid 5px transparent;
  border-bottom: solid 5px transparent;
  border-right: solid 5px #3E3A3E;
  bottom: initial;
  top: 12px;
  width: 5px;
}
.tooltip-label-right .invalid-tooltip::after,
.tooltip-label-right .valid-tooltip::after,
.tooltip-label-right div.error::after {
  content: "";
  position: absolute;
  top: initial;
  left: -4px;
  right: initial;
  margin-left: 0;
  width: 5px;
  border: initial;
  border-top: solid 5px transparent;
  border-bottom: solid 5px transparent;
  border-right: solid 5px white;
  bottom: initial;
  top: 12px;
  width: 5px;
}

.tooltip-label-right .error-l-0 .invalid-tooltip,
.tooltip-label-right .error-l-0 .valid-tooltip,
.tooltip-label-right .error-l-0 div.error {
  left: 0px;
}
.tooltip-label-right .error-l-25 .invalid-tooltip,
.tooltip-label-right .error-l-25 .valid-tooltip,
.tooltip-label-right .error-l-25 div.error {
  left: 25px;
}
.tooltip-label-right .error-l-50 .invalid-tooltip,
.tooltip-label-right .error-l-50 .valid-tooltip,
.tooltip-label-right .error-l-50 div.error {
  left: 50px;
}
.tooltip-label-right .error-l-75 .invalid-tooltip,
.tooltip-label-right .error-l-75 .valid-tooltip,
.tooltip-label-right .error-l-75 div.error {
  left: 75px;
}
.tooltip-label-right .error-l-100 .invalid-tooltip,
.tooltip-label-right .error-l-100 .valid-tooltip,
.tooltip-label-right .error-l-100 div.error {
  left: 100px;
}
.tooltip-label-right .error-l-125 .invalid-tooltip,
.tooltip-label-right .error-l-125 .valid-tooltip,
.tooltip-label-right .error-l-125 div.error {
  left: 125px;
}
.tooltip-label-right .error-l-150 .invalid-tooltip,
.tooltip-label-right .error-l-150 .valid-tooltip,
.tooltip-label-right .error-l-150 div.error {
  left: 150px;
}
.tooltip-label-right .error-l-175 .invalid-tooltip,
.tooltip-label-right .error-l-175 .valid-tooltip,
.tooltip-label-right .error-l-175 div.error {
  left: 175px;
}
.tooltip-label-right .error-l-200 .invalid-tooltip,
.tooltip-label-right .error-l-200 .valid-tooltip,
.tooltip-label-right .error-l-200 div.error {
  left: 200px;
}
.tooltip-label-right .error-l-225 .invalid-tooltip,
.tooltip-label-right .error-l-225 .valid-tooltip,
.tooltip-label-right .error-l-225 div.error {
  left: 225px;
}
.tooltip-label-right .error-l-250 .invalid-tooltip,
.tooltip-label-right .error-l-250 .valid-tooltip,
.tooltip-label-right .error-l-250 div.error {
  left: 250px;
}
.tooltip-label-right .error-l-275 .invalid-tooltip,
.tooltip-label-right .error-l-275 .valid-tooltip,
.tooltip-label-right .error-l-275 div.error {
  left: 275px;
}
.tooltip-label-right .error-t-negative .invalid-tooltip,
.tooltip-label-right .error-t-negative .valid-tooltip,
.tooltip-label-right .error-t-negative div.error {
  top: -10px;
}

.form-group.is-invalid .invalid-tooltip {
  display: block;
}

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  color: white;
  border-radius: 0.2rem;
  right: 4px;
  color: #3e884f;
}

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: white;
  border-radius: 0.2rem;
  right: 4px;
  color: #c43d4b;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d7d7d7;
  background: initial !important;
  padding: 0.5rem 0.75rem !important;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #d7d7d7;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: rgba(62, 58, 62, 0.6);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: rgba(62, 58, 62, 0.6);
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background: initial;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: snow;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #3E3A3E;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: snow;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background: initial;
}

.ng-select .ng-select-container,
.ng-value {
  color: #9A1915;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #8F8F8F;
  color: #fff;
}

.ng-select .ng-select-container {
  border-radius: 0.1rem !important;
  border: 1px solid #d7d7d7;
  font-size: 0.8rem;
  padding: 0.15rem;
  line-height: 1;
  outline: initial !important;
  box-shadow: initial !important;
}

.ng-select.ng-select-focused .ng-select-container {
  outline: initial !important;
  box-shadow: initial !important;
  border-color: #d7d7d7 !important;
}

.ng-select.ng-select-opened .ng-select-container {
  border-color: #3E3A3E !important;
  z-index: 3 !important;
}

.ng-dropdown-panel.ng-select-bottom {
  border-color: #3E3A3E;
  border-top-color: white;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 3px;
  padding-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background: initial;
  border: 1px solid #d7d7d7;
  margin-bottom: 2px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 5px 5px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 0.75rem;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-bottom: 3px;
  padding-top: 3px;
  color: #9A1915;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: initial;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  padding: 1px 6px 1px 6px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: initial;
  color: #3E3A3E;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding-top: 3px;
  color: #9A1915;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #d7d7d7 transparent transparent;
  top: 1px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #d7d7d7;
  top: -1px;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #3E3A3E;
}

.ng-select .ng-clear-wrapper {
  color: #d7d7d7;
}
.ng-select .ng-clear-wrapper:hover {
  color: #3E3A3E;
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #3E3A3E;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border: initial;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #9A1915;
}

.ng-select .ng-select-container,
.ng-select.ng-select-opened > .ng-select-container {
  background-color: white !important;
}

.form-group.has-top-label .ng-select .ng-select-container {
  min-height: calc(3.3rem + 2px);
}
.form-group.has-top-label .ng-select .ng-select-container .ng-value-container {
  padding-top: 20px;
}
.form-group.has-top-label .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 23px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #3E3A3E;
  color: #fff;
}

.input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #d7d7d7;
  color: #8F8F8F;
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.typeahead.dropdown-menu {
  width: 100%;
}
.typeahead.dropdown-menu .active a {
  background-color: #3E3A3E;
  color: #fff;
}

.bs-datepicker-head button {
  color: #9A1915;
  font-size: 0.8rem;
}

.bs-datepicker-head {
  background-color: transparent !important;
}

.bs-datepicker {
  box-shadow: initial !important;
  border: 1px solid #d7d7d7;
}

.bs-datepicker-body {
  background-color: transparent !important;
  border: initial;
}

.bs-datepicker-body table td,
.bs-datepicker-body table td span,
.bs-datepicker-head button span {
  text-align: center !important;
}

.bs-datepicker-head {
  text-align: justify !important;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: rgba(62, 58, 62, 0.4);
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
  color: rgba(62, 58, 62, 0.2);
}

.datepicker-inline .bs-datepicker {
  border: initial !important;
}

.bs-datepicker-container {
  padding: 0;
}

.datepicker-inline .bs-datepicker-head,
.datepicker-inline .bs-datepicker-body {
  min-width: unset;
}

.bs-datepicker {
  border-radius: 0.75rem;
  background-color: snow !important;
}

.datepicker-inline {
  width: 100%;
}
.datepicker-inline bs-datepicker-inline-container,
.datepicker-inline .bs-datepicker,
.datepicker-inline .bs-datepicker-container,
.datepicker-inline .bs-media-container,
.datepicker-inline bs-days-calendar-view,
.datepicker-inline bs-month-calendar-view,
.datepicker-inline bs-years-calendar-view {
  width: 100%;
}

.bs-datepicker-body table td {
  color: #856f5a;
}

.bs-datepicker-body table td.week span {
  color: #3E3A3E !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*=select-]:after,
.bs-datepicker-body table td[class*=select-] span:after {
  background-color: #3E3A3E !important;
}

.bs-datepicker-body table th {
  color: #d7d7d7 !important;
  font-size: 0.8rem !important;
}

.timepicker .btn.btn-default {
  border: 1px solid #d7d7d7;
  border-radius: 0.1rem;
  padding-left: 15px;
  padding-right: 15px;
}

.timepicker tbody tr:first-of-type .btn {
  padding-top: 0;
  padding-bottom: 0;
}

.timepicker tbody tr:last-of-type .btn {
  padding-top: 0;
  padding-bottom: 0;
}

.timepicker table {
  margin-top: -5px;
}

.bs-timepicker-field.is-invalid {
  border-color: #c43d4b !important;
}

.bs-chevron {
  width: 7px !important;
  height: 7px !important;
  border-width: 1px 0px 0 1px !important;
  color: snow !important;
  cursor: pointer;
}
.bs-chevron:hover {
  color: #3E3A3E !important;
}

dropzone > .dropzone.dz-wrapper {
  min-height: 115px !important;
  border: 1px solid #d7d7d7 !important;
  background: white !important;
  padding: 10px 10px !important;
  border-radius: 0.1rem !important;
  color: snow !important;
  height: auto !important;
  overflow: initial !important;
}
dropzone > .dropzone.dz-wrapper .img-thumbnail {
  height: 58px !important;
  width: 100% !important;
  object-fit: cover !important;
}

dropzone > .dropzone.dz-clickable .dz-message,
dropzone > .dropzone.dz-clickable .dz-message * {
  position: relative !important;
  transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0 !important;
  padding: initial !important;
  width: initial !important;
  min-width: initial !important;
  height: initial !important;
  min-height: initial !important;
  border: initial !important;
  background: initial !important;
  display: block !important;
  max-width: initial !important;
  max-height: initial !important;
  overflow: initial !important;
}

dropzone > .dropzone.dz-clickable .dz-message div {
  top: 55px !important;
  display: inline-block !important;
}

.dropzone .dz-preview {
  margin: 16px !important;
}

dropzone > .dropzone .dz-preview.dz-image-preview,
dropzone > .dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid #d7d7d7 !important;
  border-radius: 0.1rem !important;
  background: white !important;
  color: snow !important;
}
dropzone > .dropzone .dz-preview.dz-image-preview .preview-container,
dropzone > .dropzone .dz-preview.dz-file-preview .preview-container {
  transition: initial !important;
  animation: initial !important;
  margin-left: 0;
  margin-top: 0;
  position: relative;
  width: 100%;
  height: 100%;
}
dropzone > .dropzone .dz-preview.dz-image-preview .preview-container i,
dropzone > .dropzone .dz-preview.dz-file-preview .preview-container i {
  color: #3E3A3E;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 29px;
  transform: translateX(-50%) translateY(-50%) !important;
  height: 22px;
}
dropzone > .dropzone .dz-preview.dz-image-preview strong,
dropzone > .dropzone .dz-preview.dz-file-preview strong {
  font-weight: normal;
}
dropzone > .dropzone .dz-preview.dz-image-preview .remove,
dropzone > .dropzone .dz-preview.dz-file-preview .remove {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #3E3A3E !important;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-details,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-details {
  position: static;
  display: block;
  opacity: 1;
  text-align: left;
  min-width: unset;
  z-index: initial;
  color: snow !important;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-error-mark,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-error-mark {
  color: #fff !important;
  top: 15px;
  left: 25px;
  margin-left: 0;
  margin-top: 0;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-error-mark span,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-error-mark span {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:%233E3A3E;' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:%23FFFFFF;' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:%23FFFFFF;' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
  width: 28px;
  height: 28px;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-success-mark,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-success-mark {
  color: #fff;
  top: 15px;
  left: 25px;
  margin-left: 0;
  margin-top: 0;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-success-mark span,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-success-mark span {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:%233E3A3E;' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:%23FFFFFF;' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
  width: 28px;
  height: 28px;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-progress,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-progress {
  width: 84%;
  margin-left: 0;
  margin-top: 0;
  right: 0;
  height: 5px !important;
  left: 15px;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-progress .dz-upload,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-progress .dz-upload {
  width: 100%;
  background: #3E3A3E !important;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-error-message,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-error-message {
  border-radius: 15px;
  background: #c43d4b !important;
  top: 60px;
}
dropzone > .dropzone .dz-preview.dz-image-preview .dz-error-message:after,
dropzone > .dropzone .dz-preview.dz-file-preview .dz-error-message:after {
  border-bottom: 6px solid #c43d4b !important;
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

dropzone > .dropzone.dz-wrapper.dz-multiple.dz-started .dz-message {
  display: none !important;
}

.dropzone .dz-error.dz-preview.dz-file-preview .preview-icon {
  display: none;
}
.dropzone .dz-error.dz-preview.dz-file-preview .dz-error-mark,
.dropzone .dz-error.dz-preview.dz-file-preview .dz-success-mark {
  color: #3E3A3E !important;
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }
  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }
  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }
  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}

.input-group-text {
  border-radius: 0.1rem;
}

.noUi-target {
  background: white;
  border-color: #d7d7d7;
  box-shadow: initial;
}

.noUi-tooltip {
  background: white;
  color: snow;
  border-color: #d7d7d7;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: 0.1rem;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: 0.1rem;
}

.custom-select {
  border-radius: 0.1rem;
  padding: 0.5rem 0.75rem;
  height: initial;
  font-size: 0.8rem;
  background: white;
  border-color: #d7d7d7;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

select.form-control {
  height: initial;
}

.custom-select:focus {
  border-color: #3E3A3E;
}

.custom-file-input {
  box-shadow: initial !important;
}

.custom-file-label {
  box-shadow: initial !important;
  border-radius: 0.1rem;
  height: calc(2em + 0.8rem);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background: white;
  border-color: #d7d7d7;
}

.custom-file {
  height: calc(2em + 0.8rem);
}

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: rgba(62, 58, 62, 0.6);
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(62, 58, 62, 0.6);
  box-shadow: initial;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: rgba(62, 58, 62, 0.6);
}

.custom-file-label:after,
.input-group > .custom-select:not(:first-child),
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:first-child),
.input-group > .form-control:not(:last-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-file-label::after {
  height: calc(2em + 0.8rem);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  color: snow;
  border-color: #d7d7d7;
  background: transparent;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  background: white;
  color: #9A1915;
  border-color: #d7d7d7;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  background: white;
  color: snow;
  border-color: #d7d7d7;
}

.input-group > .input-group-prepend > .btn-group .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend .btn + .btn-group .btn {
  margin-left: -1px;
}

.input-group-append .btn + .btn-group .btn {
  margin-left: -1px;
}

.jumbotron {
  background: initial;
}

/* 21.Form Wizard */
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator {
  top: initial;
  bottom: -17px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):before {
  top: initial;
  bottom: -9px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator {
  padding-top: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
  text-transform: initial;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.optional .step-indicator,
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator {
  background-color: #f3f3f3;
  border: 4px solid #f3f3f3;
  width: 19px !important;
  height: 19px !important;
}
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.optional .step-indicator:after,
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator:after,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator:after {
  content: "";
  background: initial;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable a:hover .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable a:hover .step-indicator {
  background-color: #f3f3f3 !important;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):before,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):before {
  background-color: #f3f3f3;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label,
aw-wizard-navigation-bar.vertical ul.steps-indicator li a .label {
  padding-top: 0;
  padding-bottom: 10px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.done .step-indicator {
  background-color: #f3f3f3;
}
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator:after,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.done .step-indicator:after {
  content: " ";
  position: relative;
  background: initial;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%233E3A3E' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  display: block;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator {
  background-color: #3E3A3E;
}
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator:after,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator:after {
  content: "";
  background: initial;
}

aw-wizard.horizontal aw-wizard-step {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

aw-wizard.horizontal .complete-message-centered {
  top: calc(50% - 20px);
  position: relative;
  transform: translateY(-50%);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator {
  padding-top: 40px;
  padding-bottom: 15px;
}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):before {
  left: -5px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
  border-width: 1px !important;
  left: calc(50% - 20px) !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  top: -40px !important;
  border-color: #f3f3f3;
  font-size: 16px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li a .label {
  padding-top: 10px;
  padding-bottom: 0px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):before {
  background-color: #f3f3f3;
  width: calc(100% - 40px);
  top: -20px;
  left: calc(50% + 20px);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing .step-indicator {
  border-color: #3E3A3E;
  color: #3E3A3E;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  border-color: #3E3A3E;
  color: #3E3A3E;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  color: #d7d7d7;
  border-color: #d7d7d7;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done.current .step-indicator {
  border-color: #3E3A3E;
  color: #3E3A3E;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator,
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator {
  border-color: #3E3A3E;
  color: #3E3A3E;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after {
  top: 30px;
  width: calc(100% - 14px);
  left: calc(50% + 12px);
}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):after {
  top: 20px;
  left: -5px;
}

/* 22.Slider(Range) */
.noUi-connect {
  background: #3E3A3E;
}

.noUi-connects {
  border-radius: 0.1rem;
}

.noUi-handle:after,
.noUi-handle:before {
  content: "";
  display: none;
}

.noUi-handle {
  outline: initial;
  box-shadow: initial !important;
}

.noUi-horizontal {
  height: 8px;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  border-radius: 20px;
  top: -6px;
  right: -13px;
  border-color: #d7d7d7;
  background: white;
}

.noUi-horizontal .noUi-tooltip {
  top: 120%;
  bottom: unset;
  padding-left: 10px;
  padding-right: 10px;
}

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white !important;
  background-color: #3E3A3E;
}

.nav-pills .nav-link {
  border-radius: 50px;
}

.nav-link {
  padding: 0.5rem 1rem;
}
@media (max-width: 767px) {
  .nav-link {
    padding: 0.5rem 0.5rem;
  }
}
.nav-link.active {
  color: #3E3A3E;
}

.page-item {
  padding: 10px;
}
@media (max-width: 767px) {
  .page-item {
    padding: 3px;
  }
}
.page-item .page-link {
  outline: initial !important;
  box-shadow: initial !important;
  line-height: 1.7;
  border-radius: 40px !important;
  min-width: 38px;
  text-align: center;
  height: 38px;
  padding: 0.55rem 0;
}
@media (max-width: 575px) {
  .page-item .page-link {
    min-width: 30px;
    height: 30px;
    line-height: 0.9;
    font-size: 0.76rem;
  }
}
.page-item.active .page-link {
  background: #FBBB21;
  border: 1px solid white;
  color: white;
}
.page-item.disabled .page-link {
  border-color: white;
  color: #d7d7d7;
  background: transparent;
}

.page-item.pagination-next .page-link,
.page-item.pagination-prev .page-link {
  background: #FBBB21;
  color: white;
}
.page-item.pagination-next .page-link:hover,
.page-item.pagination-prev .page-link:hover {
  background-color: transparent;
  border-color: #585358;
  color: #3E3A3E;
}

.page-item.pagination-last .page-link,
.page-item.pagination-first .page-link {
  background: #F07E26;
  border: 1px solid #3E3A3E;
  border-radius: 30px;
}
.page-item.pagination-last .page-link:hover,
.page-item.pagination-first .page-link:hover {
  background: #3E3A3E;
  color: white;
  border: 1px solid #3E3A3E;
}

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: snow;
}

.page-link:hover {
  background: #3E3A3E;
  color: white;
  border: 1px solid #3E3A3E;
}

.btn-sm.page-link {
  padding: 0.5rem 0.5rem;
}

.pagination-lg .page-item {
  padding: 15px;
}
@media (max-width: 767px) {
  .pagination-lg .page-item {
    padding: 3px;
  }
}
.pagination-lg .page-item .page-link {
  min-width: 50px;
  height: 50px;
}
@media (max-width: 767px) {
  .pagination-lg .page-item .page-link {
    min-width: 38px;
    height: 38px;
    padding: 0.55rem 0;
    line-height: 1.5;
    font-size: 0.8rem;
  }
}
@media (max-width: 575px) {
  .pagination-lg .page-item .page-link {
    min-width: 30px;
    height: 30px;
    line-height: 0.9;
    font-size: 0.76rem;
  }
}

.pagination-sm .page-item {
  padding: 6px;
}
@media (max-width: 767px) {
  .pagination-sm .page-item {
    padding: 3px;
  }
}
.pagination-sm .page-item .page-link {
  min-width: 30px;
  height: 30px;
  line-height: 0.9;
  font-size: 0.75rem;
  padding: 0.5rem 0;
}

/* 24.Tooltip and popover */
.popover {
  border-radius: 0.1rem;
  background-color: white;
  border-color: #d7d7d7;
}
.popover .popover-body {
  color: snow;
}

.popover-header {
  background-color: transparent;
  border-bottom: initial;
}

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: snow;
  background-color: white;
  border-radius: 0.1rem;
  border: 1px solid #d7d7d7;
}

.tooltip.show {
  opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^=right] .arrow::before {
  border-right-color: #d7d7d7;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: white;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^=left] .arrow::before {
  border-left-color: #d7d7d7;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: white;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #d7d7d7;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: white;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^=top] .arrow::before {
  border-top-color: #d7d7d7;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: white;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: #d7d7d7;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: white;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^=right] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: #d7d7d7;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: white;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^=top] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: #d7d7d7;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: white;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: #d7d7d7;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^=left] .arrow::after {
  right: 0;
  border-left-color: white;
}

.search-sm {
  position: relative;
}
.search-sm input {
  background: none;
  outline: initial !important;
  border-radius: 15px;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  border: 1px solid #3E3A3E;
  color: snow;
}
.search-sm:after {
  font-family: "simple-line-icons";
  content: "\e090";
  font-size: 14px;
  color: #3E3A3E;
  position: absolute;
  right: 10px;
  text-align: center;
  cursor: pointer;
  top: 2px;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
}

/* 25.Progress */
.progress {
  height: 3px;
  background-color: #f3f3f3;
}

.progress-bar {
  background-color: #3E3A3E;
}

.progressbar-text {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: snow !important;
}

.progress-bar-circle {
  width: 54px;
  height: 54px;
}
.progress-bar-circle svg circle:first-of-type {
  opacity: 0.5;
}
.progress-bar-circle svg path:first-of-type,
.progress-bar-circle svg circle:first-of-type {
  stroke: #d7d7d7 !important;
}
.progress-bar-circle svg path:last-of-type {
  stroke: #3E3A3E !important;
}
.progress-bar-circle.progress-bar-banner svg path:first-of-type {
  stroke: #787578 !important;
}
.progress-bar-circle.progress-bar-banner svg path:last-of-type {
  stroke: #fff !important;
}

.progress-banner {
  height: 200px;
  background-image: linear-gradient(to right top, #aa4f435e, rgba(35, 31, 32, 0.5), #ffffff) !important;
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer;
}
.progress-banner .lead {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 1199px) {
  .progress-banner .lead {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
}
.progress-banner i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 1199px) {
  .progress-banner i {
    font-size: 2rem;
    margin-bottom: 0.2rem;
  }
}
.progress-banner .progress-bar-circle.progress-bar-banner {
  width: 120px;
  height: 120px;
}
@media (max-width: 1199px) {
  .progress-banner .progress-bar-circle.progress-bar-banner {
    width: 80px;
    height: 80px;
  }
}
.progress-banner .progress-bar-banner svg path:first-of-type,
.progress-banner .progress-bar-banner svg circle:first-of-type {
  stroke: #161516 !important;
}
.progress-banner .progress-bar-banner svg path:last-of-type {
  stroke: #e6e6e6 !important;
}
.progress-banner .progress-bar-banner .progressbar-text {
  color: #fff !important;
  font-size: 1.7rem;
  width: 100%;
  font-weight: 300;
}
@media (max-width: 1199px) {
  .progress-banner .progress-bar-banner .progressbar-text {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
}
.progress-banner:hover {
  background-position: right top;
}

/* 26.Rating */
rating span {
  outline: initial !important;
}

.bs-rating-star {
  font-family: "simple-line-icons";
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  margin-right: 2px;
}

.bs-rating-star:after {
  content: "\e09b";
  color: #d7d7d7;
}

.bs-rating-star.active:after {
  color: #3E3A3E;
  font-weight: 900;
}

/* 27.Sortable */
.sortable {
  cursor: default;
}

.sortable span {
  vertical-align: middle;
}

.sortable-ghost {
  opacity: 0.5;
}

/* 28.Spinner */
.btn-multiple-state .spinner {
  width: 36px;
  text-align: center;
}
.btn-multiple-state .spinner > div {
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}
.btn-multiple-state .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.btn-multiple-state .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.3);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
/* 29.Modal */
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.75rem;
}
.modal .modal-header {
  border-bottom: 1px solid #d7d7d7;
}
.modal .modal-footer {
  border-top: 1px solid #d7d7d7;
}
.modal .close {
  color: snow;
  text-shadow: initial;
}
.modal .modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: white;
}

.modal-right {
  padding-right: 0 !important;
}
.modal-right.modal-dialog {
  margin: 0 auto;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 380px;
}
.modal-right .modal-content {
  min-height: 100%;
  border-radius: 0.1rem !important;
}
.modal-right .modal-header {
  flex: 0 0 100px;
}
@media (max-width: 1439px) {
  .modal-right .modal-header {
    flex: 0 0 90px;
  }
}
@media (max-width: 1199px) {
  .modal-right .modal-header {
    flex: 0 0 80px;
  }
}
@media (max-width: 767px) {
  .modal-right .modal-header {
    flex: 0 0 70px;
  }
}
.modal-right .modal-footer {
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.modal.fade .modal-right {
  transform: translate(25%, 0);
}
.modal.show .modal-right {
  transform: translate(0, 0);
}

.modal-left {
  padding-left: 0 !important;
}
.modal-left.modal-dialog {
  margin: 0 auto;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 380px;
}
.modal-left .modal-content {
  min-height: 100%;
  border-radius: 0.1rem !important;
}
.modal-left .modal-header {
  flex: 0 0 100px;
}
@media (max-width: 1439px) {
  .modal-left .modal-header {
    flex: 0 0 90px;
  }
}
@media (max-width: 1199px) {
  .modal-left .modal-header {
    flex: 0 0 80px;
  }
}
@media (max-width: 767px) {
  .modal-left .modal-header {
    flex: 0 0 70px;
  }
}
.modal-left .modal-footer {
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.modal.fade .modal-left {
  transform: translate(-25%, 0);
}
.modal.show .modal-left {
  transform: translate(0, 0);
}

/* 30.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.auth-card .image-side {
  width: 40%;
  background-color: #145388;
  background-size: cover;
  padding: 80px 40px;
}
.auth-card .image-side .h3 {
  line-height: 0.8rem;
}
.auth-card .form-side {
  width: 60%;
  padding: 80px;
}
@media (max-width: 991px) {
  .auth-card {
    flex-direction: column;
  }
  .auth-card .image-side {
    width: 100%;
    padding: 60px;
  }
  .auth-card .form-side {
    width: 100%;
    padding: 60px;
  }
}
@media (max-width: 767px) {
  .auth-card p.h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 575px) {
  .auth-card {
    flex-direction: column;
  }
  .auth-card .image-side {
    padding: 35px 30px;
  }
  .auth-card .form-side {
    padding: 35px 30px;
  }
  .auth-card .logo-single {
    margin-bottom: 20px;
  }
  .auth-card p.h2 {
    font-size: 1.4rem;
  }
}

.index-card .card-body {
  padding: 80px;
}
@media (max-width: 991px) {
  .index-card .card-body {
    padding: 60px;
  }
}
@media (max-width: 575px) {
  .index-card .card-body {
    padding: 35px 30px;
  }
}

/* 31.Html Editors */
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #3E3A3E;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #3E3A3E;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #3E3A3E;
}

.ql-toolbar.ql-snow {
  border-color: #d7d7d7;
}

.ql-container.ql-snow {
  border-color: #d7d7d7;
}

.html-editor-bubble .ql-editor {
  border: 1px solid #d7d7d7;
}

.ql-tooltip {
  z-index: 10;
}

.ql-editor {
  height: 200px !important;
}

.ql-container.ql-bubble {
  border: 1px solid #d7d7d7;
}

/* 32.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center !important;
  float: left;
  height: 100px;
}
.simple-line-icons .glyph .glyph-icon,
.simple-line-icons .glyph .fa,
.mind-icons .glyph .glyph-icon,
.mind-icons .glyph .fa {
  font-size: 32px;
  text-align: center !important;
}
.simple-line-icons .glyph .class-name,
.mind-icons .glyph .class-name {
  font-size: 0.76rem;
  color: #125354 !important;
  text-align: center !important;
}
@media (max-width: 1199px) {
  .simple-line-icons .glyph,
.mind-icons .glyph {
    width: 16.66%;
  }
}
@media (max-width: 991px) {
  .simple-line-icons .glyph,
.mind-icons .glyph {
    width: 20%;
  }
}
@media (max-width: 767px) {
  .simple-line-icons .glyph,
.mind-icons .glyph {
    width: 25%;
  }
}
@media (max-width: 575px) {
  .simple-line-icons .glyph,
.mind-icons .glyph {
    width: 50%;
  }
}

.chart-container {
  height: 270px;
}

.theme-colors {
  width: 280px !important;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: white;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transform: translate(280px, -50%);
  padding-top: 10px;
  padding-bottom: 10px;
}
.theme-colors .theme-button {
  position: absolute;
  left: -32px;
  background: white;
  padding: 13px 7px 13px 7px;
  border-radius: 0.2rem;
  color: snow;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #3E3A3E;
}
.theme-colors .theme-color {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 20px;
  transition: background 0.25s;
}
.theme-colors .theme-color.active, .theme-colors .theme-color:hover {
  background: white;
}
.theme-colors .theme-color-purplemonster {
  border: 3px solid #922c88;
  background: #922c88;
}
.theme-colors .theme-color-blueyale {
  border: 3px solid #145388;
  background: #145388;
}
.theme-colors .theme-color-blueolympic {
  border: 3px solid #008ecc;
  background: #008ecc;
}
.theme-colors .theme-color-bluenavy {
  border: 3px solid #00365a;
  background: #00365a;
}
.theme-colors .theme-color-orangecarrot {
  border: 3px solid #ed7117;
  background: #ed7117;
}
.theme-colors .theme-color-greenmoss {
  border: 3px solid #576a3d;
  background: #576a3d;
}
.theme-colors .theme-color-greenlime {
  border: 3px solid #6fb327;
  background: #6fb327;
}
.theme-colors .theme-color-redruby {
  border: 3px solid #900604;
  background: #900604;
}
.theme-colors .theme-color-greysteel {
  border: 3px solid #48494b;
  background: #48494b;
}
.theme-colors .theme-color-yellowgranola {
  border: 3px solid #c0a145;
  background: #c0a145;
}
.theme-colors.shown {
  transform: translate(0, calc(-50% + 0.5px));
  transition: transform 0.4s ease-out;
}
.theme-colors.hidden {
  transform: translate(280px, -50%);
  transition: transform 0.4s ease-out;
}

/* 33.Loading */
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(62, 58, 62, 0.2);
  border-radius: 50%;
  border-top-color: #3E3A3E;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/* 34.Context Menu */
.context-menu-list {
  box-shadow: initial;
  border-radius: 0.1rem;
  background: white;
  border-color: rgba(255, 250, 250, 0.15);
  padding: 0.5rem 0;
}

.context-menu-item {
  padding: 0.75rem 1.5rem;
  background: white;
  color: snow;
}
.context-menu-item.context-menu-hover {
  color: snow;
  text-decoration: none;
  background-color: #f8f8f8;
}

.context-menu-item span {
  font-family: "Nunito", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 0.5rem;
  display: inline-block;
}

.list .card.context-menu-active,
.list .card.active {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}

/* 35.Videojs */
.video-js .vjs-big-play-button {
  background: white;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: initial;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid white;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder {
  color: #3E3A3E;
}

.vjs-poster,
.vjs-tech {
  border-radius: 0.1rem;
}

.vjs-tech {
  background: initial;
  object-fit: cover;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important;
}

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem);
}
.video-js .vjs-control-bar .vjs-control.vjs-button,
.video-js .vjs-control-bar .vjs-remaining-time,
.video-js .vjs-control-bar .vjs-volume-panel {
  margin-right: 0.5em;
  background: white;
  color: #3E3A3E;
  border-radius: 15px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}
.video-js .vjs-control-bar .vjs-progress-control.vjs-control {
  background: white;
  border-radius: 15px;
  margin-right: 0.5em;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}
.video-js .vjs-control-bar .vjs-mute-control.vjs-control {
  box-shadow: initial;
}
.video-js .vjs-control-bar .vjs-progress-holder {
  height: 3px;
  font-size: 1.6em !important;
}
.video-js .vjs-control-bar .vjs-load-progress,
.video-js .vjs-control-bar .vjs-load-progress div {
  background-color: rgba(62, 58, 62, 0.2);
}
.video-js .vjs-control-bar .vjs-play-progress:before {
  font-size: 0.55em;
  top: -0.2em;
}
.video-js .vjs-control-bar .vjs-progress-holder {
  margin: 0 17px;
}
.video-js .vjs-control-bar .vjs-slider {
  text-shadow: initial !important;
  outline: initial !important;
  background-color: #dadada;
}
.video-js .vjs-control-bar .vjs-play-progress {
  background: #3E3A3E;
}
.video-js .vjs-control-bar .vjs-play-progress:before {
  color: #3E3A3E;
}
.video-js .vjs-control-bar .vjs-volume-horizontal {
  margin-left: -1.5em;
  width: 4em;
}
.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level {
  background: #3E3A3E;
}

.video-js.audio {
  background: initial;
}
.video-js.audio .vjs-big-play-button {
  display: none;
}
.video-js.audio .vjs-control-bar {
  display: flex;
}
.video-js.audio .vjs-fullscreen-control {
  display: none;
}
.video-js.audio .vjs-control-bar {
  margin-bottom: 0;
}
.video-js.audio .vjs-control.vjs-button,
.video-js.audio .vjs-remaining-time,
.video-js.audio .vjs-volume-panel {
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
}
.video-js.audio .vjs-progress-control.vjs-control {
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
}
.video-js.audio .vjs-mute-control {
  box-shadow: initial !important;
}
.video-js.audio .vjs-loading-spinner {
  display: none !important;
}

.video-js.side-bar-video {
  width: 100%;
  height: 280px;
  background-color: #f8f8f8;
  overflow: hidden;
}
.video-js.side-bar-video video {
  object-fit: cover;
}
.video-js.side-bar-video .vjs-poster {
  background-size: cover;
}

.video-js.video-content {
  width: 100%;
  height: 400px;
  background-color: #f8f8f8;
  overflow: hidden;
  max-height: unset;
}
.video-js.video-content video {
  object-fit: cover;
}
.video-js.video-content .vjs-poster {
  background-size: cover;
}

/* 36.Prices */
.equal-height-container .col-item .card {
  height: 100%;
}
@media (max-width: 991px) {
  .equal-height-container .col-item .card-body {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .equal-height-container .col-item .card-body {
    text-align: center;
  }
}
.equal-height-container .col-item .price-top-part {
  text-align: center;
}
.equal-height-container .col-item .price-top-part * {
  text-align: center !important;
}
@media (max-width: 991px) {
  .equal-height-container .col-item .price-top-part {
    padding-left: 0;
    padding-right: 0;
    width: 40%;
  }
}
@media (max-width: 575px) {
  .equal-height-container .col-item .price-top-part {
    width: initial;
  }
}
.equal-height-container .col-item .price-feature-list {
  justify-content: space-between;
}
.equal-height-container .col-item .price-feature-list ul {
  margin: 0 auto;
  align-self: flex-start;
  margin-bottom: 1rem;
}
.equal-height-container .col-item .price-feature-list ul li p {
  text-align: center !important;
}

.table-heading {
  box-shadow: initial;
  background: initial;
}

i.large-icon {
  font-size: 38px;
  line-height: 90px;
  color: #3E3A3E;
}
@media (max-width: 767px) {
  i.large-icon {
    line-height: 70px;
  }
}

.large-icon.initial-height {
  line-height: initial;
}
@media (max-width: 767px) {
  .large-icon.initial-height {
    line-height: initial;
  }
}

/* 37.Tables */
.table {
  color: #3E3A3E;
}

.table th,
.table td {
  border-color: #f3f3f3 !important;
}

.table .thead-light th {
  background-color: #f3f3f3 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3 !important;
}

.feature-row {
  margin-top: 80px;
}
@media (max-width: 767px) {
  .feature-row {
    margin-top: 40px;
  }
}

/* 38.Invoice Print
@media print {
  body {
    background: white;
    height: 100%;
  }

  main {
    margin: 0 !important;
  }

  .navbar,
  .menu,
  .theme-colors {
    display: none;
  }

  main .container-fluid .row:not(.invoice) {
    display: none;
  }

  .invoice {
    width: 100%;
    max-width: 100%;

    &.row {
      margin: 0;
    }

    [class*="col"] {
      padding: 0;
    }

    .invoice-contents {
      width: 100%;
      max-width: 100% !important;
      height: 1370px !important;
    }
  }
}*/
/* 39.Profile Page
.social-header {
  width: 100%;
  height: 380px;
  object-fit: cover;
  max-height: 380px;
}

.social-profile-img {
  height: 120px;
  border: 3px solid $foreground-color;
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  top: -75px;
}

.social-icons {
  ul {
    margin-bottom: 0;
  }

  li {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    a {
      color: $secondary-color;
      font-size: 1.2rem;

      &:hover,
      &:active {
        color: rgba($secondary-color, 0.7);
      }
    }
  }
}

.post-icon {
  i {
    font-size: 1.1rem;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    padding-top: 3px;
    vertical-align: middle;
  }
}

.comment-likes {
  text-align: right;
  flex-basis: 90px;
  flex-shrink: 0;
}

.comment-container {
  input {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 20px;
  }
}

.social-image-row {
  [class*="col-"] {
    margin-bottom: 1rem;
  }

  [class*="col-"]:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  [class*="col-"]:nth-child(odd) {
    padding-right: 0.5rem;
  }

  [class*="col-"]:nth-child(even) {
    padding-left: 0.5rem;
  }
}

.gallery-page.row {
  margin-left: -5px;
  margin-right: -5px;
  [class*="col-"] {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.col-left {
  padding-right: 20px;
  @include respond-below(md) {
    padding-right: 15px;
  }
}

.col-right {
  padding-left: 20px;
  @include respond-below(md) {
    padding-left: 15px;
  }
} */
/* 40.Details Page */
.glide.details img {
  margin-bottom: 0;
}
.glide.details .glide__slides {
  margin-bottom: 0;
}

.glide.thumbs {
  cursor: pointer;
  width: 340px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .glide.thumbs {
    width: 300px;
  }
}
@media (max-width: 575px) {
  .glide.thumbs {
    width: calc(100% - 80px);
  }
}
.glide.thumbs li {
  text-align: center;
  opacity: 0.4;
  transition: opacity 200ms;
}
.glide.thumbs li.active, .glide.thumbs li:hover {
  opacity: 1;
}
.glide.thumbs li img {
  width: 60px;
  margin-bottom: 0;
}
.glide.thumbs .glide__slides {
  margin-bottom: 0;
}
.glide.thumbs .glide__arrows {
  width: 100%;
}
.glide.thumbs .glide__arrows .glide__arrow--left {
  border: initial;
  background: initial;
  box-shadow: initial;
  top: 23px;
  left: -50px;
  font-size: 20px;
  color: #3E3A3E;
  position: absolute;
  transform: translateY(-50%);
  line-height: 1;
  text-transform: uppercase;
  padding: 9px 12px;
  cursor: pointer;
}
.glide.thumbs .glide__arrows .glide__arrow--right {
  border: initial;
  background: initial;
  box-shadow: initial;
  top: 23px;
  right: -50px;
  font-size: 20px;
  color: #3E3A3E;
  position: absolute;
  transform: translateY(-50%);
  line-height: 1;
  text-transform: uppercase;
  padding: 9px 12px;
  cursor: pointer;
}

.glide__slides {
  white-space: initial;
}
.glide__slides .glide__slide {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.glide__slide {
  display: flex;
  height: unset;
}
.glide__slide .card {
  display: flex;
  flex-grow: 1;
}
.glide__slide .card .w-50 {
  display: flex;
}
.glide__slide .card .card-body {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.slider-nav {
  text-align: center;
}
.slider-nav .left-arrow,
.slider-nav .right-arrow {
  padding-top: 7px;
  font-size: 20px;
  color: #3E3A3E;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  border: initial;
  position: initial;
  transform: initial;
  top: initial;
  left: initial;
  right: initial;
  padding-left: 5px;
  padding-right: 5px;
}
.slider-nav .glide__arrow {
  padding-top: 15px;
}
.slider-nav .slider-dot-container {
  display: inline-block;
  position: initial;
  text-align: center;
  transform: initial;
  left: initial;
}
.slider-nav .btn:hover, .slider-nav .btn:focus, .slider-nav .btn:active {
  text-decoration: initial;
}

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: #d7d7d7;
  outline: initial !important;
  border: initial;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
  box-shadow: initial;
}
.slider-dot.glide__bullet:hover, .slider-dot.glide__bullet:focus {
  background: #d7d7d7;
  border: initial;
}
.slider-dot.glide__bullet--active {
  background: #3E3A3E !important;
}

.glide .card-img {
  width: 100%;
  height: auto;
}

.glide.details img {
  margin-bottom: 0;
}
.glide.details .glide__slides {
  margin-bottom: 0;
}
.glide.details .glide__slide {
  padding: initial;
}

.glide.thumbs {
  cursor: pointer;
  width: 340px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .glide.thumbs {
    width: 300px;
  }
}
@media (max-width: 575px) {
  .glide.thumbs {
    width: calc(100% - 80px);
  }
}
.glide.thumbs .glide__slide {
  padding: initial;
}
.glide.thumbs li {
  text-align: center;
  opacity: 0.4;
  transition: opacity 200ms;
  height: 45px;
  object-fit: cover;
}
.glide.thumbs li.active, .glide.thumbs li:hover {
  opacity: 1;
}
.glide.thumbs li img {
  width: 60px;
  margin-bottom: 0;
  object-fit: cover;
}
.glide.thumbs .glide__slides {
  margin-bottom: 0;
}
.glide.thumbs .glide__arrows {
  width: 100%;
}
.glide.thumbs .glide__arrows .glide__arrow--left {
  border: initial;
  background: initial;
  box-shadow: initial;
  top: 20px;
  left: -50px;
  font-size: 20px;
  color: #3E3A3E;
}
.glide.thumbs .glide__arrows .glide__arrow--right {
  border: initial;
  background: initial;
  box-shadow: initial;
  top: 20px;
  right: -50px;
  font-size: 20px;
  color: #3E3A3E;
}

.lightbox {
  margin-top: -140px;
  text-align: center !important;
}

.lb-loader {
  text-align: center !important;
}

.lightbox .lb-image {
  border: initial;
}

.lb-outerContainer {
  background: initial;
}

.lb-dataContainer {
  position: absolute;
  top: -50px;
  right: 20px;
}

.lightboxOverlay {
  min-width: 100%;
  margin-top: -200px;
  min-height: 100%;
}

.lb-data .lb-close,
.lb-nav a.lb-next,
.lb-nav a.lb-prev {
  background: initial;
  font-family: "simple-line-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  color: #fff !important;
}

.lb-data .lb-close:before {
  content: "\e082";
}

.lb-loader .lb-cancel {
  background: initial;
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.25em solid #3E3A3E;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.lb-nav a.lb-next:before {
  content: "\e079";
  right: 10px;
  position: absolute;
  top: 50%;
  margin-top: -28px;
}

.lb-nav a.lb-prev:before {
  content: "\e07a";
  left: 10px;
  position: absolute;
  top: 50%;
  margin-top: -28px;
}

app-profile-who-to-follow app-user-follow:last-of-type > div {
  border-bottom: initial !important;
}

/* 41.Blog */
.listing-card-container .listing-heading {
  height: 45px;
  overflow: hidden;
}
@media (max-width: 575px) {
  .listing-card-container .listing-heading {
    height: 40px;
  }
}
.listing-card-container .listing-desc {
  height: 60px;
  overflow: hidden;
  line-height: 1.4;
}
@media (max-width: 1199px) {
  .listing-card-container .listing-desc {
    height: 60px;
  }
}

/* 42.Rounded */
.rounded .modal .modal-content {
  border-radius: 0.75rem;
}
.rounded .simple-notification {
  border-radius: 0.75rem;
}
.rounded .popover,
.rounded .tooltip-inner {
  border-radius: 0.75rem;
}
.rounded .border-radius {
  border-radius: 0.75rem;
}
.rounded .vjs-poster,
.rounded .vjs-tech {
  border-radius: 0.75rem;
}
.rounded .best-rated-items .owl-stage img {
  border-radius: 0.75rem;
}
.rounded .app-menu {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rounded .sub-menu {
  border-radius: 0 0.75rem 0.75rem 0;
}
.rounded .auth-card .image-side {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
@media (max-width: 991px) {
  .rounded .auth-card .image-side {
    border-bottom-right-radius: initial;
    border-bottom-left-radius: initial;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
}
.rounded .card-img,
.rounded .card-img-fluid,
.rounded .card-img-overlay {
  border-radius: 0.75rem;
}
.rounded .sub-hidden .main-menu,
.rounded .menu-sub-hidden .main-menu {
  border-radius: 0 0.75rem 0.75rem 0;
}
.rounded .sub-show-temporary .main-menu {
  border-radius: initial;
}
.rounded .dropdown-menu {
  border-radius: 0.75rem;
}
.rounded .btn.default {
  border-radius: 0.1rem;
}
.rounded .list-thumbnail {
  border-radius: 0.75rem;
}
.rounded .card-img-bottom,
.rounded .card-img-bottom .vjs-poster,
.rounded .card-img-bottom .vjs-tech {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}
.rounded .card-img-top,
.rounded .card-img-top .vjs-poster,
.rounded .card-img-top .vjs-tech {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial;
}
.rounded .card-img-left {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: initial !important;
  border-bottom-right-radius: initial !important;
}
.rounded .card-img-right {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important;
}
.rounded .card {
  border-radius: 0.75rem;
}
.rounded .card.auth-card {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}
@media (max-width: 991px) {
  .rounded .card.auth-card {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
}
.rounded .invalid-tooltip,
.rounded .valid-tooltip,
.rounded div.error {
  border-radius: 10px;
}
.rounded .data-table-rows table tbody tr {
  border-radius: 0.75rem;
}
.rounded .data-table-rows table td {
  background: white;
}
.rounded .data-table-rows table td:first-child {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rounded .data-table-rows table td:last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

/* 43.Rtl */
.rtl {
  direction: rtl;
}
.rtl .text-center p,
.rtl .text-center div,
.rtl .text-center span,
.rtl .text-center i,
.rtl .text-center a,
.rtl .text-center h1,
.rtl .text-center h2,
.rtl .text-center h3,
.rtl .text-center h4,
.rtl .text-center h5,
.rtl .text-center h6 {
  text-align: center;
}
.rtl .list-unstyled {
  padding-right: 0;
}
.rtl .breadcrumb-item + .breadcrumb-item {
  padding-left: initial;
  padding-right: 0.5rem;
}
.rtl .breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.5rem;
  padding-right: initial;
}
.rtl .glide__arrow.glide__arrow--left .simple-icon-arrow-left:before {
  content: "\e605";
}
.rtl .glide__arrow.glide__arrow--right .simple-icon-arrow-right:before {
  content: "\e606";
}
.rtl .dropdown-menu:not(.datepicker-dropdown) {
  right: initial !important;
}
.rtl .dropdown-menu-right {
  right: initial !important;
  left: 0 !important;
}
.rtl .dropdown-menu-left {
  right: 0 !important;
  left: initial !important;
}
.rtl .btn-group > .btn:first-child {
  margin-left: -1px;
}
.rtl .top-right-button-container {
  float: left;
}
@media (max-width: 575px) {
  .rtl .top-right-button-container {
    float: initial;
  }
}
.rtl .col-left {
  padding-left: 20px;
  padding-right: 15px;
}
@media (max-width: 991px) {
  .rtl .col-left {
    padding-left: 15px;
  }
}
.rtl .col-right {
  padding-right: 20px;
  padding-left: 15px;
}
@media (max-width: 991px) {
  .rtl .col-right {
    padding-right: 15px;
  }
}
.rtl .search-sm::after {
  left: 7px;
  right: initial;
  text-align: initial;
  top: 3px;
}
.rtl .newsletter-input-container input {
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  left: initial;
  right: -1.5rem;
}
.rtl .context-menu-item span {
  margin-left: initial;
  margin-right: 0.5rem;
}
.rtl .dropdown-toggle-split:after,
.rtl .dropright .dropdown-toggle-split:after,
.rtl .dropup .dropdown-toggle-split:after {
  margin-left: 2px;
  margin-right: 2px;
}
.rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0.1rem;
}
.rtl .btn-group.dropleft .dropdown-toggle-split {
  border-radius: 0.1rem;
}
.rtl .input-group-append .btn + .btn,
.rtl .input-group-append .btn + .input-group-text,
.rtl .input-group-append .input-group-text + .btn,
.rtl .input-group-append .input-group-text + .input-group-text,
.rtl .input-group-prepend .btn + .btn,
.rtl .input-group-prepend .btn + .input-group-text,
.rtl .input-group-prepend .input-group-text + .btn,
.rtl .input-group-prepend .input-group-text + .input-group-text {
  margin-left: 0px;
  margin-right: -1px;
}
.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 0.1rem;
}
.rtl .input-group-text {
  border-radius: 0.1rem !important;
}
.rtl .input-group-prepend {
  margin-right: 0;
  margin-left: -1px;
}
.rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text {
  border-radius: 50px;
}
.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 50px;
}
.rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child) {
  border-radius: 0 !important;
}
.rtl .input-group > .input-group-append > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rtl .sw-main.sw-theme-check > ul.step-anchor > li > a:after,
.rtl .sw-main.sw-theme-dots > ul.step-anchor > li > a:after {
  left: 0;
  right: 50%;
  transform: translateX(50%);
}
.rtl .tooltip-label-right .invalid-tooltip::before,
.rtl .tooltip-label-right .valid-tooltip::before {
  left: initial;
  right: -5px;
  border-right: initial;
  border-left: solid 5px #3E3A3E;
}
.rtl .tooltip-label-right .invalid-tooltip::after,
.rtl .tooltip-label-right .valid-tooltip::after {
  right: -4px;
  left: initial;
  border-left: solid 5px white;
  border-right: initial;
}
.rtl .tooltip-label-right .invalid-tooltip,
.rtl .tooltip-label-right .valid-tooltip {
  left: initial;
  right: 50px;
}
.rtl .tooltip-label-right .invalid-tooltip:after,
.rtl .tooltip-label-right .valid-tooltip:after,
.rtl .tooltip-label-right div.error:after {
  left: auto;
  right: -9px;
  border-right: 5px solid transparent;
  border-left: 5px solid white;
}
.rtl .tooltip-label-right .invalid-tooltip:before,
.rtl .tooltip-label-right .valid-tooltip:before,
.rtl .tooltip-label-right div.error:before {
  left: auto;
  right: -10px;
  border-right: 5px solid transparent;
  border-left: 5px solid #3E3A3E;
}
.rtl .tooltip-label-right .invalid-tooltip,
.rtl .tooltip-label-right .valid-tooltip,
.rtl .tooltip-label-right div.error {
  left: initial;
  right: 50px;
}
.rtl .tooltip-label-right .error-l-0 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-0 .valid-tooltip,
.rtl .tooltip-label-right .error-l-0 div.error {
  right: 0px;
  left: initial;
}
.rtl .tooltip-label-right .error-l-25 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-25 .valid-tooltip,
.rtl .tooltip-label-right .error-l-25 div.error {
  left: initial;
  right: 25px;
}
.rtl .tooltip-label-right .error-l-50 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-50 .valid-tooltip,
.rtl .tooltip-label-right .error-l-50 div.error {
  left: initial;
  right: 50px;
}
.rtl .tooltip-label-right .error-l-75 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-75 .valid-tooltip,
.rtl .tooltip-label-right .error-l-75 div.error {
  left: initial;
  right: 75px;
}
.rtl .tooltip-label-right .error-l-100 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-100 .valid-tooltip,
.rtl .tooltip-label-right .error-l-100 div.error {
  left: initial;
  right: 100px;
}
.rtl .tooltip-label-right .error-l-125 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-125 .valid-tooltip,
.rtl .tooltip-label-right .error-l-125 div.error {
  left: initial;
  right: 125px;
}
.rtl .tooltip-label-right .error-l-150 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-150 .valid-tooltip,
.rtl .tooltip-label-right .error-l-150 div.error {
  left: initial;
  right: 150px;
}
.rtl .tooltip-label-right .error-l-175 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-175 .valid-tooltip,
.rtl .tooltip-label-right .error-l-175 div.error {
  left: initial;
  right: 175px;
}
.rtl .tooltip-label-right .error-l-200 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-200 .valid-tooltip,
.rtl .tooltip-label-right .error-l-200 div.error {
  left: initial;
  right: 200px;
}
.rtl .tooltip-label-right .error-l-225 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-225 .valid-tooltip,
.rtl .tooltip-label-right .error-l-225 div.error {
  left: initial;
  right: 225px;
}
.rtl .tooltip-label-right .error-l-250 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-250 .valid-tooltip,
.rtl .tooltip-label-right .error-l-250 div.error {
  left: initial;
  right: 250px;
}
.rtl .tooltip-label-right .error-l-275 .invalid-tooltip,
.rtl .tooltip-label-right .error-l-275 .valid-tooltip,
.rtl .tooltip-label-right .error-l-275 div.error {
  left: initial;
  right: 275px;
}
.rtl .invalid-icon,
.rtl .valid-icon {
  right: initial;
  left: 4px;
}
.rtl .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap {
  padding-right: 0;
  padding-left: 30px;
}
.rtl .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap:before {
  right: initial;
  left: 3em;
}
.rtl .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap:after {
  right: initial;
  left: 2.5em;
}
.rtl .data-table-responsive .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-row-center datatable-body-cell:first-of-type .glyph-icon {
  left: initial;
  right: 12px;
}
.rtl .data-table-responsive .datatable-row-detail .detail-content {
  padding-right: 47px;
}
.rtl .ck-editor {
  direction: rtl;
}
.rtl .glide__arrows.slider-nav {
  direction: ltr;
}
.rtl .glide__bullets.slider-dot-container {
  direction: rtl;
}
.rtl .btn-group > .btn:not(:first-child),
.rtl .btn-group > .btn-group:not(:first-child) > .btn {
  border-radius: initial;
}
.rtl .btn-group > .btn:first-child {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rtl .btn-group > button.btn:last-of-type {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.rtl .btn-group > label.btn:last-of-type {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.rtl .top-right-button-container .check-button label.custom-control {
  margin-left: -0.25rem;
}
.rtl .list .flex-row.card .custom-control {
  padding-left: 2.5rem !important;
}
.rtl .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: initial;
  left: 0;
}
.rtl .datepicker-dropdown {
  right: initial;
}
.rtl .card .card-header .card-icon {
  left: 10px;
  right: initial;
}
.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: initial;
}
.rtl .custom-control.custom-switch {
  padding-right: 2rem;
}
.rtl .custom-control.custom-switch .custom-control-label::after,
.rtl .custom-control.custom-switch .custom-control-label::before {
  right: -2rem;
}
.rtl .custom-control.custom-switch .custom-control-label::after {
  right: -1.15rem;
}
.rtl .pagination .page-link.prev i::before {
  content: "\e606";
}
.rtl .pagination .page-link.next i::before {
  content: "\e605";
}
.rtl .pagination .page-link.first i::before {
  content: "\e074";
}
.rtl .pagination .page-link.last i::before {
  content: "\e06f";
}
.rtl .nav,
.rtl .pagination {
  padding-right: 0;
}
.rtl .separator-tabs .nav-tabs .nav-link {
  margin-left: 1.5rem;
  margin-right: 0;
}
.rtl .heading-number {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .card-top-buttons {
  right: initial;
  left: 0;
}
.rtl .comment-likes {
  text-align: left;
}
.rtl .comment-container input {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  padding-left: inherit;
  padding-right: 20px;
}
.rtl .social-image-row [class*=col-]:nth-child(odd) {
  padding-left: 0.5rem;
  padding-right: 15px;
}
.rtl .social-image-row [class*=col-]:nth-child(even) {
  padding-left: 15px;
  padding-right: 0.5rem;
}
.rtl .app-row {
  padding-right: initial;
  padding-left: 280px;
}
@media (max-width: 1199px) {
  .rtl .app-row {
    padding-left: 0;
  }
}
.rtl .app-menu {
  left: 0;
  right: initial;
}
@media (max-width: 1199px) {
  .rtl .app-menu {
    transform: translateX(-280px);
  }
}
.rtl .app-menu.shown {
  transform: translateX(0);
}
.rtl .app-menu .app-menu-button {
  left: calc(280px - 2px);
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.04);
}
.rtl .float-right {
  float: left !important;
}
.rtl .float-left {
  float: right !important;
}
@media (min-width: 1439px) {
  .rtl .float-xxl-left {
    float: right !important;
  }
  .rtl .float-xxl-right {
    float: left !important;
  }
}
@media (min-width: 1199px) {
  .rtl .float-xl-left {
    float: right !important;
  }
  .rtl .float-xl-right {
    float: left !important;
  }
}
@media (min-width: 991px) {
  .rtl .float-lg-left {
    float: right !important;
  }
  .rtl .float-lg-right {
    float: left !important;
  }
}
@media (min-width: 767px) {
  .rtl .float-md-left {
    float: right !important;
  }
  .rtl .float-md-right {
    float: left !important;
  }
}
@media (min-width: 575px) {
  .rtl .float-sm-left {
    float: right !important;
  }
  .rtl .float-sm-right {
    float: left !important;
  }
}
@media (min-width: 419px) {
  .rtl .float-xs-left {
    float: right !important;
  }
  .rtl .float-xs-right {
    float: left !important;
  }
}
.rtl .badge.badge-top-left {
  left: initial;
  right: -7px;
}
.rtl .badge.badge-top-left-2 {
  left: initial;
  right: -7px;
}
.rtl .badge.badge-top-right {
  left: -7px;
  right: initial;
}
.rtl .badge.badge-top-right-2 {
  left: -7px;
  right: initial;
}
.rtl .r-0 {
  left: 0;
  right: initial;
}
.rtl .form-check {
  padding-left: 0;
  padding-right: 1.25rem;
}
.rtl .has-float-label label,
.rtl .has-float-label > span:last-of-type {
  right: 0.75rem;
  left: initial;
}
.rtl .has-top-label label,
.rtl .has-top-label > span:last-of-type {
  right: 0.75rem;
  left: initial;
}
.rtl .has-top-label label,
.rtl .has-top-label > span,
.rtl .has-float-label label,
.rtl .has-float-label > span {
  color: rgba(255, 250, 250, 0.7);
}
.rtl .nav-pills .nav-link {
  text-align: center;
}
.rtl .custom-switch .custom-switch-input + .custom-switch-btn:after {
  left: 0;
}
.rtl .custom-switch .custom-switch-input:checked + .custom-switch-btn:after {
  left: -28px;
}
.rtl .custom-switch-small .custom-switch-input:checked + .custom-switch-btn:after {
  left: -18px;
}
.rtl .bootstrap-tagsinput .tag {
  padding-right: 10px;
  padding-left: 20px;
}
.rtl .bootstrap-tagsinput .tag span {
  margin-left: 0px;
  position: relative;
}
.rtl .bootstrap-tagsinput .tag span:after {
  position: absolute;
  top: -2px;
  left: -15px;
}
.rtl .text-right {
  text-align: left !important;
}
.rtl .text-left {
  text-align: right !important;
}
.rtl .ps__rail-y {
  right: initial !important;
  left: 0 !important;
}
.rtl perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
  right: initial !important;
  left: 0 !important;
}
.rtl perfect-scrollbar > .ps {
  margin-left: -15px;
  padding-left: 15px;
  margin-right: -10px;
  padding-right: 10px;
}
.rtl .scroll {
  margin-left: -15px;
  padding-left: 15px;
  margin-right: -10px;
  padding-right: 10px;
}
.rtl .menu .scroll {
  margin-left: initial;
  padding-left: initial;
  margin-right: initial;
  padding-right: initial;
}
.rtl .modal .modal-header,
.rtl .modal .modal-body,
.rtl .modal .modal-footer {
  padding: 1.75rem;
}
@media (max-width: 575px) {
  .rtl .modal .modal-header,
.rtl .modal .modal-body,
.rtl .modal .modal-footer {
    padding: 1.5rem;
  }
}
.rtl .select-from-library .modal-body.scroll {
  margin-left: 0;
}
.rtl .select-from-library .modal-body.scroll .ps__rail-y {
  left: 10px !important;
}
.rtl .select-from-library .sfl-item-container .card-body {
  padding-right: 1.75rem !important;
}
@media (max-width: 575px) {
  .rtl .select-from-library .sfl-item-container .card-body {
    padding: 1.5rem !important;
  }
}
.rtl .select-from-library .sfl-item-container .custom-control {
  padding-left: 0.25rem !important;
}
.rtl .menu .sub-menu .scroll .ps__thumb-y,
.rtl .menu .main-menu .scroll .ps__thumb-y {
  right: -4px;
}
.rtl .dropzone .dz-preview.dz-image-preview .dz-progress {
  right: 50%;
  left: initial;
  transform: translateX(50%);
}
.rtl .dropzone .dz-preview.dz-file-preview .remove,
.rtl .dropzone .dz-preview.dz-image-preview .remove {
  right: initial;
  left: 5px;
}
.rtl .chart {
  direction: ltr;
}
.rtl .chart canvas {
  direction: rtl;
}
.rtl .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -10px;
}
.rtl .icon-button {
  text-align: center;
}
.rtl aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):before {
  right: calc(50% + 7px);
  left: initial;
}
.rtl aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):before {
  left: initial;
  right: calc(50% + 20px);
}
.rtl .menu {
  right: 0;
  left: initial;
}
.rtl .menu .main-menu {
  left: initial;
  right: 0;
}
.rtl .menu .main-menu ul li {
  position: relative;
}
.rtl .menu .main-menu ul li.active:after {
  content: " ";
  left: initial;
  right: 0;
}
.rtl .menu .sub-menu {
  left: initial;
  right: 115px;
  border-right: 1px solid #f3f3f3;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}
.rtl .menu .sub-menu ul li {
  margin-left: initial;
  margin-right: 30px;
}
.rtl .menu .sub-menu ul li i {
  margin-right: initial;
  margin-left: 10px;
}
.rtl .menu .sub-menu ul li.active:after {
  left: initial;
  right: -16px;
}
.rtl .menu .sub-menu ul.inner-level-menu li.active:after {
  left: initial;
  right: -22px;
}
@media (max-width: 1439px) {
  .rtl .menu .sub-menu {
    left: initial;
    right: 115px;
  }
}
@media (max-width: 1199px) {
  .rtl .menu .sub-menu {
    left: initial;
    right: 115px;
  }
}
@media (max-width: 767px) {
  .rtl .menu .sub-menu {
    left: initial;
    right: 105px;
  }
}
.rtl main {
  margin-right: 402px;
  margin-left: 15px !important;
  overflow: initial !important;
}
@media (max-width: 1439px) {
  .rtl main {
    margin-right: 360px;
    margin-left: 15px !important;
  }
}
@media (max-width: 1199px) {
  .rtl main {
    margin-right: 360px;
    margin-left: 15px !important;
  }
}
@media (max-width: 767px) {
  .rtl main {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
}
@media (max-width: 575px) {
  .rtl main {
    margin-bottom: 0;
  }
}
.rtl.default-transition main {
  transition: margin-right 300ms;
}
.rtl .navbar .navbar-right {
  text-align: left;
}
.rtl .navbar .menu-button-mobile {
  margin-right: 15px;
  margin-left: initial;
}
.rtl .navbar .menu-button {
  text-align: center;
}
.rtl .navbar .icon-menu-item {
  text-align: center;
}
.rtl .navbar .icon-menu-item i {
  text-align: center;
}
.rtl .navbar .icon-menu-item span {
  text-align: center;
}
.rtl .navbar .search .search-icon {
  right: initial;
  left: 5px;
}
@media (max-width: 767px) {
  .rtl .navbar .search {
    margin-right: 0.6rem;
    margin-left: initial;
  }
  .rtl .navbar .search input {
    display: none;
  }
  .rtl .navbar .search.mobile-view {
    margin-left: initial;
    margin-right: 15px;
  }
  .rtl .navbar .search.mobile-view input {
    display: block;
    width: 100%;
    height: 70px;
    padding-left: 0;
  }
  .rtl .navbar .search.mobile-view span {
    left: 15px;
    right: initial;
  }
}
.rtl .navbar .navbar-right {
  text-align: left;
}
.rtl .navbar .navbar-right .user {
  margin-left: 15px;
  margin-right: 0;
}
@media (max-width: 1439px) {
  .rtl .navbar .navbar-right .user {
    margin-left: 15px;
    margin-right: 0;
  }
}
@media (max-width: 1199px) {
  .rtl .navbar .navbar-right .user {
    margin-left: 15px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .rtl .navbar .navbar-right .user {
    margin-left: 15px;
    margin-right: 0;
  }
}
.rtl .navbar .navbar-right .user img {
  margin-right: 10px;
  margin-left: initial;
}
.rtl .navbar .navbar-right .header-icons {
  margin-right: initial;
  margin-left: 0.5rem;
}
@media (max-width: 575px) {
  .rtl .navbar .navbar-right .header-icons {
    margin-left: 0;
  }
}
.rtl .navbar .navbar-right #notificationButton .count {
  text-align: center;
}
@media (max-width: 575px) {
  .rtl .navbar .navbar-right #notificationButton .count {
    left: -1px;
  }
}
.rtl .navbar .navbar-right #userDropdown,
.rtl .navbar .navbar-right #userDropdown * {
  text-align: left;
}
.rtl .heading-icon {
  margin-right: initial;
  margin-left: 5px;
}
.rtl .survey-app .answers input {
  padding-right: 0.75rem;
  padding-left: 70px;
}
.rtl .input-icons {
  left: 0;
  right: initial;
}
.rtl .simple-icon-arrow-left:before {
  content: "\e606";
}
.rtl .simple-icon-arrow-right:before {
  content: "\e605";
}
.rtl .sub-hidden .sub-menu,
.rtl .menu-sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
  transform: translateX(272px);
}
@media (max-width: 1439px) {
  .rtl .sub-hidden .sub-menu,
.rtl .menu-sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
    transform: translateX(230px);
  }
}
@media (max-width: 1199px) {
  .rtl .sub-hidden .sub-menu,
.rtl .menu-sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
    transform: translateX(230px);
  }
}
@media (max-width: 767px) {
  .rtl .sub-hidden .sub-menu,
.rtl .menu-sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
    transform: translateX(230px);
  }
}
.rtl .main-hidden .main-menu,
.rtl .menu-hidden .main-menu {
  transform: translateX(115px);
}
@media (max-width: 1439px) {
  .rtl .main-hidden .main-menu,
.rtl .menu-hidden .main-menu {
    transform: translateX(115px);
  }
}
@media (max-width: 1199px) {
  .rtl .main-hidden .main-menu,
.rtl .menu-hidden .main-menu {
    transform: translateX(115px);
  }
}
@media (max-width: 767px) {
  .rtl .main-hidden .main-menu,
.rtl .menu-hidden .main-menu {
    transform: translateX(105px);
  }
}
.rtl .main-hidden.sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
  transform: translateX(387px);
}
@media (max-width: 1439px) {
  .rtl .main-hidden.sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
    transform: translateX(345px);
  }
}
@media (max-width: 1199px) {
  .rtl .main-hidden.sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
    transform: translateX(345px);
  }
}
@media (max-width: 767px) {
  .rtl .main-hidden.sub-hidden .sub-menu,
.rtl .menu-hidden .sub-menu {
    transform: translateX(335px);
  }
}
.rtl .menu-main-hidden .main-menu {
  width: 0;
}
.rtl .menu-main-hidden .sub-menu {
  right: 0;
}
.rtl .menu-mobile .main-menu {
  transform: translateX(105px);
}
.rtl .menu-mobile .sub-menu {
  transform: translateX(385px);
}
.rtl .main-show-temporary .main-menu {
  transform: translateX(0);
}
.rtl .main-show-temporary .sub-menu {
  transform: translateX(272px);
}
@media (max-width: 1439px) {
  .rtl .main-show-temporary .main-menu {
    transform: translateX(0);
  }
  .rtl .main-show-temporary .sub-menu {
    transform: translateX(230px);
  }
}
@media (max-width: 1199px) {
  .rtl .main-show-temporary .main-menu {
    transform: translateX(0);
  }
  .rtl .main-show-temporary .sub-menu {
    transform: translateX(230px);
  }
}
@media (max-width: 767px) {
  .rtl .main-show-temporary .sub-menu {
    transform: translateX(230px);
  }
}
.rtl .sub-show-temporary .sub-menu,
.rtl .menu-mobile.sub-show-temporary .sub-menu,
.rtl .menu-main-hidden.menu-mobile.main-show-temporary .sub-menu {
  transform: translateX(0);
}
.rtl .chat-input-container {
  padding-right: 402px;
  padding-left: 295px;
}
@media (max-width: 1439px) {
  .rtl .chat-input-container {
    padding-right: 360px;
    padding-left: 295px;
  }
}
@media (max-width: 1199px) {
  .rtl .chat-input-container {
    padding-right: 360px;
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .rtl .chat-input-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
  padding-right: 130px;
}
@media (max-width: 1439px) {
  .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
    padding-right: 130px;
  }
}
@media (max-width: 1199px) {
  .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
    padding-right: 130px;
  }
}
@media (max-width: 767px) {
  .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
  padding-right: 15px;
}
@media (max-width: 1439px) {
  .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
    padding-right: 15px;
  }
}
@media (max-width: 1199px) {
  .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.rtl .sub-hidden main,
.rtl .menu-sub-hidden main,
.rtl .menu-hidden main {
  margin-right: 130px;
}
.rtl .main-hidden main,
.rtl .menu-hidden main {
  margin-right: 15px;
}
.rtl .menu-main-hidden main {
  margin-right: 287px;
}
.rtl .menu-main-hidden.menu-hidden main {
  margin-right: 15px;
}
@media (max-width: 1439px) {
  .rtl .sub-hidden main,
.rtl .menu-sub-hidden main,
.rtl .menu-hidden main {
    margin-right: 130px;
  }
  .rtl .main-hidden main,
.rtl .menu-hidden main {
    margin-right: 15px;
  }
  .rtl .menu-main-hidden main {
    margin-right: 245px;
  }
  .rtl .menu-main-hidden.menu-hidden main {
    margin-right: 15px;
  }
}
@media (max-width: 1199px) {
  .rtl .sub-hidden main,
.rtl .menu-sub-hidden main,
.rtl .menu-hidden main {
    margin-right: 130px;
  }
  .rtl .main-hidden main,
.rtl .menu-hidden main {
    margin-right: 15px;
  }
  .rtl .menu-main-hidden main {
    margin-right: 245px;
  }
  .rtl .menu-main-hidden.menu-hidden main {
    margin-right: 15px;
  }
}
.rtl .theme-colors {
  transform: translate(-280px, -50%);
  left: 0;
  right: initial;
}
.rtl .theme-colors .theme-button {
  left: 278px;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.04);
}
.rtl .theme-colors.shown {
  transform: translate(0, -50%);
}
.rtl.rounded .app-menu {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.rtl.rounded .sub-menu {
  border-radius: 0.75rem 0 0 0.75rem;
}
.rtl.rounded .auth-card .image-side {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rtl.rounded .card-img,
.rtl.rounded .card-img-fluid,
.rtl.rounded .card-img-overlay {
  border-radius: 0.75rem;
}
.rtl.rounded .sub-hidden .main-menu,
.rtl.rounded .menu-sub-hidden .main-menu {
  border-radius: 0.75rem 0 0 0.75rem;
}
.rtl.rounded .sub-show-temporary .main-menu {
  border-radius: initial;
}
.rtl.rounded .card-img-left {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important;
}
.rtl.rounded .card-img-right {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
  border-top-right-radius: initial !important;
  border-bottom-right-radius: initial !important;
}
.rtl.rounded .card.auth-card {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}
.rtl.rounded .auth-card .image-side {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.rtl.rounded .data-table-rows table td {
  border-radius: initial;
}
.rtl.rounded .data-table-rows table td:first-child {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.rtl.rounded .data-table-rows table td:last-child {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

body {
  min-height: calc(100% - 150px);
  position: relative;
  padding-bottom: 90px;
}
@media (max-width: 1439px) {
  body {
    min-height: calc(100% - 130px);
  }
}
@media (max-width: 1199px) {
  body {
    min-height: calc(100% - 110px);
  }
}
@media (max-width: 767px) {
  body {
    min-height: calc(100% - 85px);
  }
}
@media (max-width: 575px) {
  body {
    padding-bottom: 60px;
  }
}
body.no-footer {
  padding-bottom: initial;
}
body.no-footer footer {
  display: none;
}

footer.page-footer {
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
  border-top: 1px solid #d7d7d7;
  width: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
}
@media (max-width: 575px) {
  footer.page-footer {
    height: 60px;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}
footer.page-footer .breadcrumb {
  padding-left: 0;
  padding-right: 0;
}
footer.page-footer .breadcrumb-item + .breadcrumb-item::before {
  color: #3E3A3E;
}
footer.page-footer .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
footer.page-footer .footer-content {
  margin-left: 402px;
  margin-right: 15px;
}
@media (max-width: 1439px) {
  footer.page-footer .footer-content {
    margin-left: 360px;
    margin-right: 15px;
  }
}
@media (max-width: 1199px) {
  footer.page-footer .footer-content {
    margin-left: 360px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  footer.page-footer .footer-content {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

.right-menu footer.page-footer {
  padding-right: 280px;
}
@media (max-width: 1199px) {
  .right-menu footer.page-footer {
    padding-right: 0;
  }
}

#app-container.sub-hidden .footer-content, #app-container.menu-sub-hidden .footer-content, #app-container.menu-hidden .footer-content {
  margin-left: 130px;
}
#app-container.main-hidden .footer-content, #app-container.menu-hidden .footer-content {
  margin-left: 15px;
}
#app-container.menu-main-hidden .footer-content {
  margin-left: 287px;
}
#app-container.menu-main-hidden.menu-hidden .footer-content {
  margin-left: 15px;
}
@media (max-width: 1439px) {
  #app-container.sub-hidden .footer-content, #app-container.menu-sub-hidden .footer-content, #app-container.menu-hidden .footer-content {
    margin-left: 130px;
  }
  #app-container.main-hidden .footer-content, #app-container.menu-hidden .footer-content {
    margin-left: 15px;
  }
  #app-container.menu-main-hidden .footer-content {
    margin-left: 245px;
  }
  #app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-left: 15px;
  }
}
@media (max-width: 1199px) {
  #app-container.sub-hidden .footer-content, #app-container.menu-sub-hidden .footer-content, #app-container.menu-hidden .footer-content {
    margin-left: 130px;
  }
  #app-container.main-hidden .footer-content, #app-container.menu-hidden .footer-content {
    margin-left: 15px;
  }
  #app-container.menu-main-hidden .footer-content {
    margin-left: 245px;
  }
  #app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-left: 15px;
  }
}

.menu .sub-menu ul li.has-sub-item.active:after {
  content: " ";
  background: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.chat-app-tab-pane-contacts {
  height: calc(100vh - 220px);
}

.chat-app-tab-pane-messages {
  height: calc(100vh - 170px);
}

.rtl footer.page-footer .footer-content {
  margin-right: 402px;
  margin-left: 15px;
}
@media (max-width: 1439px) {
  .rtl footer.page-footer .footer-content {
    margin-right: 360px;
    margin-left: 15px;
  }
}
@media (max-width: 1199px) {
  .rtl footer.page-footer .footer-content {
    margin-right: 360px;
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .rtl footer.page-footer .footer-content {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
}
.rtl #app-container.sub-hidden .footer-content, .rtl #app-container.menu-sub-hidden .footer-content, .rtl #app-container.menu-hidden .footer-content {
  margin-right: 130px;
  margin-left: 15px;
}
.rtl #app-container.main-hidden .footer-content, .rtl #app-container.menu-hidden .footer-content {
  margin-right: 15px;
  margin-left: 15px;
}
.rtl #app-container.menu-main-hidden .footer-content {
  margin-right: 287px;
  margin-left: 15px;
}
.rtl #app-container.menu-main-hidden.menu-hidden .footer-content {
  margin-right: 15px;
  margin-left: 15px;
}
@media (max-width: 1439px) {
  .rtl #app-container.sub-hidden .footer-content, .rtl #app-container.menu-sub-hidden .footer-content, .rtl #app-container.menu-hidden .footer-content {
    margin-right: 130px;
    margin-left: 15px;
  }
  .rtl #app-container.main-hidden .footer-content, .rtl #app-container.menu-hidden .footer-content {
    margin-right: 15px;
    margin-left: 15px;
  }
  .rtl #app-container.menu-main-hidden .footer-content {
    margin-right: 245px;
    margin-left: 15px;
  }
  .rtl #app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-right: 15px;
    margin-left: 15px;
  }
}
@media (max-width: 1199px) {
  .rtl #app-container.sub-hidden .footer-content, .rtl #app-container.menu-sub-hidden .footer-content, .rtl #app-container.menu-hidden .footer-content {
    margin-right: 130px;
    margin-left: 15px;
  }
  .rtl #app-container.main-hidden .footer-content, .rtl #app-container.menu-hidden .footer-content {
    margin-right: 15px;
    margin-left: 15px;
  }
  .rtl #app-container.menu-main-hidden .footer-content {
    margin-right: 245px;
    margin-left: 15px;
  }
  .rtl #app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-right: 15px;
    margin-left: 15px;
  }
}
.rtl.right-menu footer.page-footer {
  padding-left: 280px;
  padding-right: initial;
}
@media (max-width: 1199px) {
  .rtl.right-menu footer.page-footer {
    padding-left: 0;
  }
}

.main-menu .ps {
  margin-left: initial;
  margin-right: initial;
  padding-left: initial;
  padding-right: initial;
}

/* 38.Landing Page */
.landing-page {
  font-size: 1rem;
  line-height: 1.6rem;
  color: #3E3A3E;
}
@media (max-width: 767px) {
  .landing-page {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}
.landing-page .mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(300px);
  z-index: 25;
  width: 300px;
  background: #f8f8f8;
  box-shadow: initial;
  transition: transform 0.5s, box-shadow 0.5s;
}
.landing-page .logo-mobile {
  display: block;
  text-align: center;
  padding: 3rem 2rem;
  background: none;
  width: unset;
  height: unset;
}
.landing-page .logo-mobile span {
  display: inline-block;
  width: 276px;
  height: 62px;
  background: url("../../logos/logo-mpl.jpg");
  background-repeat: no-repeat;
}
.landing-page .main-container {
  overflow: hidden;
}
.landing-page.show-mobile-menu .mobile-menu {
  transform: translateX(0);
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}
.landing-page.show-mobile-menu .main-container .content-container,
.landing-page.show-mobile-menu .main-container nav {
  transform: translateX(-300px);
}
.landing-page .content-container {
  transition: transform 0.5s;
}
.landing-page p {
  font-size: 1rem;
  line-height: 1.6rem;
  color: #3E3A3E;
}
@media (max-width: 767px) {
  .landing-page p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}
.landing-page h1 {
  font-size: 3rem;
  color: #3E3A3E;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .landing-page h1 {
    font-size: 2rem;
  }
}
.landing-page h2 {
  font-size: 1.8rem;
  color: #3E3A3E;
  margin-bottom: 2rem;
}
@media (max-width: 991px) {
  .landing-page h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  .landing-page h2 {
    font-size: 1.2rem;
  }
}
.landing-page h3 {
  font-size: 1.4rem;
  line-height: 1.4;
  color: #3E3A3E;
}
@media (max-width: 767px) {
  .landing-page h3 {
    font-size: 1.1rem;
  }
}
.landing-page .semi-rounded {
  border-radius: 1rem !important;
}
.landing-page .dropdown-toggle::after {
  opacity: 0.7;
}
.landing-page .btn-outline-semi-light {
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  background: transparent;
}
.landing-page .btn-outline-semi-light:hover, .landing-page .btn-outline-semi-light:active {
  border: 1px solid #242124;
  background: #242124;
}
.landing-page .landing-page-nav {
  z-index: 5 !important;
  top: 0;
  position: fixed;
  width: 100%;
  background: transparent;
  box-shadow: initial;
}
.landing-page .landing-page-nav nav {
  transition: transform 0.5s;
}
.landing-page .landing-page-nav .headroom {
  transition: top 0.5s, background 0.5s, box-shadow 0.2s, transform 0.5s !important;
}
.landing-page .landing-page-nav .container {
  height: 100px;
}
@media (max-width: 1439px) {
  .landing-page .landing-page-nav .container {
    height: 90px;
  }
}
@media (max-width: 1199px) {
  .landing-page .landing-page-nav .container {
    height: 80px;
  }
  .landing-page .landing-page-nav .container .navbar-logo {
    width: 276px;
    height: 62px;
  }
  .landing-page .landing-page-nav .container .navbar-logo .white,
.landing-page .landing-page-nav .container .navbar-logo .dark {
    width: 276px;
    height: 62px;
  }
}
@media (max-width: 767px) {
  .landing-page .landing-page-nav .container {
    height: 70px;
  }
}
.landing-page .landing-page-nav .btn {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}
.landing-page .landing-page-nav .navbar-logo {
  width: 200px;
  height: 60px;
}
.landing-page .landing-page-nav .navbar-logo .white,
.landing-page .landing-page-nav .navbar-logo .dark {
  width: 200px;
  height: 60px;
}
.landing-page .landing-page-nav .navbar-logo .white {
  display: inline-block;
  background: url("../../logos/logo-mpl.jpg");
  background-repeat: no-repeat;
}
.landing-page .landing-page-nav .navbar-logo .dark {
  background: url("../../logos/logo-mpl.jpg");
  background-repeat: no-repeat;
  display: none;
}
.landing-page .landing-page-nav .mobile-menu-button {
  font-size: 20px;
  display: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  .landing-page .landing-page-nav .mobile-menu-button {
    display: block;
  }
}
.landing-page .landing-page-nav .navbar-nav {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.landing-page .landing-page-nav .navbar-nav li {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  margin-bottom: initial;
}
.landing-page .landing-page-nav .navbar-nav li:last-of-type {
  padding-right: 0;
}
.landing-page .landing-page-nav .navbar-nav li.active::before {
  content: " ";
  background: #fff;
  border-radius: 10px;
  position: absolute;
  width: calc(100% - 1.5rem);
  height: 4px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
}
@media (max-width: 1199px) {
  .landing-page .landing-page-nav .navbar-nav li {
    padding-left: 0.85rem;
    padding-right: 0.85rem;
  }
  .landing-page .landing-page-nav .navbar-nav li:last-of-type {
    padding-right: 0;
  }
}
.landing-page .landing-page-nav .navbar-nav .nav-item > a,
.landing-page .landing-page-nav .navbar-nav .nav-item > .dropdown > a {
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  display: inline-block;
  margin-top: 0;
}
.landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) > a:hover:not(.btn), .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) > a:active:not(.btn),
.landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) .dropdown > a:hover:not(.btn),
.landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) .dropdown > a:active:not(.btn) {
  opacity: 0.8;
}
.landing-page .landing-page-nav .navbar-nav .dropdown {
  height: 80px;
  position: relative;
}
.landing-page .landing-page-nav .navbar-nav .dropdown:hover > .dropdown-menu {
  display: block;
}
.landing-page .landing-page-nav .navbar-nav .dropdown > .dropdown-toggle:active {
  pointer-events: none;
}
.landing-page .landing-page-nav .navbar-nav .dropdown .dropdown-menu {
  position: absolute;
  margin-top: 0;
}
.landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item {
  font-size: 0.8rem;
  color: snow;
  padding-bottom: 0.5rem;
}
.landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item.active, .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item:active {
  color: #fff;
}
.landing-page .landing-page-nav .headroom--pinned {
  background: white;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.landing-page .landing-page-nav .headroom--pinned .navbar-logo .white {
  display: none;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-logo .dark {
  display: inline-block;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-nav a:not(.btn-primary) {
  color: snow;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-nav a:not(.btn-primary):hover:not(.btn-outline-semi-light), .landing-page .landing-page-nav .headroom--pinned .navbar-nav a:not(.btn-primary):active:not(.btn-outline-semi-light) {
  color: #3E3A3E;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-nav a.dropdown-item:focus {
  color: #fff;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-nav li.active::before {
  content: " ";
  background-color: #3E3A3E;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-nav .btn-outline-semi-light {
  border: 1px solid rgba(62, 58, 62, 0.3);
  color: #3E3A3E;
}
.landing-page .landing-page-nav .headroom--pinned .navbar-nav .btn-outline-semi-light:hover, .landing-page .landing-page-nav .headroom--pinned .navbar-nav .btn-outline-semi-light:active {
  border: 1px solid #3E3A3E;
  background: #3E3A3E;
  color: #fff;
}
.landing-page .landing-page-nav .headroom--pinned .headroom--unpinned {
  background: transparent;
}
.landing-page .landing-page-nav .headroom--unfixed .mobile-menu-button {
  color: #fff;
}
.landing-page .section {
  margin-bottom: 150px;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .landing-page .section {
    margin-bottom: 100px;
    padding-top: 60px;
  }
}
.landing-page .section.home {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1440'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='862.49' y1='-420.67' x2='1546.24' y2='981.21' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23AD7140'/%3E%3Cstop offset='0.27' stop-color='%23AD7140'/%3E%3Cstop offset='0.63' stop-color='%239A1915'/%3E%3Cstop offset='0.88' stop-color='%239A1915'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-home%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M0,0V952.71l497.08,428.77c44.64,38.51,122.25,50,172.46,25.57L2600,467.26V0Z'/%3E%3Cpath style='fill:%239A1915;opacity:0.3;' d='M624.43,1418.11c-44.33,4.25-94.67-9.44-127.35-37.63L0,952.48v50.82l452.08,389.18C496.69,1431,574.21,1442.48,624.43,1418.11Z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-y: 0;
  background-position-x: center;
  background-color: transparent;
  height: 1440px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  background-size: cover;
}
@media (max-width: 575px) {
  .landing-page .section.home {
    height: 1340px;
  }
}
.landing-page .section.subpage {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1040'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='929.23' y1='-453.21' x2='1676' y2='1077.89' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23AD7140'/%3E%3Cstop offset='0.27' stop-color='%23AD7140'/%3E%3Cstop offset='0.63' stop-color='%239A1915'/%3E%3Cstop offset='0.88' stop-color='%239A1915'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-sub%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M170.66,739.24,522.89,996.51c31.63,23.1,89.75,35.48,129.15,27.5L2166.74,717.1,2600,627.22V0H0V614Z'/%3E%3Cpath style='fill:%239A1915;opacity:0.3;' d='M442.89,993.51c31.63,23.1,89.75,35.48,129.15,27.5l4.62-.94c-20.5-5.19-39.74-13.32-53.77-23.56L170.66,739.24,0,614v55.73l90.66,66.55Z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 2700px;
  background-position-y: -300px;
  background-position-x: center;
  background-color: transparent;
  min-height: 950px;
  /*height: 670px;
  @include respond-below(lg) {
    background-position-y: -450px;
    height: 590px;
  }

  @include respond-below(md) {
    background-position-y: -470px;
    height: 570px;
  }

  @include respond-below(sm) {
    background-position-y: -530px;
    height: 510px;
  }*/
}
.landing-page .section.subpage .btn-circle.hero-circle-button {
  bottom: initial;
}
.landing-page .section.subpage-long {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1040'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='929.23' y1='-453.21' x2='1676' y2='1077.89' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23AD7140'/%3E%3Cstop offset='0.27' stop-color='%23AD7140'/%3E%3Cstop offset='0.63' stop-color='%239A1915'/%3E%3Cstop offset='0.88' stop-color='%239A1915'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-sub%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M170.66,739.24,522.89,996.51c31.63,23.1,89.75,35.48,129.15,27.5L2166.74,717.1,2600,627.22V0H0V614Z'/%3E%3Cpath style='fill:%239A1915;opacity:0.3;' d='M442.89,993.51c31.63,23.1,89.75,35.48,129.15,27.5l4.62-.94c-20.5-5.19-39.74-13.32-53.77-23.56L170.66,739.24,0,614v55.73l90.66,66.55Z'/%3E%3C/g%3E%3C/svg%3E");
  height: 1040px;
  background-repeat: no-repeat;
  background-position-y: -200px;
}
.landing-page .section.footer {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1100'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='-153' y1='642.88' x2='3129.11' y2='642.88' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23AD7140'/%3E%3Cstop offset='0.27' stop-color='%23AD7140'/%3E%3Cstop offset='0.63' stop-color='%239A1915'/%3E%3Cstop offset='0.88' stop-color='%239A1915'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' y1='264.94' x2='3129.11' y2='264.94' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Cg%3E%3Cpath style='fill:%239A1915;opacity:0.3;' d='M2019.51,188.57c17.85,4.08,35.64,13,52.49,29.43l528,635.74V787.56L2127,218C2092.59,184.43,2054.24,182.23,2019.51,188.57Z'/%3E%3Cpath style='fill:url(%23linear-gradient);' d='M1945,193,0,714v386H2600V853.33L2072,218C2031,178,1984.41,182.53,1945,193Z'/%3E%3Cpath style='fill:url(%23linear-gradient-2);' d='M2308.31,91c-60.24,17.09-77.23,69.94-37.76,117.43l210.94,253.81c26.74,32.19,72.83,54,118.51,59.37V8.25Z'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  padding-bottom: 0;
  background-size: cover;
  background-size: auto 1100px;
  padding-top: 340px;
  background-position-y: 150px;
  background-position-x: center;
  margin-top: -150px;
}
@media (max-width: 991px) {
  .landing-page .section.footer {
    padding-top: 380px;
  }
}
@media (max-width: 575px) {
  .landing-page .section.footer {
    padding-top: 380px;
  }
}
.landing-page .section.background {
  background: white;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .landing-page .section.background {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.landing-page .section.background::before {
  content: " ";
  width: 100%;
  height: 70px;
  position: absolute;
  top: -70px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 70'%3E%3Cg%3E%3Cpath style='fill:%23FFFFFF' d='M2600,70v-.71C2254.44,26.19,1799.27,0,1300.5,0,801.25,0,345.68,26.24,0,69.42V70Z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
}
.landing-page .section.background::after {
  content: " ";
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: -70px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 70'%3E%3Cg%3E%3Cpath style='fill:%23FFFFFF' d='M0,0V.71C345.56,43.81,800.73,70,1299.5,70,1798.75,70,2254.32,43.76,2600,.58V0Z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
}
.landing-page .section.background.background-no-bottom::after {
  content: "";
  background: initial;
  width: initial;
  height: initial;
}
.landing-page .background-white {
  background: #fff;
}
.landing-page .mobile-hero {
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 135%;
  margin-bottom: 3rem;
}
.landing-page .home-row {
  padding-top: 180px;
  margin-bottom: 70px;
}
@media (max-width: 1439px) {
  .landing-page .home-row {
    padding-top: 120px;
  }
}
@media (max-width: 991px) {
  .landing-page .home-row {
    padding-top: 110px;
  }
}
.landing-page .home-text {
  margin-top: 40px;
}
.landing-page .home-text p {
  color: #fff;
  font-size: 1.1rem;
}
@media (max-width: 767px) {
  .landing-page .home-text p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}
.landing-page .home-text .display-1 {
  font-size: 3rem;
  line-height: 3.2rem;
  margin-bottom: 1.1em;
  color: #fff;
}
@media (max-width: 991px) {
  .landing-page .home-text .display-1 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 767px) {
  .landing-page .home-text .display-1 {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }
}
@media (max-width: 575px) {
  .landing-page .home-text .display-1 {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
@media (max-width: 767px) {
  .landing-page .home-text {
    margin-top: 0;
  }
}
.landing-page .review-carousel {
  margin-top: 5rem;
}
.landing-page .review-carousel .owl-stage-outer {
  padding-top: 5px;
}
.landing-page .home-carousel {
  cursor: grab;
}
.landing-page .home-carousel ::-moz-selection {
  /* Code for Firefox */
  color: inherit;
  background: inherit;
}
.landing-page .home-carousel ::selection {
  color: inherit;
  background: inherit;
}
.landing-page .home-carousel .card .detail-text {
  padding: 1rem 0.25rem;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .landing-page .home-carousel .card .detail-text {
    padding: 0.25rem;
  }
}
@media (max-width: 767px) {
  .landing-page .home-carousel .card .btn-link {
    padding: 0.25rem;
  }
}
.landing-page i.large-icon {
  font-size: 60px;
  line-height: 110px;
  color: #3E3A3E;
  display: initial;
}
@media (max-width: 767px) {
  .landing-page i.large-icon {
    line-height: 90px;
  }
}
.landing-page .btn-circle {
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
}
.landing-page .btn-circle.hero-circle-button {
  position: absolute;
  bottom: 80px;
  margin-left: 10px;
}
.landing-page .btn-circle.hero-circle-button i {
  padding-top: 18px;
  display: inline-block;
}
.landing-page .newsletter-input-container {
  margin-top: 5rem;
}
.landing-page .newsletter-input-container .input-group {
  border-radius: 50px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}
.landing-page .newsletter-input-container input {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-color: transparent !important;
  padding: 0.75rem 1.75rem 0.75rem;
  font-size: 0.9rem;
}
@media (max-width: 767px) {
  .landing-page .newsletter-input-container button {
    padding: 1rem 1.5rem 0.9rem;
  }
}
.landing-page .footer .footer-content {
  padding-top: 210px;
}
@media (max-width: 767px) {
  .landing-page .footer .footer-content {
    padding-top: 140px;
  }
}
@media (max-width: 575px) {
  .landing-page .footer .footer-content {
    padding-top: 80px;
  }
}
.landing-page .footer .footer-circle-button {
  margin-top: 80px;
  margin-right: 20px;
}
@media (max-width: 991px) {
  .landing-page .footer .footer-circle-button {
    margin-top: 110px;
  }
}
.landing-page .footer .footer-circle-button i {
  padding-top: 15px;
  display: inline-block;
}
.landing-page .footer .footer-logo {
  width: 140px;
  margin-bottom: 70px;
}
.landing-page .footer .footer-menu p {
  color: #fff;
  font-size: 1rem;
}
.landing-page .footer .footer-menu a {
  font-size: 1rem;
  color: #fff;
}
.landing-page .footer .footer-menu a:hover, .landing-page .footer .footer-menu a:focus {
  color: #090909;
}
.landing-page .footer .footer-menu .collapse-button i {
  font-size: 0.75rem;
  margin-left: 5px;
}
.landing-page .footer .footer-menu .collapse-button:hover, .landing-page .footer .footer-menu .collapse-button:focus {
  color: #fff;
}
.landing-page .footer .separator {
  opacity: 0.3;
}
.landing-page .footer .copyright p {
  color: #fff;
}
.landing-page .footer .social-icons ul {
  margin-bottom: 0;
}
.landing-page .footer .social-icons li {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.landing-page .footer .social-icons li a {
  color: #fff;
  font-size: 1.2rem;
}
.landing-page .footer .social-icons li a:hover, .landing-page .footer .social-icons li a:active {
  color: rgba(255, 255, 255, 0.7);
}
.landing-page .feature-image-left {
  float: right;
}
@media (max-width: 767px) {
  .landing-page .feature-image-left,
.landing-page .feature-image-right {
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 115%;
    float: initial;
  }
}
@media (max-width: 767px) {
  .landing-page .heading-team {
    text-align: center;
  }
}
.landing-page .mobile-menu {
  text-align: center;
}
.landing-page .mobile-menu img {
  width: 100px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.landing-page .mobile-menu ul {
  text-align: left;
}
.landing-page .mobile-menu ul li {
  padding: 0.5rem 2.5rem;
  margin-bottom: unset;
}
.landing-page .mobile-menu ul li a {
  font-size: 0.9rem;
}
.landing-page .mobile-menu .dropdown .dropdown-menu {
  position: static !important;
  background: initial;
  border: initial;
  padding: initial;
  margin: initial;
  transform: initial !important;
  margin-top: 0.5rem;
}
.landing-page .mobile-menu .dropdown a.dropdown-item {
  color: snow;
  padding-bottom: 0.5rem;
}
.landing-page .mobile-menu .dropdown a.dropdown-item.active, .landing-page .mobile-menu .dropdown a.dropdown-item:active {
  color: #fff;
}
@media (max-width: 991px) {
  .landing-page .side-bar {
    margin-top: 5rem;
  }
}
.landing-page .side-bar .side-bar-content {
  margin-bottom: 5rem;
}
@media (max-width: 767px) {
  .landing-page .side-bar h2 {
    margin-bottom: 1rem;
  }
}
.landing-page .listing-card-container {
  background: initial;
  box-shadow: initial;
}
.landing-page .listing-card-container .listing-heading {
  height: 65px;
  overflow: hidden;
}
.landing-page .listing-card-container .listing-desc {
  height: 68px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 1rem;
}
@media (max-width: 1199px) {
  .landing-page .listing-card-container .listing-desc {
    height: 46px;
  }
}
.landing-page .video-heading {
  height: 38px;
  overflow: hidden;
}
.landing-page .table-heading {
  box-shadow: initial;
  background: initial;
}
.landing-page form.dark-background input:-webkit-autofill {
  color: white !important;
  -webkit-text-fill-color: white !important;
}
.landing-page form.dark-background .has-float-label > span,
.landing-page form.dark-background .has-float-label label,
.landing-page form.dark-background .has-top-label > span,
.landing-page form.dark-background .has-top-label label {
  color: rgba(255, 255, 255, 0.6);
}
.landing-page form.dark-background .bootstrap-tagsinput,
.landing-page form.dark-background .form-control {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.landing-page form.dark-background .bootstrap-tagsinput:focus, .landing-page form.dark-background .bootstrap-tagsinput:active,
.landing-page form.dark-background .form-control:focus,
.landing-page form.dark-background .form-control:active {
  border-color: rgba(255, 255, 255, 0.6);
}
.landing-page .has-float-label > span,
.landing-page .has-float-label label,
.landing-page .has-top-label > span,
.landing-page .has-top-label label {
  font-size: 64%;
}
.landing-page .video-js.blog-video {
  width: 100%;
  height: 442px;
  background-color: #f8f8f8;
}
@media (max-width: 991px) {
  .landing-page .video-js.blog-video {
    height: 280px;
  }
}
.landing-page .video-js.blog-video .vjs-poster {
  background-size: cover;
}
.landing-page .video-js.side-bar-video {
  width: 100%;
  height: 300px;
  background-color: #f8f8f8;
}
.landing-page .video-js.side-bar-video .vjs-poster {
  background-size: cover;
}
.landing-page .feature-icon-container .detail-text {
  min-height: 100px;
}
@media (max-width: 991px) {
  .landing-page .feature-icon-container .detail-text {
    min-height: 70px;
  }
}
.landing-page .screenshots .nav-tabs {
  margin-bottom: 0 !important;
  border: initial;
}
.landing-page .screenshots .nav-tabs .nav-link {
  border: initial;
  background: initial !important;
  padding-right: 40px;
  padding-left: 40px;
}
.landing-page .screenshots .nav-tabs .nav-item.show .nav-link:before,
.landing-page .screenshots .nav-tabs .nav-link.active:before {
  top: initial;
  bottom: 0;
}
.landing-page .app-image {
  width: 100%;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
}
.landing-page .doc-search {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: transparent;
  border-radius: 40px;
  padding: 0.85rem 0.75rem 0.8rem;
  max-width: 360px;
}
.landing-page .doc-search input {
  color: #fff;
  background: transparent;
  width: 93%;
  padding: 0 0.75rem;
  outline: initial !important;
  border: initial;
}
.landing-page .doc-search input::placeholder {
  color: #fff;
  opacity: 0.7;
}
.landing-page .doc-search i {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.landing-page .feedback-container a {
  font-size: 1.3em;
  color: #3E3A3E;
  margin: 0.5rem;
}
.landing-page .feedback-container a:hover, .landing-page .feedback-container a:active {
  color: #3E3A3E;
}
.landing-page .video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;
}
.landing-page .video-play-icon span {
  position: absolute;
  font-family: VideoJS;
  font-weight: 400;
  font-style: normal;
  background: rgba(255, 255, 255, 0.7);
  height: 1.25em;
  border-radius: 0.75em;
  line-height: 1.25em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.75em;
  width: 2em;
  text-align: center;
}
.landing-page .video-play-icon span:before {
  color: #3E3A3E;
  content: "\f101";
}
.landing-page .video-play-icon:hover span, .landing-page .video-play-icon:active span {
  background: rgba(255, 255, 255, 0.85);
}
.landing-page .page-item .page-link {
  line-height: 1.2;
}

.components-image {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 991px) {
  .components-image {
    width: 1600px;
  }
}
@media (max-width: 767px) {
  .components-image {
    width: 1200px;
  }
}

.color-left {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 33.33%;
  height: 20px;
}

.color-center {
  width: 33.33%;
  height: 20px;
}

.color-right {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  width: 33.33%;
  height: 20px;
}

.color-container {
  height: 20px;
  display: flex;
  justify-content: stretch;
  border-radius: 1rem;
  background-color: initial;
}

.bluenavy-light-1 {
  background-color: #00365a;
}

.bluenavy-light-2 {
  background-color: #fff;
}

.bluenavy-light-3 {
  background-color: #184f90;
}

.bluenavy-dark-1 {
  background-color: #236591;
}

.bluenavy-dark-2 {
  background-color: #1e2022;
}

.bluenavy-dark-3 {
  background-color: #1d477a;
}

.blueolympic-light-1 {
  background-color: #008ecc;
}

.blueolympic-light-2 {
  background-color: #fff;
}

.blueolympic-light-3 {
  background-color: #73c2fb;
}

.blueolympic-dark-1 {
  background-color: #3e83a1;
}

.blueolympic-dark-2 {
  background-color: #1e2022;
}

.blueolympic-dark-3 {
  background-color: #304d72;
}

.blueyale-light-1 {
  background-color: #145388;
}

.blueyale-light-2 {
  background-color: #fff;
}

.blueyale-light-3 {
  background-color: #2a93d5;
}

.blueyale-dark-1 {
  background-color: #38688b;
}

.blueyale-dark-2 {
  background-color: #1e2022;
}

.blueyale-dark-3 {
  background-color: #3e8ab9;
}

.greenmoss-light-1 {
  background-color: #576a3d;
}

.greenmoss-light-2 {
  background-color: #fff;
}

.greenmoss-light-3 {
  background-color: #dd9c02;
}

.greenmoss-dark-1 {
  background-color: #627745;
}

.greenmoss-dark-2 {
  background-color: #1e2022;
}

.greenmoss-dark-3 {
  background-color: #8f7b39;
}

.greenlime-light-1 {
  background-color: #6fb327;
}

.greenlime-light-2 {
  background-color: #fff;
}

.greenlime-light-3 {
  background-color: #51c878;
}

.greenlime-dark-1 {
  background-color: #63883b;
}

.greenlime-dark-2 {
  background-color: #1e2022;
}

.greenlime-dark-3 {
  background-color: #4d7058;
}

.carrotorange-light-1 {
  background-color: #ed7117;
}

.carrotorange-light-2 {
  background-color: #fff;
}

.carrotorange-light-3 {
  background-color: #e7523e;
}

.carrotorange-dark-1 {
  background-color: #ad7140;
}

.carrotorange-dark-2 {
  background-color: #1e2022;
}

.carrotorange-dark-3 {
  background-color: #aa4f43;
}

.rubyred-light-1 {
  background-color: #900604;
}

.rubyred-light-2 {
  background-color: #fff;
}

.rubyred-light-3 {
  background-color: #e7284a;
}

.rubyred-dark-1 {
  background-color: #913a47;
}

.rubyred-dark-2 {
  background-color: #1e2022;
}

.rubyred-dark-3 {
  background-color: #aa5e6c;
}

.monsterpurple-light-1 {
  background-color: #922c88;
}

.monsterpurple-light-2 {
  background-color: #fff;
}

.monsterpurple-light-3 {
  background-color: #4556ac;
}

.monsterpurple-dark-1 {
  background-color: #7e4877;
}

.monsterpurple-dark-2 {
  background-color: #1e2022;
}

.monsterpurple-dark-3 {
  background-color: #3c4b9a;
}

.steelgrey-light-1 {
  background-color: #48494b;
}

.steelgrey-light-2 {
  background-color: #fff;
}

.steelgrey-light-3 {
  background-color: #999da0;
}

.steelgrey-dark-1 {
  background-color: #767e8d;
}

.steelgrey-dark-2 {
  background-color: #1e2022;
}

.steelgrey-dark-3 {
  background-color: #4d5a5f;
}

.granolayellow-light-1 {
  background-color: #c0a145;
}

.granolayellow-light-2 {
  background-color: #fff;
}

.granolayellow-light-3 {
  background-color: #e3b778;
}

.granolayellow-dark-1 {
  background-color: #8a722c;
}

.granolayellow-dark-2 {
  background-color: #1e2022;
}

.granolayellow-dark-3 {
  background-color: #a88048;
}

.rtl .landing-page .color-left {
  border-radius: initial;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.rtl .landing-page .color-right {
  border-radius: initial;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.rtl .landing-page .footer-circle-button {
  float: right;
}
.rtl .landing-page .components-image {
  left: initial;
  right: 50%;
  transform: translateX(50%);
}
.rtl .landing-page .feature-image-left {
  float: left;
}
@media (max-width: 767px) {
  .rtl .landing-page .feature-image-right {
    float: initial;
    margin-left: initial;
    margin-right: 50%;
    transform: translateX(50%);
  }
}
.rtl .landing-page .mobile-hero {
  margin-left: initial;
  transform: translateX(50%);
  margin-right: 50%;
}
.rtl .landing-page .mobile-menu ul {
  padding: initial;
}
.rtl .landing-page .mobile-menu ul .nav-item + .nav-item {
  margin-right: initial;
  margin-left: initial;
}
.rtl .landing-page .mobile-menu-cta {
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-color-black {
  color: #125354 !important;
}

.text-color-redbrown {
  color: #242929 !important;
}

.text-size-1 {
  font-size: 1rem;
}

.no-pointer {
  cursor: default;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 1;
}

p,
body,
html {
  font-family: "PT Sans", sans-serif !important;
  color: #125354;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans Narrow", sans-serif !important;
  font-weight: 900 !important;
  color: snow;
}

html {
  min-width: 350px !important;
}

body {
  background: transparent !important;
  min-width: 350px !important;
  overflow: hidden;
}
body .simple-notification-wrapper {
  z-index: 10000 !important;
}

main {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 575px) {
  main {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
}

.outline .danger {
  color: #DC0D15;
}

.simple-notification.has-icon .sn-content,
.simple-notification.has-icon .sn-html,
.simple-notification.has-icon .sn-title {
  color: #fff;
  font-size: 14px;
}

.container-fluid {
  margin-left: 0.5em !important;
  width: auto;
  height: 100% !important;
  min-height: calc(
    100vh - 100px - 50px - 20px
  ) !important;
}
@media (max-width: 1439px) {
  .container-fluid {
    margin-left: 4em;
    width: auto;
    min-height: calc(
      100vh - 90px - 50px - 20px
    ) !important;
  }
}
@media (max-width: 1199px) {
  .container-fluid {
    margin-left: 4em;
    width: auto;
    min-height: calc(
      100vh - 80px - 50px - 20px
    ) !important;
  }
}
@media (max-width: 767px) {
  .container-fluid {
    margin-left: auto;
    width: auto;
    min-height: calc(
      100vh - 70px - 50px - 20px
    ) !important;
    margin-left: auto;
  }
}

.app-page-card .card-heading {
  background: linear-gradient(to bottom, #9A1915, #DC0D15) !important;
  border-radius: 0.75rem 0.75rem 0 0 !important;
  border-radius: 0.75rem 0.75rem 0 0 !important;
}
.app-page-card .page-card-body {
  background-color: #f0f0f0;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.15);
}

.cc-theme-classic {
  font-size: 13px;
}
.cc-theme-classic > div {
  justify-content: space-between;
  width: 100%;
}
.cc-theme-classic .cc-btn {
  padding: 0 0.5rem;
  margin: 0.05rem 0.25rem;
  min-width: 100px !important;
  width: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cc-theme-classic .cc-btn:last-child {
  min-width: auto;
}

.breadcrumb-container .breadcrumb .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.9);
}
.breadcrumb-container .breadcrumb .breadcrumb-item a:hover, .breadcrumb-container .breadcrumb .breadcrumb-item a:focus {
  font-weight: bolder;
  color: #ffffff;
}
.breadcrumb-container .breadcrumb .breadcrumb-item.active {
  font-weight: bolder;
  color: #ffffff;
}

.menu .main-menu {
  width: min-content;
  background: snow !important;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.5);
}
.menu .main-menu ul li a {
  text-align: center;
  color: #DC0D15 !important;
  padding: 0 1rem;
}
.menu .main-menu ul li a i {
  color: #DC0D15;
}
.menu .main-menu ul li a:hover, .menu .main-menu ul li a:focus {
  color: #3E3A3E;
}
.menu .main-menu ul li::after {
  background: #3E3A3E;
}
.menu .main-menu ul li.active a {
  text-align: center;
  color: #fff !important;
  background: linear-gradient(to bottom, #9A1915, #DC0D15) !important;
  text-align: center;
}
.menu .main-menu ul li.active a i {
  color: #fff !important;
}
@media (max-width: 767px) {
  .menu .main-menu {
    width: 105px;
  }
}
.menu .sub-menu {
  left: 7rem;
  max-width: 272px;
  background: linear-gradient(to bottom, #d7d7d71a, #F0F0F0);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.5) !important;
}
.menu .sub-menu ul li {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  width: max-content;
  position: relative;
  color: #DC0D15;
}
.menu .sub-menu ul li:hover {
  color: #37AB49;
}
.menu .sub-menu ul li:hover i {
  color: #9A1915;
}
.menu .sub-menu ul li.active a {
  color: #37AB49;
}
.menu .sub-menu ul li.active a i {
  color: #9A1915;
}
.menu .sub-menu ul li.active::after {
  background: #5F5F5F;
}
.menu .sub-menu ul a span {
  vertical-align: middle;
  height: 6px;
  width: fit-content;
  color: #3E3A3E;
}
@media (max-width: 767px) {
  .menu .sub-menu {
    transform: translate(-386px);
    left: 5rem;
  }
}

.c-pagination {
  justify-content: center;
}

aw-wizard-navigation-bar {
  display: none !important;
}

.modal-cgu {
  display: flex;
  align-items: center;
  height: calc(100vh - 90px);
}
.modal-cgu a:hover {
  text-decoration: underline !important;
}

.modal-rib .hiddeninputfile {
  display: none !important;
}

.modal-rad .btn.benef-btn {
  padding: 0 !important;
  border-radius: 50% !important;
  border: none !important;
  line-height: 0 !important;
}
.modal-rad .btn.benef-btn i::before {
  margin: 0;
}

.modal-remboursements {
  max-width: 90vw;
}

.modal-content {
  border-radius: 1rem !important;
}
.modal-content .modal-header {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
  background: linear-gradient(45deg, #9A1915, #DC0D15, #E62243);
  color: #ffffff;
  font-weight: 600;
}
.modal-content .modal-body {
  min-width: 34rem;
}
.modal-content .modal-body.content-min-height {
  min-height: 24.2rem;
}
.modal-content .modal-body .form-control:disabled {
  background-color: transparent;
  opacity: 1;
  border-color: #d7d7d74a !important;
  cursor: not-allowed;
}
.modal-content .modal-body .form-control.input-ellipsis {
  overflow: visible;
  white-space: normal;
  text-overflow: initial;
  padding: 0;
}
.modal-content .modal-body .form-control.displayfileinput:disabled {
  cursor: pointer;
}
.modal-content .modal-body .ng-option {
  text-align: start;
  white-space: normal !important;
}
.modal-content .modal-body .ng-option .ng-option-label {
  color: #000000 !important;
}
.modal-content .modal-body .ng-dropdown-panel {
  white-space: normal !important;
}
.modal-content .modal-body .ng-dropdown-panel .ng-dropdown-panel-items {
  white-space: normal !important;
}
.modal-content .modal-body .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #d7d7d7 !important;
}
.modal-content .modal-body .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked.ng-option.ng-option-selected {
  background-color: #9A1915 !important;
}
.modal-content .modal-body .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked.ng-option.ng-option-selected .ng-option-label {
  color: #ffffff !important;
}
.modal-content .modal-body .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  color: #ffffff !important;
}
.modal-content .modal-body.table-container .ngx-datatable span {
  font-size: 0.8rem !important;
  text-align: center;
}
.modal-content .modal-body.table-container .ngx-datatable p {
  font-size: 0.8rem !important;
  margin-bottom: 0 !important;
}
.modal-content .ngx-datatable {
  border: 1px solid #e0e0e0;
}
.modal-content .ngx-datatable span {
  font-size: 0.8rem !important;
}
.modal-content .ngx-datatable p {
  font-size: 0.8rem !important;
  margin-bottom: 0 !important;
}
.modal-content .ngx-datatable .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.modal-content .ngx-datatable .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.modal-content .ngx-datatable .progress-linear .container .bar {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.modal-content .ngx-datatable.lvl2detailtable .datatable-header .datatable-header-inner {
  position: relative;
}
.modal-content .ngx-datatable#lvl3table .datatable-header .datatable-row-center .datatable-header-cell .datatable-header-cell-template-wrap {
  text-align: center;
}

.datatable-row-detail {
  height: max-content !important;
}

#lvl1table datatable-body {
  width: 100%;
  overflow-x: visible;
  height: auto !important;
  padding: 0;
}
#lvl1table datatable-body datatable-selection datatable-scroller {
  width: 100% !important;
}
#lvl1table datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row {
  width: 100% !important;
}
#lvl1table datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-header .datatable-row-detail {
  height: max-content;
}
#lvl1table datatable-body datatable-selection datatable-scroller datatable-row-wrapper .datatable-row-even {
  width: 100%;
}
#lvl1table datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-row-detail {
  width: 100% !important;
}

.bs-datepicker .bs-datepicker-head button:disabled {
  color: #00000029;
}
.bs-datepicker .bs-datepicker-body .months td span {
  text-transform: capitalize;
}

.container.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url("../../logos/background.png") !important;
  width: 100vw;
  min-height: calc(100vh + 10px);
  padding: 0;
  margin: 0;
  left: 0;
  top: -10px;
  min-width: 22rem;
  position: absolute;
  transition: unset !important;
}
@media (min-width: 1199px) {
  .container.user-container {
    background-size: cover !important;
  }
}
.container.user-container .section-login.auth-card {
  width: 28rem !important;
}
.container.user-container .section-login.auth-card .form-side {
  width: 100%;
  height: 100%;
  min-width: 350px;
  min-height: 30rem;
  padding: 35px 60px !important;
}
@media (max-width: 767px) {
  .container.user-container .section-login.auth-card .form-side {
    padding: 30px 60px !important;
  }
}
@media (max-width: 575px) {
  .container.user-container .section-login.auth-card .form-side {
    padding: 20px 30px !important;
  }
}
@media (max-width: 419px) {
  .container.user-container .section-login.auth-card .form-side {
    padding: 10px 20px !important;
  }
}
.container.user-container .section-login.auth-card .form-side .logo-single {
  margin: 0 !important;
  width: 100%;
  height: 9rem;
  padding: 0 !important;
}
.container.user-container .section-login.auth-card .form-side h1 {
  font-weight: bold;
}
.container.user-container .section-login.auth-card .form-side input.form-control {
  width: 14rem;
}
.container.user-container .section-login.auth-card .form-side .input-group input.form-control {
  width: 11.5rem;
}
.container.user-container .section-login.auth-card .form-side .error-l-75 {
  width: 18rem;
}
.container.user-container .section-login.auth-card .form-side .btn {
  background-color: #004171;
  color: white;
  white-space: nowrap !important;
}
.container.user-container .section-login.auth-card .form-side .btn:disabled {
  background-color: rgba(29, 55, 75, 0.322);
  color: white !important;
}
.container.user-container .section-login.auth-card .form-side .btn:disabled:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  color: white !important;
}
.container.user-container .section-login.auth-card .form-side .btn.btn-radio {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  color: snow;
  white-space: normal !important;
}
.container.user-container .section-login.auth-card .form-side .btn.btn-radio:hover {
  color: white !important;
  background-color: snow !important;
  border-color: snow !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
}
.container.user-container .section-login.auth-card .form-side .btn.btn-radio.active {
  color: white !important;
  background-color: snow !important;
  border-color: snow !important;
}
.container.user-container .section-login.auth-card .form-side .icon-fusion .simple-icon-ban {
  position: absolute;
  top: 0.2rem;
}
.container.user-container .section-login.auth-card .form-side .icon-fusion .simple-icon-ban::before {
  content: "/";
  font-size: 1.5rem;
  padding-left: 0.2rem;
}

footer.page-footer {
  position: relative !important;
  bottom: 0 !important;
  height: 50px !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}
footer.page-footer .footer-content {
  width: 100%;
}
footer.page-footer .footer-content .breadcrumb {
  margin-bottom: 0;
  padding-bottom: 0;
}
footer.page-footer.bg-light {
  border-radius: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

@media (max-width: 575px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    padding-right: 1px;
    padding-left: 1px;
  }
}

.bg-light {
  border-radius: 5px;
  background-color: #fff !important;
  border: none !important;
}

.section.home {
  overflow: auto;
  height: calc(100vh - 100px) !important;
  margin-top: 100px !important;
}
@media (max-width: 1439px) {
  .section.home {
    height: calc(100vh - 90px) !important;
    margin-top: 90px !important;
  }
}
@media (max-width: 1199px) {
  .section.home {
    height: calc(100vh - 80px) !important;
    margin-top: 80px !important;
  }
}
@media (max-width: 767px) {
  .section.home {
    height: calc(100vh - 70px) !important;
    margin-top: 70px !important;
  }
}

.image-logo-mf > img {
  vertical-align: middle;
  border-style: none;
  max-width: 91px;
  position: fixed;
  top: 8px;
  left: 69%;
}

.section-a-savoir a {
  color: #242929;
}
.section-a-savoir a .no-link p {
  margin-bottom: 1rem;
}
@media (min-width: 991px) {
  .section-a-savoir a .no-link p {
    margin-bottom: 0;
  }
}
.section-a-savoir a .linkhover {
  color: snow;
}
.section-a-savoir a:hover .linkhover {
  color: snow;
  text-decoration: underline;
}
.section-a-savoir a:hover .linkhover.no-link {
  text-decoration: none;
}
.section-a-savoir a .list-card {
  display: flex;
  flex-direction: row;
  min-height: 85px;
}
.section-a-savoir a .list-card .div-thumbnail {
  width: 80px;
}
@media (min-width: 419px) {
  .section-a-savoir a .list-card .div-thumbnail {
    width: 100px;
  }
}
@media (min-width: 575px) {
  .section-a-savoir a .list-card .div-thumbnail {
    width: 85px;
  }
}
@media (min-width: 767px) {
  .section-a-savoir a .list-card .div-thumbnail {
    width: 90px;
  }
}
@media (min-width: 991px) {
  .section-a-savoir a .list-card .div-thumbnail {
    width: 100px;
  }
}
@media (min-width: 1199px) {
  .section-a-savoir a .list-card .div-thumbnail {
    width: 125px;
  }
}
@media (min-width: 1439px) {
  .section-a-savoir a .list-card .div-thumbnail {
    width: 150px;
  }
}
.section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
  width: 80px;
  height: 100%;
}
@media (max-width: 419px) {
  .section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
    width: 100px;
  }
}
@media (min-width: 575px) {
  .section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
    width: 85px;
  }
}
@media (min-width: 767px) {
  .section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
    width: 90px;
  }
}
@media (min-width: 991px) {
  .section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
    width: 100px;
  }
}
@media (min-width: 1199px) {
  .section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
    width: 125px;
  }
}
@media (min-width: 1439px) {
  .section-a-savoir a .list-card .div-thumbnail .list-thumbnail {
    width: 150px;
  }
}
.section-a-savoir a .list-card .div-content {
  width: 100%;
}
.section-a-savoir a .list-card .div-content .card-body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 991px) {
  .section-a-savoir a .list-card .div-content .card-body {
    flex-direction: row;
    justify-content: space-between;
  }
}
.section-a-savoir a .list-card .div-content .card-body .div-title {
  display: flex;
  flex-direction: column;
  padding-right: 25px;
}
.section-a-savoir a .list-card .div-content .card-body .div-next {
  display: flex;
  flex-direction: column;
  min-width: 100px;
}
.section-a-savoir a .list-card .mw-5rem {
  min-width: 5rem;
}

.section-informations .section-informations-card .card-min-height {
  min-height: 14rem !important;
}
.section-informations .section-informations-card i.simple-icon-people {
  opacity: 0;
  font-size: 1rem;
}
.section-informations .section-informations-card i.simple-icon-people:before {
  padding: 5px;
}
.section-informations .section-informations-card i.iconclass {
  color: snow;
  font-size: 1rem;
}
.section-informations .section-informations-card i.iconclass:before {
  border-radius: 50%;
  padding: 5px;
  background: snow;
  color: white;
  background: linear-gradient(to bottom, #757575, #8F8F8F) !important;
}
.section-informations .section-informations-card button.icon-button {
  width: 26px;
  height: 26px;
  line-height: 26px;
}
.section-informations .section-informations-card button.iconclass {
  opacity: 1 !important;
}

.section-adhesion-contrat .card-contrat {
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
}
.section-adhesion-contrat .card-contrat .sante {
  color: #9A1915 !important;
}
.section-adhesion-contrat .card-contrat .sante-top {
  color: #e1e1e1 !important;
}
.section-adhesion-contrat .card-contrat .sante-plus {
  color: #856f5a !important;
}
.section-adhesion-contrat .card-contrat .sante-max {
  color: #e7000e !important;
}
.section-adhesion-contrat .card-contrat .prevoyance {
  color: #9A1915 !important;
}
.section-adhesion-contrat .card-contrat .prevention {
  color: #856f5a !important;
}
.section-adhesion-contrat .card-contrat .social {
  color: #e1e1e1 !important;
}
.section-adhesion-contrat .card-contrat .garantimmo {
  color: #e7000e !important;
}
.section-adhesion-contrat .card-contrat .contrat-img {
  max-width: 5rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 991px) {
  .section-adhesion-contrat .card-contrat {
    background-image: none !important;
  }
}

.section-adhesion-benef .separator {
  border-top: 2px dashed snow !important;
  border: none;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.section-adhesion-benef .alert-primary {
  border: none;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  background-color: none !important;
  background: none !important;
}
.section-adhesion-benef .alert-primary.sante {
  background: linear-gradient(225deg, #125354 0%, #e1e1e1 10%, #dc3545bf 20%, #de6360 30%, #c43d4b2e 40%, rgba(255, 255, 255, 0) 100%) !important;
}
.section-adhesion-benef .alert-primary.sante-top {
  background: linear-gradient(225deg, #ec008c 0%, rgba(236, 0, 140, 0.8) 10%, rgba(236, 0, 140, 0.6) 20%, rgba(236, 0, 140, 0.4) 30%, rgba(236, 0, 140, 0.2) 40%, rgba(255, 255, 255, 0) 100%) !important;
}
.section-adhesion-benef .alert-primary.sante-plus {
  background: linear-gradient(225deg, #8cc642 0%, rgba(140, 198, 66, 0.8) 10%, rgba(140, 198, 66, 0.6) 20%, rgba(140, 198, 66, 0.4) 30%, rgba(140, 198, 66, 0.2) 40%, rgba(255, 255, 255, 0) 100%) !important;
}
.section-adhesion-benef .alert-primary.sante-max {
  background: linear-gradient(225deg, #f58220 0%, rgba(245, 130, 32, 0.8) 10%, rgba(245, 130, 32, 0.6) 20%, rgba(245, 130, 32, 0.4) 30%, rgba(245, 130, 32, 0.2) 40%, white 100%) !important;
}
.section-adhesion-benef .contrat-img {
  max-width: 5rem;
}
.section-adhesion-benef .badge.badge-pill {
  min-width: 5rem;
  max-height: 1.5rem;
}
.section-adhesion-benef .badge.badge-benef {
  position: absolute;
  top: 8px;
}
.section-adhesion-benef .badge.badge-sante {
  background-color: #FBBB21 !important;
}
.section-adhesion-benef .badge.badge-sante-top {
  background-color: #e1e1e1 !important;
  color: white;
}
.section-adhesion-benef .badge.badge-sante-plus {
  background-color: #856f5a !important;
}
.section-adhesion-benef .badge.badge-sante-max {
  background-color: #e7000e !important;
  color: white;
}
.section-adhesion-benef .badge .dropdown-menu-contrat {
  inset: 1.5rem auto auto -19rem !important;
  background-color: #D3D3D3;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante {
  color: #3E3A3E;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante .dropdown-item {
  color: #3E3A3E;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante .dropdown-item:hover, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante .dropdown-item:focus {
  background-color: #F0F0F0 !important;
  color: #3E3A3E !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante .dropdown-item.active, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante .dropdown-item:active {
  color: #3E3A3E;
  text-decoration: none !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-top .dropdown-item {
  color: #3E3A3E;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-top .dropdown-item:hover, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-top .dropdown-item:focus {
  background-color: #e1e1e1 !important;
  color: white !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-top .dropdown-item.active, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-top .dropdown-item:active {
  color: white !important;
  text-decoration: none !important;
  background-color: #e1e1e1 !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-plus .dropdown-item:hover, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-plus .dropdown-item:focus {
  background-color: #856f5a !important;
  color: #125354 !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-plus .dropdown-item.active, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-plus .dropdown-item:active {
  color: white !important;
  text-decoration: none !important;
  background-color: #856f5a !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-max .dropdown-item:hover, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-max .dropdown-item:focus {
  background-color: #e7000e !important;
  color: #125354 !important;
}
.section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-max .dropdown-item.active, .section-adhesion-benef .badge .dropdown-menu-contrat.dropdown-menu-sante-max .dropdown-item:active {
  color: white !important;
  text-decoration: none !important;
  background-color: #e7000e !important;
}
.section-adhesion-benef div.position-relative:hover .section-adhesion-benef-card .card-contrat {
  background-repeat: no-repeat !important;
  background-position-x: right !important;
  background-size: contain !important;
  background-color: transparent !important;
}
.section-adhesion-benef div.position-relative:hover .section-adhesion-benef-card .card-contrat-sante {
  background: url("/assets/logos/contrats/contrat-benef-sante.png");
  background-position-x: right;
}
.section-adhesion-benef div.position-relative:hover .section-adhesion-benef-card .card-contrat-sante-top {
  background: url("/assets/logos/contrats/contrat-benef-sante-top.svg");
}
.section-adhesion-benef div.position-relative:hover .section-adhesion-benef-card .card-contrat-sante-plus {
  background: url("/assets/logos/contrats/contrat-benef-sante-plus.svg");
}
.section-adhesion-benef div.position-relative:hover .section-adhesion-benef-card .card-contrat-sante-max {
  background-image: url("/assets/logos/contrats/contrat-benef-sante-max.svg");
}
.section-adhesion-benef .btn-options {
  padding: 0.25rem 0.75rem !important;
}
.section-adhesion-benef .btn-options:hover .section-adhesion-benef-card .card-contrat {
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
  background-size: contain !important;
}
.section-adhesion-benef .btn-options:hover .section-adhesion-benef-card .card-contrat-sante {
  background-image: url("/assets/logos/contrats/contrat-benef-sante.svg");
}
.section-adhesion-benef .btn-options:hover .section-adhesion-benef-card .card-contrat-sante-top {
  background-image: url("/assets/logos/contrats/contrat-benef-sante-top.svg");
}
.section-adhesion-benef .btn-options:hover .section-adhesion-benef-card .card-contrat-sante-plus {
  background-image: url("/assets/logos/contrats/contrat-benef-sante-plus.svg");
}
.section-adhesion-benef .btn-options:hover .section-adhesion-benef-card .card-contrat-sante-max {
  background-image: url("/assets/logos/contrats/contrat-benef-sante-max.svg");
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td {
  border: none !important;
  text-align: center;
  vertical-align: sub;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-typebenef,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-typebenef {
  min-width: 7.5rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-nom,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-nom {
  min-width: 6rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-prenom,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-prenom {
  min-width: 8rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-ddn,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-ddn {
  min-width: 8.5rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-debdroitmpl,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-debdroitmpl {
  min-width: 9.5rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-contrat,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-contrat {
  min-width: 7rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-debcontrat,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-debcontrat {
  min-width: 9.5rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-findroitmpl,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-findroitmpl {
  min-width: 9.5rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-radiation,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-radiation {
  min-width: 9.5rem;
}
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table th.th-procdebut,
.section-adhesion-benef .row-benef-cards .section-adhesion-benef-card .table td.th-procdebut {
  min-width: 6.5rem;
}
.section-adhesion-benef .row-benef-table .ngx-datatable {
  /*-----------------------------------------------------*/
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -1px 6px 6px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.15);
}
.section-adhesion-benef .row-benef-table .ngx-datatable span {
  font-size: 0.8rem !important;
}
.section-adhesion-benef .row-benef-table .ngx-datatable p {
  font-size: 0.8rem !important;
  margin-bottom: 0 !important;
}
.section-adhesion-benef .row-benef-table .ngx-datatable .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.section-adhesion-benef .row-benef-table .ngx-datatable .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  transform: translate(0, 0) scale(1, 1);
  width: 100%;
  height: 5px;
  background-color: #aad1f9;
}
.section-adhesion-benef .row-benef-table .ngx-datatable .progress-linear .container .bar {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.section-adhesion-benef .row-benef-table .ngx-datatable .datatable-header {
  margin-left: 8px;
  margin-bottom: 0;
  min-width: calc(100% - 16px) !important;
}
@media (max-width: 1439px) {
  .section-adhesion-benef .row-benef-table .ngx-datatable .datatable-header {
    min-width: 1334px !important;
  }
}
@media (min-width: 1439px) {
  .section-adhesion-benef .row-benef-table .ngx-datatable .datatable-header {
    min-width: calc(100% - 16px) !important;
  }
}
.section-adhesion-benef .row-benef-table .ngx-datatable .datatable-footer-inner {
  overflow: hidden;
}
.section-adhesion-benef .row-pagination ul {
  margin-bottom: 0;
}
.section-adhesion-benef .row-pagination ul li {
  margin-bottom: 0;
}

.section-cotis-appel input.form-control {
  text-transform: capitalize;
  width: fit-content;
  min-width: 13rem;
}
.section-cotis-appel .ngb-dp-arrow {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}
.section-cotis-appel .ngb-dp-arrow-btn {
  padding: 0 0.25rem;
  margin: 0 0.5rem;
  border: none;
  background-color: transparent;
}
.section-cotis-appel .ngb-dp-navigation-chevron {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  margin-left: -0.5em;
  margin-right: 0.25em;
  transform: rotate(-135deg);
}
.section-cotis-appel .right .ngb-dp-navigation-chevron {
  transform: rotate(45deg);
  margin-left: 0.5em;
  margin-right: 0.25em;
}
.section-cotis-appel .selectYear {
  display: block;
  width: 100%;
  padding: 0.25rem 0.25rem;
  clear: both;
  text-align: center;
}
.section-cotis-appel .dropdown-item {
  padding: 0.5rem 0.5rem !important;
  text-align: center;
}
.section-cotis-appel .btn {
  height: calc(1.5em + 1rem + 2px) !important;
}

.section-fichier-cotis input.form-control {
  text-transform: capitalize;
  width: fit-content;
  min-width: 13rem;
}
.section-fichier-cotis .ngb-dp-arrow {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}
.section-fichier-cotis .ngb-dp-arrow-btn {
  padding: 0 0.25rem;
  margin: 0 0.5rem;
  border: none;
  background-color: transparent;
}
.section-fichier-cotis .ngb-dp-navigation-chevron {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  margin-left: -0.5em;
  margin-right: 0.25em;
  transform: rotate(-135deg);
}
.section-fichier-cotis .right .ngb-dp-navigation-chevron {
  transform: rotate(45deg);
  margin-left: 0.5em;
  margin-right: 0.25em;
}
.section-fichier-cotis .selectYear {
  display: block;
  width: 100%;
  padding: 0.25rem 0.25rem;
  clear: both;
  text-align: center;
}
.section-fichier-cotis .dropdown-item {
  padding: 0.5rem 0.5rem !important;
  text-align: center;
}
.section-fichier-cotis .btn {
  height: calc(1.5em + 1rem + 2px) !important;
}

.section-cotis-situ-compte input.form-control,
.section-remb-mes-telechargements input.form-control {
  text-transform: capitalize;
  width: fit-content;
  z-index: 11 !important;
  opacity: 0.75;
}
.section-cotis-situ-compte .btn,
.section-remb-mes-telechargements .btn {
  height: calc(1.5em + 1rem + 2px) !important;
}
.section-cotis-situ-compte #imgChevronBas,
.section-remb-mes-telechargements #imgChevronBas {
  z-index: 9 !important;
  position: absolute;
  left: 165px;
  margin-right: 4px;
  top: 11px;
  opacity: 1;
}
@media (max-width: 575px) {
  .section-cotis-situ-compte #imgChevronBas,
.section-remb-mes-telechargements #imgChevronBas {
    z-index: 9 !important;
    position: absolute;
    left: 152px;
    margin-right: 4px;
    top: 11px;
    opacity: 1;
  }
}

.section-compte .text-invalid {
  color: #ca0000;
}
.section-compte .card-body .lh-8 {
  line-height: 8px;
}
@media (min-width: 575px) {
  .section-compte .card-body {
    min-height: 21rem;
  }
}
.section-compte .card-body .control-label {
  z-index: 4;
}
.section-compte .card-body .alert-primary {
  color: #242929 !important;
  margin-bottom: 2rem;
}
.section-compte .card-body .input-upper {
  text-transform: uppercase;
}
.section-compte .card-body .icon-fusion .simple-icon-eye {
  padding-top: 0;
}
.section-compte .card-body .icon-fusion .simple-icon-ban {
  position: absolute;
  top: 4px;
}
.section-compte .card-body .icon-fusion .simple-icon-ban::before {
  content: "/";
  font-size: 1.5rem;
  padding-left: 0.2rem;
}

.section-remb-remb .selected {
  background-color: #eee;
}
.section-remb-remb .page-card-body {
  background-color: white !important;
}
.section-remb-remb .progress-linear {
  position: relative !important;
}
.section-remb-remb .progress-linear .container {
  max-width: max-content !important;
}
.section-remb-remb .head {
  background-color: white;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: -1px 6px 6px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.15);
}
.section-remb-remb .modal-xl {
  max-width: 85%;
}
.section-remb-remb .ngx-datatable {
  border: 1px solid #e0e0e0;
}
.section-remb-remb .ngx-datatable span {
  font-size: 0.8rem !important;
}
.section-remb-remb .ngx-datatable p {
  font-size: 0.8rem !important;
  margin-bottom: 0 !important;
}
.section-remb-remb .ngx-datatable .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.section-remb-remb .ngx-datatable .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.section-remb-remb .ngx-datatable .progress-linear .container .bar {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
.section-remb-remb .simple-icon-plus {
  cursor: pointer;
}