////////////////////////////////////////////////////////////

.text-color-black {
  color: $text-color-black !important;
}
.text-color-redbrown {
  color: $text-color-redbrown !important;
}

.text-size-1 {
  font-size: 1rem;
}

.no-pointer {
  cursor: default;
}

//.nowrap { white-space: nowrap; }

.op-0 {
  opacity: 0;
}
.op-1 {
  opacity: 1;
}

p,
body,
html {
  font-family: "PT Sans", sans-serif !important;
  color: $text-color-black;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans Narrow", sans-serif !important;
  font-weight: 900 !important;
  color: $primary-color;
}

////////////////////////////////////////////////////////////

html {
  min-width: 350px !important;
}

body {
  background: transparent !important;
  min-width: 350px !important;
  overflow: hidden;
  .simple-notification-wrapper {
    z-index: 10000 !important;
  }
}

main {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  @include respond-below(xs) {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
}

.outline .danger {
  color: #DC0D15;
}

.simple-notification.has-icon .sn-content,
.simple-notification.has-icon .sn-html,
.simple-notification.has-icon .sn-title {
  color: #fff;
  font-size: 14px;
}

.container-fluid {
  margin-left: 0.5em !important;
  width: auto;
  height: 100% !important;
  min-height: calc(
    100vh - #{$navbar-height} - #{$footer-height} - 20px
  ) !important;
  @include respond-below(xl) {
    margin-left: 4em;
    width: auto;
    min-height: calc(
      100vh - #{$navbar-height-lg} - #{$footer-height} - 20px
    ) !important;
  }
  @include respond-below(lg) {
    margin-left: 4em;
    width: auto;
    min-height: calc(
      100vh - #{$navbar-height-md} - #{$footer-height} - 20px
    ) !important;
  }
  @include respond-below(sm) {
    margin-left: auto;
    width: auto;
    min-height: calc(
      100vh - #{$navbar-height-xs} - #{$footer-height} - 20px
    ) !important;
    margin-left: auto;
  }
}

.app-page-card {
  .card-heading {
    background: linear-gradient(to bottom, #9A1915, #DC0D15) !important;
    border-radius: 0.75rem 0.75rem 0 0 !important;
    border-radius: 0.75rem 0.75rem 0 0 !important;
  }
  .page-card-body {
    background-color: #f0f0f0;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    box-shadow: 0 1px 15px rgb(0 0 0 / 15%), 0 1px 6px rgb(0 0 0 / 15%);
  }
}

.cc-theme-classic {
  font-size: 13px;
  > div {
    justify-content: space-between;
    width: 100%;
  }
  .cc-btn {
    padding: 0 0.5rem;
    margin: 0.05rem 0.25rem;
    min-width: 100px !important;
    width: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      min-width: auto;
    }
  }
}

////////////////////////////// BREADCRUMB //////////////////////////////

.breadcrumb-container {
  .breadcrumb {
    .breadcrumb-item {
      a {
        color: rgba(#fff, 0.9);
        &:hover,
        &:focus {
          font-weight: bolder;
          color: #ffffff;
        }
      }
      &.active {
        font-weight: bolder;
        color: #ffffff;
      }
    }
  }
}

////////////////////////////// SIDEBAR //////////////////////////////

.menu {
  .main-menu {
    width: min-content;

    background: $primary-color !important;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 50%);
    ul {
      li {
        a {
          text-align: center;
          color: #DC0D15 !important;
          padding: 0 1rem;
          i {
            color: #DC0D15;
          }
          &:hover,
          &:focus {
            color: $secondary-color;
          }
        }
        &::after {
          background: $secondary-color;
        }
        &.active {

          a {

 
            text-align: center;
            color: #fff !important;
            background:linear-gradient(to bottom, #9A1915, #DC0D15) !important;
            text-align: center;
            i {
              color: #fff !important;
            }
          }
          
        }
      }
    }
    @include respond-below(sm) {
      width: 105px;
    }
  }
  .sub-menu {
    left: 7rem;
    max-width: 272px;
    background: linear-gradient(to bottom, #d7d7d71a, #F0F0F0);
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 50%) !important;
    ul {
      li {

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: flex-start;
     
        width: max-content;
        position: relative;
      

         color: #DC0D15;
         &:hover {
          color: #37AB49 ;
          i{
         
            color:#9A1915;
          
          }
         }
        &.active {
          a {
            color: #37AB49 ;
            i {
              color: #9A1915 ;
            }
          }
          &::after {
            background: #5F5F5F;
          }
        }
        
      }

      a{
        span{
          vertical-align: middle;
          height: 6px;
          width: fit-content;
          color : #3E3A3E;
        }
      }
    
  }
    @include respond-below(sm) {
      transform: translate(-386px);
      left: 5rem;
    }
  }
}

////////////////////////////// PAGINATION //////////////////////////////

.c-pagination {
  justify-content: center;
}

///////////////////////////// WIZZARD ///////////////////////////////

aw-wizard-navigation-bar {
  display: none !important;
}

////////////////////////////// MODAL //////////////////////////////

.modal-cgu {
  display: flex;
  align-items: center;
  height: calc(100vh - 90px);
  a:hover {
    text-decoration: underline !important;
  }
}
.modal-rib {
  .hiddeninputfile {
    display: none !important;
  }
}
.modal-rad {
  .btn {
    &.benef-btn {
      padding: 0 !important;
      border-radius: 50% !important;
      border: none !important;
      line-height: 0 !important;
      i {
        &::before {
          margin: 0;
        }
      }
    }
  }
}
.modal-remboursements {
  max-width: 90vw;
}
.modal-content {
  border-radius: 1rem !important;

  .modal-header {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    background: linear-gradient(45deg, #9A1915, #DC0D15,#E62243);
    color: #ffffff;
    font-weight: 600;
  }
  .modal-body {
    min-width: 34rem;
    &.content-min-height {
      min-height: 24.2rem;
    }
    .form-control {
      &:disabled {
        background-color: transparent;
        opacity: 1;
        border-color: #d7d7d74a !important;
        cursor: not-allowed;
      }
      &.input-ellipsis {
        overflow: visible;
        white-space: normal;
        text-overflow: initial;
        padding: 0;
      }
      &.displayfileinput {
        &:disabled {
          cursor: pointer;
        }
      }
    }
    .ng-option {
      text-align: start;
      white-space: normal !important;
      .ng-option-label {
        color: #000000 !important;
      }
    }
    .ng-dropdown-panel {
      white-space: normal !important;
      .ng-dropdown-panel-items {
        white-space: normal !important;
        .ng-option.ng-option-marked {
          background-color: #d7d7d7 !important;
          &.ng-option.ng-option-selected {
            background-color: $custom-secondary !important;
            .ng-option-label {
              color: #ffffff !important;
            }
          }
        }
        .ng-option.ng-option-selected {
          .ng-option-label {
            color: #ffffff !important;
          }
        }
      }
    }
    &.table-container {
      .ngx-datatable {
        span {
          font-size: 0.8rem !important;
          text-align: center;
        }
        p {
          font-size: 0.8rem !important;
          margin-bottom: 0 !important;
         // margin-top: 5px;
        }
      }
    }
  }
  .ngx-datatable {
    border: 1px solid #e0e0e0;
    span {
      font-size: 0.8rem !important;
    }
    p {
      font-size: 0.8rem !important;
      margin-bottom: 0 !important;
    }
    .progress-linear {
      display: block;
      position: relative;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;
      position: absolute;
      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        transform: translate(0, 0) scale(1, 1);
        background-color: #aad1f9;
        .bar {
          transition: all 0.2s linear;
          animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          transition: -webkit-transform 0.2s linear;
          transition: transform 0.2s linear;
          background-color: #106cc8;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
        }
      }
    }

    &.lvl2detailtable {
      .datatable-header {
        .datatable-header-inner {
          position: relative;
          .datatable-row-center {
            .datatable-header-cell {
              .datatable-header-cell-template-wrap {
              }
            }

            .datatable-header-cell-template-wrap {
              .datatable-header-cell-wrapper {
                .datatable-header-cell-label {
                }
              }
            }
          }
        }
      }

    }

    &#lvl3table {
      .datatable-header {
        .datatable-row-center {
          .datatable-header-cell {
            .datatable-header-cell-template-wrap {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.datatable-row-detail {
  height: max-content !important;
}
#lvl1table {
 
  datatable-body {
    width: 100%;
    overflow-x: visible;
    height: auto !important;
    padding: 0;
    datatable-selection {
      datatable-scroller {
        width: 100%!important;
      
        datatable-row-wrapper {
          datatable-body-row{
            width: 100%!important;
          }
          datatable-header {
            .datatable-row-detail {
              height: max-content;
            }
          }
          .datatable-row-even{
            width: 100%;
          }
          datatable-row-detail{
            width: 100%!important;
          }
        }
      }
    }
  }
}

////////////////////////////// DATEPICKER //////////////////////////////

.bs-datepicker {
  .bs-datepicker-head {
    button {
      &:disabled {
        color: #00000029;
      }
    }
  }
  .bs-datepicker-body {
    .months {
      td {
        span {
          text-transform: capitalize;
        }
      }
    }
  }
}

////////////////////////////// LOGIN CONTAINER //////////////////////////////

.container {
  &.user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-image: url("../../logos/background.png") !important;
    width: 100vw;
    min-height: calc(100vh + 10px);
    padding: 0;
    margin: 0;
    left: 0;
    top: -10px;
    min-width: 22rem;
    position: absolute;
    transition: unset !important;
    @include respond-above(lg) {
      background-size: cover !important;
    }
    .section-login {
      &.auth-card {
        width: 28rem !important;
        .form-side {
          width: 100%;
          height: 100%;
          min-width: 350px;
          min-height: 30rem;
          padding: 35px 60px !important;
          @include respond-below(sm) {
            padding: 30px 60px !important;
          }
          @include respond-below(xs) {
            padding: 20px 30px !important;
          }
          @include respond-below(xxs) {
            padding: 10px 20px !important;
          }
          .logo-single {
            margin: 0 !important;
            width: 100%;
            height: 9rem;
            padding: 0 !important;
          }
          h1 {
            font-weight: bold;
          }
          input.form-control {
            width: 14rem;
          }
          .input-group {
            input.form-control {
              width: 11.5rem;
            }
          }
          .error-l-75 {
            width: 18rem;
          }
          .btn {
            background-color: #004171;
            color: white;
            white-space: nowrap !important;
            &:disabled {
              background-color: rgba(29, 55, 75, 0.322);
              color: white !important;
              &:hover {
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%),
                  0 1px 3px 1px rgb(0 0 0 / 15%);
                color: white !important;
              }
            }
            &.btn-radio {
              display: flex;
              align-items: center;
              background-color: transparent !important;
              color: $primary-color;
              white-space: normal !important;
              &:hover {
                color: white !important;
                background-color: $primary-color !important;
                border-color: $primary-color !important;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%),
                  0 1px 3px 1px rgb(0 0 0 / 15%) !important;
              }
              &.active {
                color: white !important;
                background-color: $primary-color !important;
                border-color: $primary-color !important;
              }
            }
          }
          .icon-fusion {
            .simple-icon-ban {
              position: absolute;
              top: 0.2rem;
              &::before {
                content: "/";
                font-size: 1.5rem;
                padding-left: 0.2rem;
              }
            }
          }
        }
      }
    }
  }
}

////////////////////////////// FOOTER //////////////////////////////

footer.page-footer {
  position: relative !important;
  bottom: 0 !important;
  height: $footer-height !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  .footer-content {
    width: 100%;
    .breadcrumb {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  &.bg-light {
    border-radius: 0;
    @include depth(1);
  }
}

////////////////////////////// BOOTSTRAP GRID //////////////////////////////

.row {
  @include respond-below(xs) {
    margin-right: 0;
    margin-left: 0;
  }
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  @include respond-below(xs) {
    padding-right: 1px;
    padding-left: 1px;
  }
}

////////////////////////////// CARD //////////////////////////////

.bg-light {
  border-radius: 5px;
  background-color: #fff !important;
  border: none !important;
}

////////////////////////////// HOME //////////////////////////////

.section {
  &.home {
    overflow: auto;
    height: calc(100vh - #{$navbar-height}) !important;
    margin-top: $navbar-height !important;
    @include respond-below(xl) {
      height: calc(100vh - #{$navbar-height-lg}) !important;
      margin-top: $navbar-height-lg !important;
    }
    @include respond-below(lg) {
      height: calc(100vh - #{$navbar-height-md}) !important;
      margin-top: $navbar-height-md !important;
    }
    @include respond-below(sm) {
      height: calc(100vh - #{$navbar-height-xs}) !important;
      margin-top: $navbar-height-xs !important;
    }
  }
}

.image-logo-mf > img {
  vertical-align: middle;
  border-style: none;
  max-width: 91px;
  position: fixed;
  top: 8px;
  left: 69%;
}
////////////////////////////// "A SAVOIR" //////////////////////////////

.section-a-savoir {
  a {
    color: $text-color-redbrown;
    .no-link {
      p {
        margin-bottom: 1rem;
        @include respond-above(md) {
          margin-bottom: 0;
        }
      }
    }
    .linkhover {
      color: $primary-color;
    }
    &:hover {
      .linkhover {
        color: $primary-color;
        text-decoration: underline;
        &.no-link {
          text-decoration: none;
        }
      }
    }
    .list-card {
      display: flex;
      flex-direction: row;
      min-height: 85px;
      .div-thumbnail {
        width: 80px;
        @include respond-above(xxs) {
          width: 100px;
        }
        @include respond-above(xs) {
          width: 85px;
        }
        @include respond-above(sm) {
          width: 90px;
        }
        @include respond-above(md) {
          width: 100px;
        }
        @include respond-above(lg) {
          width: 125px;
        }
        @include respond-above(xl) {
          width: 150px;
        }
        .list-thumbnail {
          width: 80px;
          @include respond-below(xxs) {
            width: 100px;
          }
          @include respond-above(xs) {
            width: 85px;
          }
          @include respond-above(sm) {
            width: 90px;
          }
          @include respond-above(md) {
            width: 100px;
          }
          @include respond-above(lg) {
            width: 125px;
          }
          @include respond-above(xl) {
            width: 150px;
          }
          height: 100%;
        }
      }
      .div-content {
        width: 100%;
        .card-body {
          display: flex;
          flex-direction: column;
          @include respond-above(md) {
            flex-direction: row;
            justify-content: space-between;
          }
          .div-title {
            display: flex;
            flex-direction: column;
            padding-right: 25px;
          }
          .div-next {
            display: flex;
            flex-direction: column;
            min-width: 100px;
          }
        }
      }
      .mw-5rem {
        min-width: 5rem;
      }
    }
  }
}

////////////////////////////// "MES INFORMATIONS" //////////////////////////////

.section-informations {
  .section-informations-card {
    .card-min-height {
      min-height: 14rem !important;
    }
    i {
      &.simple-icon-people {
        opacity: 0;
        font-size: 1rem;
        &:before {
          padding: 5px;
        }
      }
      &.iconclass {
        color: $primary-color;
        font-size: 1rem;
        &:before {
          border-radius: 50%;
          padding: 5px;
          background: $primary-color;
          color: white;
          background: linear-gradient(to bottom, #757575, #8F8F8F) !important;
        
        }
      }
    }
    button {
      &.icon-button {
        width: 26px;
        height: 26px;
        line-height: 26px;
      }
      &.iconclass {
        opacity: 1 !important;
      }
    }
  }
}

////////////////////////////// "MON ADHESION > CONTRAT" //////////////////////////////

.section-adhesion-contrat {
  .card-contrat {
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    // &.sante { background-image: url("/assets/logos/contrats/contrat-sante.svg"); }
    // &.sante-top { background-image: url("/assets/logos/contrats/contrat-sante-top.svg"); }
    // &.sante-plus { background-image: url("/assets/logos/contrats/contrat-sante-plus.svg"); }
    // &.sante-max { background-image: url("/assets/logos/contrats/contrat-sante-max.svg"); }
    .sante {
      color: $theme-color-2 !important;
    }
    .sante-top {
      color: $theme-color-4 !important;
    }
    .sante-plus {
      color: $theme-color-5 !important;
    }
    .sante-max {
      color: $theme-color-6 !important;
    }
    .prevoyance {
      color: $theme-color-2 !important;
    }
    .prevention {
      color: $theme-color-5 !important;
    }
    .social {
      color: $theme-color-4 !important;
    }
    .garantimmo {
      color: $theme-color-6 !important;
    }
    .contrat-img {
      max-width: 5rem;
      margin-bottom: 0.5rem;
    }
    @include respond-below(md) {
      background-image: none !important;
    }
  }
}

////////////////////////////// "MON ADHESION > BENEFICIAIRES" //////////////////////////////

.section-adhesion-benef {
  .separator {
    border-top: 2px dashed $primary-color !important;
    border: none;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .alert-primary {
    border: none;
    box-shadow: 0.2rem 0.2rem 0.5rem rgb(0 0 0 / 15%) !important;
    background-color: none !important;
    background: none !important;
    &.sante {
      background: $sante-gradient !important;
    }
    &.sante-top {
      background: $sante-top-gradient !important;
    }
    &.sante-plus {
      background: $sante-plus-gradient !important;
    }
    &.sante-max {
      background: $sante-max-gradient !important;
    }
  }
  .contrat-img {
    max-width: 5rem;
  }
  .badge {
    &.badge-pill {
      min-width: 5rem;
      max-height: 1.5rem;
    }
    &.badge-benef {
      position: absolute;
      top: 8px;
    }
    &.badge-sante {
      background-color: #FBBB21!important;
    }
    &.badge-sante-top {
      background-color: $theme-color-4 !important;
      color: white;
    }
    &.badge-sante-plus {
      background-color: $theme-color-5 !important;
    }
    &.badge-sante-max {
      background-color: $theme-color-6 !important;
      color: white;
    }
    .dropdown-menu-contrat {
      inset: 1.5rem auto auto -19rem !important;
      background-color: #D3D3D3;
   
      &.dropdown-menu-sante {
        color: #3E3A3E;
        .dropdown-item {
          color: #3E3A3E;
          &:hover,
          &:focus {
            background-color: #F0F0F0 !important;
         
            color: #3E3A3E !important;
          }
          &.active,
          &:active {
            color:#3E3A3E;
            text-decoration: none !important;
          }
        }
      }
      &.dropdown-menu-sante-top {
        .dropdown-item {
          color: #3E3A3E;
          &:hover,
          &:focus {
            background-color: $theme-color-4 !important;
            color: white !important;
          }
          &.active,
          &:active {
            color: white !important;
            text-decoration: none !important;
            background-color: $theme-color-4 !important;
          }
        }
      }
      &.dropdown-menu-sante-plus {
        .dropdown-item {
          &:hover,
          &:focus {
            background-color: $theme-color-5 !important;
            color: $text-color-black !important;
          }
          &.active,
          &:active {
            color: white !important;
            text-decoration: none !important;
            background-color: $theme-color-5 !important;
          }
        }
      }
      &.dropdown-menu-sante-max {
        .dropdown-item {
          &:hover,
          &:focus {
            background-color: $theme-color-6 !important;
            color: $text-color-black !important;
          }
          &.active,
          &:active {
            color: white !important;
            text-decoration: none !important;
            background-color: $theme-color-6 !important;
          }
        }
      }
    }
  }
  div.position-relative {
    &:hover {
      .section-adhesion-benef-card {
        .card-contrat {
          background-repeat: no-repeat !important;
          background-position-x: right !important;
          background-size: contain !important;
          background-color: transparent !important;
        }
        .card-contrat-sante {
          background: url("/assets/logos/contrats/contrat-benef-sante.png");
     
      
         background-position-x: right;
        }
        .card-contrat-sante-top {
          background: url("/assets/logos/contrats/contrat-benef-sante-top.svg");
        }
        .card-contrat-sante-plus {
          background: url("/assets/logos/contrats/contrat-benef-sante-plus.svg");
        }
        .card-contrat-sante-max {
          background-image: url("/assets/logos/contrats/contrat-benef-sante-max.svg");
        }
      }
    }
  }
  .btn-options {
    padding: 0.25rem 0.75rem !important;
    &:hover {
      .section-adhesion-benef-card {
        .card-contrat {
          background-color: transparent !important;
          background-repeat: no-repeat !important;
          background-position-x: right !important;
          background-size: contain !important;
        }
        .card-contrat-sante {
          background-image: url("/assets/logos/contrats/contrat-benef-sante.svg");
        }
        .card-contrat-sante-top {
          background-image: url("/assets/logos/contrats/contrat-benef-sante-top.svg");
        }
        .card-contrat-sante-plus {
          background-image: url("/assets/logos/contrats/contrat-benef-sante-plus.svg");
        }
        .card-contrat-sante-max {
          background-image: url("/assets/logos/contrats/contrat-benef-sante-max.svg");
        }
      }
    }
  }

  .row-benef-cards {
    .section-adhesion-benef-card {
      .table th,
      .table td {
        border: none !important;
        text-align: center;
        vertical-align: sub;
        &.th-typebenef {
          min-width: 7.5rem;
        }
        &.th-nom {
          min-width: 6rem;
        }
        &.th-prenom {
          min-width: 8rem;
        }
        &.th-ddn {
          min-width: 8.5rem;
        }
        &.th-debdroitmpl {
          min-width: 9.5rem;
        }
        &.th-contrat {
          min-width: 7rem;
        }
        &.th-debcontrat {
          min-width: 9.5rem;
        }
        &.th-findroitmpl {
          min-width: 9.5rem;
        }
        &.th-radiation {
          min-width: 9.5rem;
        }
        &.th-procdebut {
          min-width: 6.5rem;
        }
      }
    }
  }
  .row-benef-table {
    .ngx-datatable {
      span {
        font-size: 0.8rem !important;
      }
      p {
        font-size: 0.8rem !important;
        margin-bottom: 0 !important;
      }
      .progress-linear {
        display: block;
        position: relative;
        width: 100%;
        height: 5px;
        padding: 0;
        margin: 0;
        position: absolute;
        .container {
          display: block;
          position: relative;
          overflow: hidden;
          transform: translate(0, 0) scale(1, 1);
          width: 100%;
          height: 5px;
          background-color: #aad1f9;
          .bar {
            transition: all 0.2s linear;
            animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
            transition: -webkit-transform 0.2s linear;
            transition: transform 0.2s linear;
            background-color: #106cc8;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
          }
        }
      }
      /*-----------------------------------------------------*/
      border: 1px solid #e0e0e0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: -1px 6px 6px rgb(0 0 0 / 15%), 0 1px 6px rgb(0 0 0 / 15%);
      .datatable-header {
        margin-left: 8px;
        margin-bottom: 0;
        min-width: calc(100% - 16px) !important;
        @include respond-below(xl) {
          min-width: 1334px !important;
        }
        @include respond-above(xl) {
          min-width: calc(100% - 16px) !important;
        }
 
      }
      .datatable-footer-inner {
        overflow: hidden;
      }
    }
  }
  .row-pagination {
    ul {
      margin-bottom: 0;
      li {
        margin-bottom: 0;
      }
    }
  }
}

////////////////////////////// "COTISATIONS > APPELS" //////////////////////////////

.section-cotis-appel {
  input.form-control {
    text-transform: capitalize;
    width: fit-content;
    min-width: 13rem;
  }
  .ngb-dp-arrow {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    display: inline-block;
  }
  .ngb-dp-arrow-btn {
    padding: 0 0.25rem;
    margin: 0 0.5rem;
    border: none;
    background-color: transparent;
  }
  .ngb-dp-navigation-chevron {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    margin-left: -0.5em;
    margin-right: 0.25em;
    transform: rotate(-135deg);
  }
  .right .ngb-dp-navigation-chevron {
    transform: rotate(45deg);
    margin-left: 0.5em;
    margin-right: 0.25em;
  }
  .selectYear {
    display: block;
    width: 100%;
    padding: 0.25rem 0.25rem;
    clear: both;
    text-align: center;
  }
  .dropdown-item {
    padding: 0.5rem 0.5rem !important;
    text-align: center;
  }
  .btn {
    height: calc(1.5em + 1rem + 2px) !important;
  }
}

////////////////////////////// "COTISATIONS > FICHIER COTISATIONS" //////////////////////////////

.section-fichier-cotis {
  input.form-control {
    text-transform: capitalize;
    width: fit-content;
    min-width: 13rem;
  }
  .ngb-dp-arrow {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    display: inline-block;
  }
  .ngb-dp-arrow-btn {
    padding: 0 0.25rem;
    margin: 0 0.5rem;
    border: none;
    background-color: transparent;
  }
  .ngb-dp-navigation-chevron {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    margin-left: -0.5em;
    margin-right: 0.25em;
    transform: rotate(-135deg);
  }
  .right .ngb-dp-navigation-chevron {
    transform: rotate(45deg);
    margin-left: 0.5em;
    margin-right: 0.25em;
  }
  .selectYear {
    display: block;
    width: 100%;
    padding: 0.25rem 0.25rem;
    clear: both;
    text-align: center;
  }
  .dropdown-item {
    padding: 0.5rem 0.5rem !important;
    text-align: center;
  }
  .btn {
    height: calc(1.5em + 1rem + 2px) !important;
  }
}

////////////////////////////// "COTISATION > SITUATION COMPTE" & "REMBOURSEMENTS > MES TELECHARGEMENTS" //////////////////////////////

.section-cotis-situ-compte,
.section-remb-mes-telechargements {
  input.form-control {
    text-transform: capitalize;
    width: fit-content;
    z-index: 11 !important;
    opacity: 0.75;
  }
  .btn {
    height: calc(1.5em + 1rem + 2px) !important;
  }
  #imgChevronBas {
    z-index: 9 !important;
    position: absolute;
    left: 165px;
    margin-right: 4px;
    top: 11px;
    opacity: 1;
    @include respond-below(xs) {
      z-index: 9 !important;
      position: absolute;
      left: 152px;
      margin-right: 4px;
      top: 11px;
      opacity: 1;
    }
  }
}

////////////////////////////// "MON COMPTE" //////////////////////////////

.section-compte {
  .text-invalid {
    color: #ca0000;
  }
  .card-body {
    .lh-8 {
      line-height: 8px;
    }
    @include respond-above(xs) {
      min-height: 21rem;
    }
    .control-label {
      z-index: 4;
    }
    .alert-primary {
      color: $text-color-redbrown !important;
      margin-bottom: 2rem;
    }
    .input-upper {
      text-transform: uppercase;
    }
    .icon-fusion {
      .simple-icon-eye {
        padding-top: 0;
      }
      .simple-icon-ban {
        position: absolute;
        top: 4px;
        &::before {
          content: "/";
          font-size: 1.5rem;
          padding-left: 0.2rem;
        }
      }
    }
  }
}

////////////////////////////// "REMBOURSEMENTS > REMBOURSEMENTS" //////////////////////////////

.section-remb-remb {
  .selected {
    background-color: #eee;
  }
  .page-card-body {
    background-color: white !important;
  }
  .progress-linear {
    position: relative !important;
    .container {
      max-width: max-content !important;
    }
  }
  .head {
    background-color: white;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: -1px 6px 6px rgb(0 0 0 / 15%), 0 1px 6px rgb(0 0 0 / 15%);
  }
  .modal-xl {
    max-width: 85%;
  }
  .ngx-datatable {
    border: 1px solid #e0e0e0;
    span {
      font-size: 0.8rem !important;
    }
    p {
      font-size: 0.8rem !important;
      margin-bottom: 0 !important;
    }
    .progress-linear {
      display: block;
      position: relative;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;
      position: absolute;
      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        transform: translate(0, 0) scale(1, 1);
        background-color: #aad1f9;
        .bar {
          transition: all 0.2s linear;
          animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          transition: -webkit-transform 0.2s linear;
          transition: transform 0.2s linear;
          background-color: #106cc8;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
        }
      }
    }

  }
  @keyframes query {
    0% {
      opacity: 1;
      transform: translateX(35%) scale(0.3, 1);
    }
    100% {
      opacity: 0;
      transform: translateX(-50%) scale(0, 1);
    }
  }
  .simple-icon-plus {
    cursor: pointer;
  }
}
